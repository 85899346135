import {
	Venda,
	ItemDaVenda,
	ProdutoFilho,
	RegimeFiscal,
	SiglaUf,
	FormMovimentacaoDeCaixa,
	TurnoDeVenda,
	PontoDeVenda,
	FiltroDeVenda,
	ConfiguracaoDeCupom,
	ModalidadeDeFrete,
	ResumoDaVenda,
	VendaBase,
	ItemDaVendaBase,
	FiltroDePedido,
	ParcelasComTaxas,
	InformacoesDeTransporte,
	CupomDeDesconto,
	NotaEmTransito,
	Desconto,
	TipoDeTransacaoDeVenda,
	Voucher,
	Produto,
	VendaDemonstracaoResumida,
	InfoNotaComplementar,
} from '@/models'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import {
	formatarCnpjOuCpf,
	removerFormatacaoDeCnpjOuCpf,
	aplicarMascaraParaCpfOculto,
	fixarCasasDecimais,
} from './formatadores'
import { gerarValeTrocas } from './impressao-de-vale-troca'
import request from '@/common/request'
import print from 'print-js'
import Axios from 'axios'
import { PedidoDeVenda } from '@/models/venda/PedidoDeVenda'
import { v4 as uuidv4 } from 'uuid'
import { Decimal } from 'decimal.js'
import { ValeTroca } from '@/models/venda/ValeTroca'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { CartaDeCorrecao } from '@/models/fiscal/CartaDeCorrecao'
import { converterProdutoParaProdutoSimples } from './produto-shareds'

export function criarMovimentacaoDeCaixa(): FormMovimentacaoDeCaixa {
	return {
		id: '',
		tipoMovimentacao: 'Sangria',
		dataHoraMovimentacao: null,
		valor: null,
		observacao: '',
		anexos: [],
		operador: null,
		motivo: null,
	}
}

export function criarDevolucao({
	itens,
	estado,
	regimeFiscal,
	vendaOrigem,
	vendaOriginal,
	tipoDeTransacao,
	totalValorFrete,
}: {
	itens: ItemDaVenda[]
	estado: SiglaUf
	regimeFiscal: RegimeFiscal
	vendaOrigem: Venda
	vendaOriginal: Venda
	tipoDeTransacao: TipoDeTransacaoDeVenda
	totalValorFrete: number
}): Venda {
	return {
		...criarVenda({ estado, regimeFiscal }),
		tipoDeTransacao: tipoDeTransacao,
		vendaOrigem: vendaOrigem.id,
		pedido: null,
		itens: itens.map(item => ({
			...item,
			quantidade: -item.quantidade,
			id: '',
			percentualCashback: item.percentualCashback,
			diasParaEfetivarCashback: item.diasParaEfetivarCashback,
			idItemOrigem: item.idItemOrigem || item.id,
			desconto:
				vendaOriginal.tipoDeTransacao === 'Outros' &&
					vendaOriginal.tipoNota === 'Entrada'
					? item.desconto
					: {
						isPercentual: true,
						valor: 0,
					},
			vendedor: item.vendedor,
		})),
		cliente: vendaOrigem.cliente,
		cpfDoCliente: vendaOrigem.cpfDoCliente,
		resumoDaVendaOrigem: {
			id: vendaOrigem.id,
			cliente: vendaOrigem.cliente,
			cpfDoCliente: vendaOrigem.cpfDoCliente,
		},
		totalDeTaxa: -vendaOriginal.totalDeTaxa,
		isConsignado: vendaOrigem.isConsignado,
		isDemonstracao: vendaOriginal.isDemonstracao,
		tipoNota: 'Entrada',
		informacoesDeTransporte: obterInformacaoDeFreteDaDevolucao(
			vendaOriginal.informacoesDeTransporte,
			totalValorFrete,
		),
		vendedor: vendaOrigem.vendedor,
		desconto: null,
		possuiNotaNaoEletronica: vendaOriginal.possuiNotaNaoEletronica,
	}
}

export function criarDevolucaoParaDemonstracaoOuConsignado({
	itens,
	estado,
	regimeFiscal,
	vendaOrigem,
	vendaOriginal,
	tipoDeTransacao,
}: {
	itens: ItemDaVenda[]
	estado: SiglaUf
	regimeFiscal: RegimeFiscal
	vendaOrigem: Venda
	vendaOriginal: Venda
	tipoDeTransacao: TipoDeTransacaoDeVenda
}): Venda {
	return {
		...criarVenda({ estado, regimeFiscal }),
		tipoDeTransacao: tipoDeTransacao ? tipoDeTransacao : 'Devolução',
		vendaOrigem: vendaOrigem.id,
		pedido: vendaOrigem.pedido?.id
			? {
				id: vendaOrigem.pedido?.id,
				situacao: 'Devolvido',
				observacao: vendaOrigem.pedido?.observacao
					? vendaOrigem.pedido?.observacao
					: '',
				enderecoDoPedido: null,
			}
			: null,
		itens: itens.map(item => ({
			...item,
			quantidade: -item.quantidade,
			id: '',
			percentualCashback: item.percentualCashback,
			diasParaEfetivarCashback: item.diasParaEfetivarCashback,
		})),
		cliente: vendaOrigem.cliente,
		cpfDoCliente: vendaOrigem.cpfDoCliente,
		resumoDaVendaOrigem: {
			id: vendaOrigem.id,
			cliente: vendaOrigem.cliente,
			cpfDoCliente: vendaOrigem.cpfDoCliente,
		},
		totalDeTaxa: -vendaOriginal.totalDeTaxa,
		isConsignado: vendaOrigem.isConsignado,
		isDemonstracao: vendaOriginal.isDemonstracao,
		tipoNota: 'Entrada',
		informacoesDeTransporte: obterInformacaoDeFrete(
			vendaOriginal.informacoesDeTransporte,
		),
		vendedor: vendaOrigem.vendedor,
		desconto: null,
		autenticandoParaLiberarVendaSemEstoque: vendaOriginal.autenticandoParaLiberarVendaSemEstoque,
		cashbackAplicado: false,
		valorDeCashbackAplicado: 0,
	}
}

function obterInformacaoDeFrete(
	informacaoDeTransporte: InformacoesDeTransporte,
): InformacoesDeTransporte {
	if (!informacaoDeTransporte)
		return {
			modalidadeDeFrete: 'Sem Frete',
			especie: null,
			pesoBruto: null,
			pesoLiquido: null,
			volume: null,
			fornecedor: null,
			valorFrete: null,
		}

	return {
		modalidadeDeFrete: informacaoDeTransporte.modalidadeDeFrete,
		volume: informacaoDeTransporte.volume,
		especie: informacaoDeTransporte.especie,
		pesoLiquido: informacaoDeTransporte.pesoLiquido,
		pesoBruto: informacaoDeTransporte.pesoBruto,
		fornecedor: informacaoDeTransporte.fornecedor,
		valorFrete: !informacaoDeTransporte.valorFrete
			? 0
			: -informacaoDeTransporte.valorFrete,
	}
}

function obterInformacaoDeFreteDaDevolucao(
	informacaoDeTransporte: InformacoesDeTransporte,
	totalValorFrete: number,
): InformacoesDeTransporte {
	if (!informacaoDeTransporte)
		return {
			modalidadeDeFrete: 'Sem Frete',
			especie: null,
			pesoBruto: null,
			pesoLiquido: null,
			volume: null,
			fornecedor: null,
			valorFrete: null,
		}

	return {
		modalidadeDeFrete: informacaoDeTransporte.modalidadeDeFrete,
		volume: informacaoDeTransporte.volume,
		especie: informacaoDeTransporte.especie,
		pesoLiquido: informacaoDeTransporte.pesoLiquido,
		pesoBruto: informacaoDeTransporte.pesoBruto,
		fornecedor: informacaoDeTransporte.fornecedor,
		valorFrete: !totalValorFrete
			? 0
			: -totalValorFrete,
	}
}

/*TODO FEATURE/VES-45
Esperar os testes de QA

function obterDescontoParaDevolucaoItem(desconto: Desconto): Desconto {
	return {
		...desconto,
		valor: desconto.valor,
	}
}

function obterDescontoParaDevolucaoCapa(desconto: Desconto, itens: ItemDaVenda[], venda: Venda): Desconto {
	let valorTotalDesconto = desconto.valor

	if (!desconto.isPercentual) {
		const totalDaVenda = Number(venda.itens.map(item => item.preco*item.quantidade).reduce((soma, valor) => soma+valor).toFixed(2))
		const percentualDeDesconto = (desconto.valor*100)/totalDaVenda
		const totalDosItensDevolvidos = Number(itens.map(item => item.preco*item.quantidade).reduce((soma, valor) => soma+valor).toFixed(2))
		valorTotalDesconto = Number(((percentualDeDesconto/100)*totalDosItensDevolvidos).toFixed(2))
	}

	return {
		...desconto,
		valor: valorTotalDesconto,
	}
}
*/

export function criarVendaDoPdv(pdv: PontoDeVenda) {
	return criarVenda({
		...pdv.parametrosFiscais,
		criaPedido: pdv.criaPedidoNaVenda,
		pdv,
	})
}

export function criarEmissaoDeNota() {
	return criarVendaEmissao({})
}

export function criarVenda({
	estado,
	regimeFiscal = 'Simples Nacional',
	criaPedido = false,
	pdv = null,
}: {
	estado: SiglaUf
	regimeFiscal?: RegimeFiscal
	criaPedido?: boolean
	pdv?: PontoDeVenda | null
}): Venda {
	return {
		id: uuidv4(),
		identificador: '',
		cliente: null,
		itens: [],
		pagamentos: [],
		pontoDeVenda: pdv,
		desconto: null,
		dataHora: null,
		estado,
		regimeFiscal,
		descontoRateado: 0,
		cpfDoCliente: '',
		exibirCpfNaNota: false,
		tipoDeTransacao: 'Venda',
		vendaOrigem: null,
		notas: [],
		situacao: 'Aberta',
		tipoDeCliente: null,
		resumoDaVendaOrigem: null,
		pedido: criaPedido
			? {
				id: '',
				situacao: 'Pendente',
				observacao: '',
				enderecoDoPedido: null,
			}
			: null,
		troco: 0,
		cuponsDeDesconto: [],
		informacoesDeTransporte: {
			modalidadeDeFrete: 'Sem Frete',
			especie: null,
			pesoBruto: null,
			pesoLiquido: null,
			volume: null,
			fornecedor: null,
			valorFrete: null,
		},
		temDevolucao: false,
		vendedor: null,
		duplicadoDe: null,
		prazoDevolucao: null,
		volumes: [],
		totalDeTaxa: 0,
		taxaDeServico: 0,
		representanteComercial: null,
		liberarCrediario: false,
		isConsignado: false,
		isDemonstracao: false,
		isVendaReaberta: false,
		tipoNota: 'Saída',
		serie: null,
		isBrinde: false,
		valorDeCashbackAplicado: 0,
		produtosVerificados: false,
		cashbackAplicado: false,
		isPresente: false,
		isTroca: false,
		infoAdicionais: null,
		modalidadeDeVenda: null,
		demonstracaoConvertida: false,
		consignacaoConvertida: false,
		autenticandoParaLiberarVendaSemEstoque: false,
		numeroTicket: '',
		valesPresentes: [],
		existeValePresente: false,
		enderecoAdicional: null,
		possuiNotaNaoEletronica: false,
		isOperacaoPresencial: true,
	}
}

export function criarVendaEmissao({
	estado = null,
	regimeFiscal = 'Simples Nacional',
	criaPedido = false,
	pdv = null,
}: {
	estado?: SiglaUf | null
	regimeFiscal?: RegimeFiscal
	criaPedido?: boolean
	pdv?: PontoDeVenda | null
}): Venda {
	return {
		id: uuidv4(),
		identificador: '',
		cliente: null,
		itens: [],
		pagamentos: [],
		pontoDeVenda: pdv,
		desconto: null,
		dataHora: null,
		estado,
		regimeFiscal,
		descontoRateado: 0,
		cpfDoCliente: '',
		exibirCpfNaNota: false,
		tipoDeTransacao: 'Transferência',
		vendaOrigem: null,
		notas: [],
		situacao: 'Aberta',
		tipoDeCliente: null,
		resumoDaVendaOrigem: null,
		pedido: criaPedido
			? {
				id: '',
				situacao: 'Pendente',
				observacao: '',
				enderecoDoPedido: null,
			}
			: null,
		troco: 0,
		cuponsDeDesconto: [],
		informacoesDeTransporte: {
			modalidadeDeFrete: 'Sem Frete',
			especie: null,
			pesoBruto: null,
			pesoLiquido: null,
			volume: null,
			fornecedor: null,
			valorFrete: null,
		},
		temDevolucao: false,
		vendedor: null,
		duplicadoDe: null,
		prazoDevolucao: null,
		volumes: [],
		totalDeTaxa: 0,
		representanteComercial: null,
		liberarCrediario: false,
		isConsignado: false,
		isDemonstracao: false,
		isVendaReaberta: false,
		tipoNota: 'Saída',
		serie: null,
		isBrinde: false,
		valorDeCashbackAplicado: 0,
		produtosVerificados: false,
		cashbackAplicado: false,
		isPresente: false,
		isTroca: false,
		infoAdicionais: null,
		taxaDeServico: 0,
		modalidadeDeVenda: null,
		demonstracaoConvertida: false,
		consignacaoConvertida: false,
		autenticandoParaLiberarVendaSemEstoque: false,
		numeroTicket: '',
		valesPresentes: [],
		existeValePresente: false,
		enderecoAdicional: null,
		possuiNotaNaoEletronica: false,
		isOperacaoPresencial: true,
	}
}

export function criarPedidoDeVenda(): PedidoDeVenda {
	return {
		id: '',
		situacao: 'Pendente',
		observacao: '',
		enderecoDoPedido: null,
	}
}

export function limparFiltroDosCamposDeVenda(): FiltroDeVenda {
	return {
		busca: '',
		lojaId: '',
		datas: [null, null],
		horas: [null, null],
		cliente: null,
		pdvId: '',
		numeroDaNota: '',
		serieFiscal: '',
		exibeVendasComErros: false,
		ambientes: ['Homologação', 'Produção'],
		identificador: '',
		identificadorExterno: '',
		vendasComOrcamento: false,
		vendasComPagamentoCancelado: false,
		origem: null,
		vendasConsignadaDemonstracao: false,
		vendedorId: null,
	}
}

export function limparFiltroDosCamposDePedidos(): FiltroDePedido {
	return {
		lojaId: null,
		cliente: null,
		identificador: null,
		identificadorExterno: null,
		situacoes: [],
		datas: [],
		possuiFrete: false,
	}
}

export function criarConfiguracaoDeCupom(): ConfiguracaoDeCupom {
	return {
		id: '',
		nome: '',
		codigo: '',
		descricao: '',
		qtdCuponsGerar: 1,
		limiteDeUso: 1,
		valorDoDesconto: 0,
		statusConfiguracaoDeCupom: 'Disponível para gerar',
		isPercentual: false,
		dataInicioVigencia: null,
		dataFinalVigencia: null,
		cupons: [],
		grupoEconomico: null,
		permiteUtilizarMultiplosCuponsNaMesmaVenda: true,
		permiteUtilizarCupomComDescontoNaVenda: true,
		numeroDeCupomPorCpf: 0,
		lojaId: '',
		valorMinimoDeUsoCupom: 0,
	}
}

export function criarItemDaVenda(produto: Produto, quantidadeDeItens: number): ItemDaVenda {
	return {
		id: '',
		produto: converterProdutoParaProdutoSimples(produto),
		quantidade: quantidadeDeItens,
		quantidadeLida: 0,
		desconto: { isPercentual: true, valor: 0.0 },
		preco: produto.preco || 0,
		precoDeCusto: 0,
		idConjunto: null,
		descontoVarejo: 0,
		percentualCashback: 0,
		diasParaEfetivarCashback: 0,
		isBrinde: false,
		idItemNotaOrigem: null,
		chaveNotaOrigem: null,
		diasParaExpirarCashback: 0,
		possuiDescontoPorTabela: false,
		valorDescontoRateadoDoItem: 0,
		itemDeDevolucao: false,
		isTotalmenteTrocado: false,
		motivoCancelamento: null,
		isCompra: false,
		idItemOrigem: '',
		isDaVendaReaberta: false,
		vendedor: null,
		autenticadoParaLiberarProdutoSemEstoque: false,
		vendaOrigemDoItem: null,
		valorFreteRateado: 0,

		isItemReferenteATroca: false,
		isDeOutraLoja: false,
		percentualDescontoTabelaPreco: 0,
	}
}

export function obterValorDisponivelDeSangria(
	turno: TurnoDeVenda,
	vendas: Venda[],
) {
	const totalEmVendas = vendas.reduce(
		(total, venda) =>
			total +
			venda.pagamentos.reduce(
				(total, pagamento) =>
					total +
					(pagamento.tipoDePagamento.formaDePagamento === 'Dinheiro'
						? pagamento.valor
						: 0),
				0,
			) -
			venda.troco,
		0,
	)
	const totalEmMovimentacao = turno.movimentacoes.reduce(
		(total, movimentacao) =>
			total +
			(movimentacao.tipoMovimentacao === 'Sangria'
				? -movimentacao.valor
				: movimentacao.valor),
		0,
	)
	return totalEmVendas + totalEmMovimentacao
}

export function obterTotalDaVenda(venda: VendaBase) {
	let subTotalDaVenda = new Decimal(obterTotalDosItensDaVenda(venda)).add(
		venda.taxaDeServico,
	)

	if (
		venda.informacoesDeTransporte &&
		venda.informacoesDeTransporte.modalidadeDeFrete !== 'Sem Frete' &&
		venda.informacoesDeTransporte.valorFrete
	) {
		subTotalDaVenda = subTotalDaVenda.add(
			venda.informacoesDeTransporte.valorFrete,
		)
	}

	const descontoDaVenda = new Decimal(obterDescontoDaVenda(venda))
	let aplicarCupomNaVenda = new Decimal(0)
	if (venda) {
		const valorDosDescontos = venda.cuponsDeDesconto.map(cupom => {
			return new Decimal(aplicarCupomDaVenda(venda, cupom))
		})

		const somaDosDescontos = valorDosDescontos.reduce(
			(acumulador, valorAtual) => {
				return acumulador.plus(valorAtual)
			},
			new Decimal(0),
		)
		aplicarCupomNaVenda = somaDosDescontos
	}

	return subTotalDaVenda
		.sub(descontoDaVenda)
		.sub(aplicarCupomNaVenda)
		.toNumber()
}

export function aplicarCupomDaVenda(
	venda: VendaBase,
	cupomDaVenda: CupomDeDesconto,
): number {
	if (!venda.cuponsDeDesconto || venda.cuponsDeDesconto.length <= 0) return 0
	const desconto = cupomDaVenda.isPercentual
		? calcularDescontoPercentualDaVenda(
			cupomDaVenda.valorDoDesconto,
			obterTotalDosItensDaVenda(venda),
		)
		: venda.itens[0].quantidade < 0
			? new Decimal(cupomDaVenda.valorDoDesconto * -1)
			: new Decimal(cupomDaVenda.valorDoDesconto)

	return desconto
		.mul(100)
		.trunc()
		.div(100)
		.toNumber()
}

export function obterTotalDosItensSemDesconto(venda: VendaBase) {
	const subTotalDaVenda = venda.itens
		.filter(item => item.desconto.valor === 0)
		.reduce(
			(total, item) => total.add(obterTotalDoItemSemDesconto(item)),
			new Decimal(0),
		)
	return subTotalDaVenda.toNumber()
}

export function obterTotalBrutoDosItensComDesconto(venda: VendaBase) {
	const subTotalDaVenda = venda.itens
		.filter(item => item.desconto.valor !== 0)
		.reduce(
			(total, item) => total.add(obterTotalDoItemSemDesconto(item)),
			new Decimal(0),
		)
	return subTotalDaVenda.toNumber()
}

export function obterTotalDaVendaSemDesconto(venda: VendaBase) {
	let subTotalDaVenda = new Decimal(obterTotalDosItensDaVendaSemDesconto(venda))

	if (
		venda.informacoesDeTransporte &&
		venda.informacoesDeTransporte.modalidadeDeFrete !== 'Sem Frete' &&
		venda.informacoesDeTransporte.valorFrete
	) {
		subTotalDaVenda = subTotalDaVenda.add(
			venda.informacoesDeTransporte.valorFrete,
		)
	}

	return subTotalDaVenda.toNumber()
}

export function obterDescontoDaVenda(venda: VendaBase): number {
	if (!venda.desconto || !venda.desconto.valor) return 0

	const desconto = venda.desconto.isPercentual
		? calcularDescontoPercentualDaVenda(
			venda.desconto.valor,
			obterTotalDosItensDaVenda(venda),
		)
		: venda.itens[0].quantidade < 0
			? new Decimal(venda.desconto.valor * -1)
			: new Decimal(venda.desconto.valor)

	return desconto
		.mul(100)
		.trunc()
		.div(100)
		.toNumber()
}

export function obterDescontoDeCupomDaVenda(venda: VendaBase): number {
	if (!venda.cuponsDeDesconto || !venda.cuponsDeDesconto) return 0

	const totalDesconto = venda.cuponsDeDesconto
		.map(cupom => {
			const desconto = cupom.isPercentual
				? calcularDescontoPercentualDaVenda(
					cupom.valorDoDesconto,
					obterTotalDosItensDaVenda(venda),
				)
				: new Decimal(cupom.valorDoDesconto)

			return desconto
				.mul(100)
				.trunc()
				.div(100)
				.toNumber()
		})
		.reduce((total, valor) => total + valor)

	return totalDesconto
}

function calcularDescontoPercentualDaVenda(
	desconto: number,
	totalDosItens: number,
): Decimal {
	return new Decimal(totalDosItens).sub(
		new Decimal(1)
			.sub(new Decimal(desconto).div(100))
			.mul(totalDosItens)
			.mul(100)
			.div(100)
			.toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN),
	)
}

export function obterDescontoDaVendaComItens(venda: Venda): number {
	const itensValidosDaVenda = venda.itens.filter((item) => item.motivoCancelamento === null)
	const descontoTotalDosItens = itensValidosDaVenda.reduce(
		(total, item) => total.add(obterDescontoDoItem(item)),
		new Decimal(0),
	)

	const valorDosCuponsDeDescontos = venda.cuponsDeDesconto
		.map(cupom => {
			return new Decimal(aplicarCupomDaVenda(venda, cupom))
		})
		.reduce((acumulador, valorAtual) => {
			return acumulador.plus(valorAtual)
		}, new Decimal(0))

	const descontoDeCapa = obterDescontoDaVenda(venda)

	return (
		descontoTotalDosItens.toNumber() +
		descontoDeCapa +
		valorDosCuponsDeDescontos.toNumber()
	)
}

export function obterDescontoDoItem(itemVenda: ItemDaVenda) {
	let desconto = new Decimal(0)

	if (itemVenda.desconto.isPercentual) {
		desconto = new Decimal(itemVenda.preco)
			.sub(
				new Decimal(itemVenda.preco)
					.mul(
						new Decimal(1).sub(new Decimal(itemVenda.desconto.valor).div(100)),
					)
					.toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN),
			)
			.mul(itemVenda.quantidade)
	} else {
		desconto = new Decimal(itemVenda.desconto.valor).mul(itemVenda.quantidade)
	}

	return desconto
		.mul(100)
		.trunc()
		.div(100)
}

export function obterTotalDosItensDaVenda(venda: VendaBase) {
	const ItensParaVenda = venda.itens.filter(item => item.isCompra)
	if (
		venda.isVendaReaberta &&
		venda.isDemonstracao &&
		ItensParaVenda.length > 0
	) {
		const subTotalDaVenda = ItensParaVenda.reduce(
			(total, item) => total.add(obterTotalDoItem(item)),
			new Decimal(0),
		)
		return subTotalDaVenda.toNumber()
	}

	const subTotalDaVenda = venda.itens.reduce(
		(total, item) => total.add(obterTotalDoItem(item)),
		new Decimal(0),
	)
	return subTotalDaVenda.toNumber()
}

export function obterTotalDosItensDaVendaPositivos(venda: VendaBase) {
	const subTotalDaVenda = venda.itens
		.filter(item => item.quantidade > 0)
		.reduce((total, item) => total.add(obterTotalDoItem(item)), new Decimal(0))
	return subTotalDaVenda.toNumber()
}

export function obterTotalDosItensDaVendaSemDesconto(venda: VendaBase) {
	const subTotalDaVenda = venda.itens.reduce(
		(total, item) => total.add(obterTotalDoItemSemDesconto(item)),
		new Decimal(0),
	)
	return subTotalDaVenda.toNumber()
}

export function obterTotalDeTaxas(venda: Venda) {
	const valorDasTaxasPorItens = venda.pagamentos.map(pagamento => {
		if (pagamento.tipoDePagamento.parcelasComTaxas === null || !pagamento.tipoDePagamento.somaTaxaNaVenda) return null
		return pagamento.valorDaTaxa
	})

	const valorTotalTaxa =
		valorDasTaxasPorItens != null
			? valorDasTaxasPorItens.reduce((a, b) => Number(a) + Number(b), 0)
			: null
	return valorTotalTaxa != null
		? Number(valorTotalTaxa) + Number(venda.taxaDeServico)
		: Number(0)
}

export function obterValorDeFrete(venda: Venda) {
	if (!venda.informacoesDeTransporte) return 0

	return venda.informacoesDeTransporte.modalidadeDeFrete !== 'Sem Frete' &&
		venda.informacoesDeTransporte.valorFrete
		? venda.informacoesDeTransporte.valorFrete
		: 0
}

export function obterSomaTotalDaTaxaPorItem(
	valor: number,
	item: ParcelasComTaxas[],
	qtdeDeParcelas: number,
) {
	if (item.length === 0) return 0
	const parcelaComTaxas = item.filter(
		parcela => parcela.parcelamentos === qtdeDeParcelas,
	)[0]

	const taxas =
		(Number(valor) * parcelaComTaxas.taxas * parcelaComTaxas.parcelamentos) /
		100

	return taxas
}

export function obterItensComTaxaAplicada(
	valor: number,
	item: ParcelasComTaxas[],
	qtdeDeParcelas: number,
) {
	if (item.length === 0) return valor

	const parcelaComTaxas = item.filter(
		parcela => parcela.parcelamentos === qtdeDeParcelas,
	)[0]

	let taxasCalculadas = 0

	if (parcelaComTaxas) taxasCalculadas = parcelaComTaxas.taxas * parcelaComTaxas.parcelamentos

	const taxas =
		(Number(valor) * taxasCalculadas) /
		100
	return taxas + Number(valor)
}

export function obterTotalDoItemComTaxas(venda: Venda) {
	const valorTotalDosItens = venda.pagamentos.map(pagamento => {
		if (pagamento.tipoDePagamento.parcelasComTaxas === null) return null
		if (!pagamento.tipoDePagamento.somaTaxaNaVenda) return pagamento.valor
		return obterItensComTaxaAplicada(
			pagamento.valor,
			pagamento.tipoDePagamento.parcelasComTaxas,
			pagamento.qtdeDeParcelas,
		)
	})

	const valorTotalDosItensComTaxa =
		valorTotalDosItens != null
			? valorTotalDosItens.reduce((a, b) => Number(a) + Number(b), 0)
			: null
	return valorTotalDosItensComTaxa != null
		? valorTotalDosItensComTaxa
		: Number(0)
}

export function obterTotalDoItem(
	item: ItemDaVendaBase,
	percentualDeRateio = 0,
) {
	if (item.desconto.isPercentual) {
		const descontoValor = new Decimal(1).sub(
			new Decimal(item.desconto.valor).div(100),
		)
		const descontoRateio = new Decimal(1).sub(
			new Decimal(percentualDeRateio).div(100),
		)
		const precoUnitario = new Decimal(item.preco)
		const precoComDesconto = precoUnitario
			.mul(descontoValor)
			.mul(descontoRateio)
			.toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN)
		return precoComDesconto.mul(item.quantidade).toNumber()
	} else {
		const descontoValor = new Decimal(item.desconto.valor || 0)
		const preco = new Decimal(item.preco)
		return preco
			.sub(descontoValor)
			.mul(item.quantidade)
			.toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN)
			.toNumber()
	}
}

export function obterTotalDoItemSemDesconto(item: ItemDaVendaBase) {
	const preco = new Decimal(item.preco)
	return preco.mul(item.quantidade).toNumber()
}

export function obterTotalDoItemDevolucao(
	item: ItemDaVendaBase,
	quantidadeOriginal: number,
) {
	const preco = new Decimal(item.preco)
	const quantidadeDoItem = new Decimal(item.quantidade)
	const valorDescontoRateadoUnitario = new Decimal(
		item.valorDescontoRateadoDoItem,
	).dividedBy(quantidadeOriginal)

	return preco
		.times(quantidadeDoItem)
		.minus(valorDescontoRateadoUnitario.times(quantidadeDoItem))
		.toNumber()
}

export function obterTotalLiquidoDoItem(
	item: ItemDaVendaBase,
	quantidadeOriginal: number,
) {
	const preco = new Decimal(item.preco)
	const quantidadeDoItem = new Decimal(item.quantidade)
	const valorDescontoRateadoUnitario = new Decimal(
		item.valorDescontoRateadoDoItem,
	).dividedBy(quantidadeOriginal)

	return preco
		.times(quantidadeDoItem)
		.minus(valorDescontoRateadoUnitario.times(quantidadeDoItem))
		.toNumber()
}

export function obterValorDoItemDevolucao(
	item: ItemDaVendaBase,
	quantidadeOriginal: number,
) {
	const preco = new Decimal(item.preco)
	const valorDescontoRateadoUnitario = new Decimal(
		item.valorDescontoRateadoDoItem,
	).dividedBy(quantidadeOriginal)

	return preco.minus(valorDescontoRateadoUnitario).toNumber()
}

export function obterValorDoFreteRateado(
	item: ItemDaVendaBase,
	quantidadeOriginal: number,
) {
	const valorFrete = new Decimal(item.valorFreteRateado)
	const valorDoFreteRateado = valorFrete.dividedBy(quantidadeOriginal)
	return valorDoFreteRateado.mul(item.quantidade).toNumber().toFixed(2);
}

export function obterDisplayClienteDaVenda(venda: Venda | ResumoDaVenda) {
	const cnpjOuCpf = obterCnpjOuCpfDaVenda(venda)
	if (!cnpjOuCpf) return ''
	const cnpjOuCpfSemMascara = removerFormatacaoDeCnpjOuCpf(cnpjOuCpf)
	let display =
		cnpjOuCpfSemMascara.length <= 11
			? aplicarMascaraParaCpfOculto(cnpjOuCpfSemMascara)
			: formatarCnpjOuCpf(cnpjOuCpf)
	if (venda.cliente) {
		display = display + ' - ' + venda.cliente.razaoSocialOuNome
	}
	return display
}

export function obterCnpjOuCpfDaVenda(venda: VendaBase) {
	return venda.cpfDoCliente
}

export function obterSubTotalDoItem(item: ItemDaVendaBase) {
	return new Decimal(item.preco).mul(item.quantidade).toNumber()
}

export function limparFormatacaoDePreco(preco: string | number) {
	if (typeof preco === 'number') return preco
	let precoLimpo = preco
	precoLimpo = precoLimpo.replace(/\./g, '')
	precoLimpo = precoLimpo.replace(',', '')
	return Number(precoLimpo)
}

export function contarItensDaVenda(venda: Venda) {
	const vendaPossuiItensParaVenda =
		venda.itens.filter(item => item.isCompra).length > 0
	const itensTratados = vendaPossuiItensParaVenda
		? venda.itens.filter(item => item.isCompra)
		: venda.itens

	const valorTotalDeItens = itensTratados.reduce(
		(total, { quantidade }) => total + Number(quantidade),
		0,
	)
	return Number.isInteger(valorTotalDeItens)
		? valorTotalDeItens
		: fixarCasasDecimais(valorTotalDeItens, 3)
}

export function contarItensDaVendaDemonstracao(venda: Venda) {
	const itensParaVenda = venda.itens.filter(item => item.isCompra);

	const vendaPossuiItensParaVenda = itensParaVenda.length > 0;

	const itensTratados = vendaPossuiItensParaVenda ? itensParaVenda : venda.itens;

	const valorTotalDeItens = itensTratados.reduce(
		(total, { quantidade, isCompra }) => total + (isCompra ? Number(quantidade) : 0),
		0,
	);
	return Number.isInteger(valorTotalDeItens)
		? valorTotalDeItens
		: fixarCasasDecimais(valorTotalDeItens, 3);
}

export function obterTotalPagoDaVenda(venda: VendaBase) {
	return venda.pagamentos.reduce((total, { valor }) => total + valor, 0)
}

export function obterRestanteDaVenda(venda: VendaBase) {
	const restante = obterTotalDaVenda(venda) - obterTotalPagoDaVenda(venda)
	return restante
}

export function obterValorAPagar(venda: VendaBase) {
	const restante = obterRestanteDaVenda(venda)
	if (
		(venda.tipoDeTransacao === 'Devolução' ||
			venda.tipoDeTransacao === 'Outros') &&
		restante >= 0
	)
		return 0
	if (
		venda.tipoDeTransacao !== 'Devolução' &&
		venda.tipoDeTransacao !== 'Outros' &&
		restante <= 0
	)
		return 0

	return restante
}

export function obterTrocoDaVenda(venda: VendaBase) {
	const pago = obterTotalPagoDaVenda(venda)
	const total = obterTotalDaVenda(venda)
	return pago > total ? pago - total : 0
}

export function obterDescontoEmPercentual(venda: VendaBase): Desconto | null {
	if (venda.desconto && !venda.desconto.isPercentual) {
		const valorDoDesconto = venda.desconto.valor
		const totalLiquidoDosItens = obterTotalDosItensDaVenda(venda)
		const percentualDeDesconto = (valorDoDesconto / totalLiquidoDosItens) * 100
		return {
			isPercentual: true,
			valor: Number(percentualDeDesconto),
		}
	}
	return venda.desconto
}

export function obterDescontoEmPercentualDaTroca(
	venda: VendaBase,
): Desconto | null {
	if (venda.desconto && !venda.desconto.isPercentual) {
		const valorDoDesconto = venda.desconto.valor

		//calcula subtotal da venda somente dos itens que estão saindo, ou seja estão com a quantidade positiva
		let subTotalDaVenda
		const itensParaVenda = venda.itens.filter(item => item.isCompra)
		if (
			venda.isVendaReaberta &&
			venda.isDemonstracao &&
			itensParaVenda.length > 0
		) {
			subTotalDaVenda = itensParaVenda
				.reduce(
					(total, item) => total.add(obterTotalDoItem(item)),
					new Decimal(0),
				)
				.toNumber()
		} else {
			subTotalDaVenda = venda.itens
				.filter(item => item.quantidade > 0)
				.reduce(
					(total, item) => total.add(obterTotalDoItem(item)),
					new Decimal(0),
				)
				.toNumber()
		}

		const totalLiquidoDosItens = subTotalDaVenda
		const percentualDeDesconto = (valorDoDesconto / totalLiquidoDosItens) * 100
		return {
			isPercentual: true,
			valor: Number(percentualDeDesconto),
		}
	}
	return venda.desconto
}

export function obterDescontoEmPercentualSomentePositivo(
	venda: VendaBase,
): Desconto | null {
	if (venda.desconto && !venda.desconto.isPercentual) {
		const valorDoDesconto = venda.desconto.valor
		const totalLiquidoDosItens = obterTotalDosItensDaVendaPositivos(venda)
		const percentualDeDesconto = (valorDoDesconto / totalLiquidoDosItens) * 100
		return {
			isPercentual: true,
			valor: Number(percentualDeDesconto),
		}
	}
	return venda.desconto
}

export function obterPercentualDeRateioDeItensDaVenda(venda: VendaBase) {
	if (!venda.desconto) return 0
	if (venda.desconto.isPercentual) return venda.desconto.valor
	return (venda.desconto.valor * 100) / obterTotalDosItensDaVenda(venda)
}

export const obterRegraFiscalDoProduto = (
	produto: ProdutoFilho,
	regimeFiscal: RegimeFiscal,
) =>
	produto.regrasFiscais.find(
		regraDoProduto => regraDoProduto.regra.regimeFiscal === regimeFiscal,
	)?.regra

export function normalizarTurnoDeVenda(turnoDeVenda: TurnoDeVenda | null) {
	if (!turnoDeVenda) return null
	return {
		...turnoDeVenda,
		movimentacoes: turnoDeVenda.movimentacoes.map(movimentacao => ({
			...movimentacao,
			dataHoraMovimentacao: new Date(movimentacao.dataHoraMovimentacao),
		})),
	}
}

export async function imprimirValeTroca(
	valeTrocas: ValeTroca[],
): Promise<void> {
	const pdf: string = await gerarValeTrocas(valeTrocas)
	return new Promise((resolve, reject) => {
		print({
			printable: pdf,
			type: 'pdf',
			base64: true,
			onError: reject,
			onLoadingEnd: resolve,
		})
	})
}

export async function imprimirNotaFiscal(idVenda: string, imprimirNfe = false, isContingencia = false, reservarNumeroDaNota = false) {
	const token = UserLoginStore.token
	if (!token) throw new Error('Token não informado')

	if (imprimirNfe && !isContingencia) {
		const data = await request.get(`/vendas/${idVenda}/consulta-nota${reservarNumeroDaNota ? '?reservarNumeroDaNota=true' : ''}`) as any

		let status: string = data.status
		const tempoMedioResposta: number = data.tempoMedioResposta
		if (status == '103' && !reservarNumeroDaNota) {
			await new Promise(resolve => {
				setTimeout(resolve, tempoMedioResposta * 1000)
			})

			const data = (await request.get(
				`/vendas/${idVenda}/consulta-nota`,
			)) as any
			status = data.status

			if (status == '105')
				throw Error('Tempo limite de consulta excedido, consulte novamente')
		}
		if (status != '100' && status != '108' && status != '109' && !reservarNumeroDaNota) {
			throw Error(`Erro status ${status}, verifique o XML e tente novamente`)
		}
	}

	const response: any = await request.get(`/vendas/${idVenda}/nota-fiscal/${idVenda}.pdf?token=${token}${imprimirNfe ? '&imprimirNfe=true' : ''}&isContingencia=${isContingencia}
		&reservarNumeroDaNota=${reservarNumeroDaNota}`, {
		responseType: 'arraybuffer',
	}).catch(async error => {
		if (error?.response?.data) {
			let dataJson
			try {
				dataJson = JSON.parse(new TextDecoder("utf-8").decode(error.response.data))
				// eslint-disable-next-line no-empty
			} catch { }
			if (dataJson) {
				throw new Error(dataJson)
			}
			throw error
		}
	})

	if (!reservarNumeroDaNota) imprimePdf(response, idVenda)
}

export async function imprimirNotaFiscalComplementar(idVenda: string, infoNotaComplementar: InfoNotaComplementar) {
	const token = UserLoginStore.token
	if (!token) throw new Error('Token não informado')

	const response: any = await request.get(`/vendas/${idVenda}/nota-fiscal-complementar/${idVenda}.pdf?token=${token}`, {
		params: {
			infoNotaComplementar: infoNotaComplementar,
		},
		responseType: 'arraybuffer',
	}).catch(async error => {
		if (error?.response?.data) {
			let dataJson
			try {
				dataJson = JSON.parse(new TextDecoder("utf-8").decode(error.response.data))
				// eslint-disable-next-line no-empty
			} catch { }
			if (dataJson) {
				throw new Error(dataJson)
			}
			throw error
		}
	})

	imprimePdf(response, idVenda)
}

export async function emitirEComunicarDanfe(idVenda: string) {
	const token = UserLoginStore.token
	if (!token) throw new Error('Token não informado')

	const data = (await request.get(`/vendas/${idVenda}/consulta-nota`)) as any

	let status: string = data.status
	const tempoMedioResposta: number = data.tempoMedioResposta
	if (status == '103') {
		await new Promise(resolve => {
			setTimeout(resolve, tempoMedioResposta * 1000)
		})

		const data = (await request.get(`/vendas/${idVenda}/consulta-nota`)) as any
		status = data.status

		if (status == '105')
			throw Error('Tempo limite de consulta excedido, consulte novamente')
	}
	if (status != '100' && status != '108' && status != '109') {
		throw Error(`Erro status ${status}, verifique o XML e tente novamente`)
	}
}

export async function gerarNotaNaoEletronica(idVenda: string) {
	const token = UserLoginStore.token
	if (!token) throw new Error('Token não informado')

	return (await request.post(`/vendas/${idVenda}/nota-nao-eletronica`)) as ResumoDaVenda
}

export async function imprimirNotaEmTransito(notaEmTransito: NotaEmTransito) {
	const token = UserLoginStore.token
	if (!token) throw new Error('Token não informado')

	const response: any = await request
		.get(`/notas-em-transito/imprimir-nota`, {
			params: {
				urlNotaEmTransito: notaEmTransito.urlDoXml,
			},
			responseType: 'arraybuffer',
		})
		.catch(async error => {
			if (error?.response?.data) {
				let dataJson
				try {
					dataJson = JSON.parse(
						new TextDecoder('utf-8').decode(error.response.data),
					)
					// eslint-disable-next-line no-empty
				} catch { }
				if (dataJson) {
					throw new Error(dataJson)
				}
			}
			throw error
		})

	imprimePdf(response, notaEmTransito.chaveDanfe)
}


export async function imprimirComprovanteDeRecebivel(response: any) {
	imprimePdf(response, "recebiveis")
}

export async function imprimirCupomFuncionario(idVenda: string) {
	const response: any = await request
		.get(`/vendas/${idVenda}/cupom`, {
			responseType: 'arraybuffer',
		})
		.catch(async error => {
			if (error?.response?.data) {
				let dataJson
				try {
					dataJson = JSON.parse(
						new TextDecoder('utf-8').decode(error.response.data),
					)
					// eslint-disable-next-line no-empty
				} catch { }
				if (dataJson) {
					throw new Error(dataJson)
				}
			}
			throw error
		})

	imprimePdf(response, idVenda)
}

export async function imprimirCupomCrediario(idCliente: string, baixa: string) {
	const response = await request
		.get(`/vendas/${idCliente}/cupom-crediario`, {
			params: {
				baixaId: baixa,
			},
			responseType: 'arraybuffer',
		})
		.catch(async error => {
			if (error?.response?.data) {
				let dataJson
				try {
					dataJson = JSON.parse(
						new TextDecoder('utf-8').decode(error.response.data),
					)
					// eslint-disable-next-line no-empty
				} catch { }
				if (dataJson) {
					throw new Error(dataJson)
				}
			}
			throw error
		})
	imprimePdf(response, idCliente)
}

export async function imprimirEtiquetaDeEntrega(idVenda: string) {
	const response: any = await request
		.get(`/vendas/${idVenda}/etiqueta-transporte`, {
			responseType: 'arraybuffer',
		})
		.catch(async error => {
			if (error?.response?.data) {
				let dataJson
				try {
					dataJson = JSON.parse(
						new TextDecoder('utf-8').decode(error.response.data),
					)
					// eslint-disable-next-line no-empty
				} catch { }
				if (dataJson) {
					throw new Error(dataJson)
				}
			}
			throw error
		})

	imprimePdf(response, idVenda)
}

export async function setarVendaComoPresente(idVenda: string) {
	await request.patch(`/vendas/${idVenda}/presente`).catch(async error => {
		AlertModule.setError(error)
		throw error
	})
}

export async function imprimirNfceDaConsulta(
	chaveDeConsulta: string,
	chaveNF: string,
) {
	const response: any = await request
		.get(`/nf/consulta/${chaveDeConsulta}/impressao.pdf?chaveNF=${chaveNF}`, {
			responseType: 'arraybuffer',
		})
		.catch(async error => {
			if (error?.response?.data) {
				let dataJson
				try {
					dataJson = JSON.parse(
						new TextDecoder('utf-8').decode(error.response.data),
					)
					// eslint-disable-next-line no-empty
				} catch { }
				if (dataJson) {
					throw new Error(dataJson)
				}
			}
			throw error
		})

	imprimePdf(response, chaveDeConsulta)
}

export async function baixarXmlDaNotaFiscal(venda: Venda) {
	const token = UserLoginStore.token
	if (!token) throw new Error('Token não informado')
	const urls: string[] = await request.get(
		`/vendas/${venda.id}/nota-fiscal/xml`,
	)
	const informacoesDoXml = await Promise.all(
		urls.map(async url => {
			const fileName = decodeURIComponent(
				url.substring(url.lastIndexOf('/') + 1),
			)
			// eslint-disable-next-line no-useless-escape
			const cnpjDaLojaNaVenda = url.split(/[\/\\]/)[5]
			const presignedUrl = await request.post<
				{ fileName: string; contentType: string },
				string
			>('/pre-signed-url', {
				fileName: fileName,
				folderName: `xml/${cnpjDaLojaNaVenda}`,
				contentType: 'xml',
				httpMethod: 'GET',
			})

			const { data } = await Axios.get(presignedUrl)
			return {
				content: data,
				fileName,
			}
		}),
	)
	informacoesDoXml.forEach(xml =>
		fazDownloadXmlsDaNota(xml.content, xml.fileName),
	)
}

export async function baixarXmlCompletoDaNotaFiscal(venda: Venda) {
	const token = UserLoginStore.token
	if (!token) throw new Error('Token não informado')
	const urls = [0]
	const informacoesDoXml = await Promise.all(
		urls.map(async () => {
			const response = await request.get(`/vendas/${venda.id}/nfe/xml`)
			return {
				content: response,
				fileName: `XML_DANFE_${new Date().getTime()}_${venda.identificador
				}.xml`,
			}
		}),
	)
	informacoesDoXml.forEach(xml =>
		fazDownloadXmlsDaNota(xml.content, xml.fileName),
	)
}

function fazDownloadXmlsDaNota(text: string | any, fileName: string) {
	if (!text || !fileName) return

	const base64 = btoa(unescape(encodeURIComponent(text)))
	const content = `data:text/xml;base64,${base64}`
	const encodedUri = encodeURI(content)
	const link = document.createElement('a')
	link.href = encodedUri
	link.download = fileName
	document.body.appendChild(link)
	link.click()
	document.body.removeChild(link)
}

function imprimePdf(response: any, nomeDoArquivo: string) {
	const url = window.URL.createObjectURL(new Blob([response]))
	return Promise.race([
		new Promise<void>((resolve, reject) => {
			print({
				documentTitle: `${nomeDoArquivo}.pdf`,
				printable: url,
				onError: error => reject(error),
				onLoadingEnd: () => resolve(),
			})
		}),
		new Promise((resolve, reject) =>
			setTimeout(
				() => reject('Erro ao gerar impressão, verifique as configurações'),
				15000,
			),
		),
	])
}

export const listasDeModalidadesDeFrete: ModalidadeDeFrete[] = [
	'Por Conta do Emitente (CIF)',
	'Por Conta do Destinatário (FOB)',
	'Por Conta de Terceiros',
	'Sem Frete',
]

export function unificarItensDaVendaPorProduto(itens: ItemDaVenda[]) {
	const itensUnificados: any[] = []
	itens.forEach(item => {
		const indice = itensUnificados.findIndex(
			({ id }) => id && id === item.produto.id,
		)
		if (indice === -1) {
			itensUnificados.push({
				id: item.produto.id,
				quantidade: item.quantidade,
				autenticadoParaLiberarProdutoSemEstoque: item.autenticadoParaLiberarProdutoSemEstoque,
			})
		} else {
			const itemAnterior = itensUnificados[indice]
			itensUnificados.splice(indice, 1, {
				...itemAnterior,
				quantidade: itemAnterior.quantidade + item.quantidade,
			})
		}
	})
	return itensUnificados
}

export function contarItensDaDemonstracao(venda: Venda) {
	return venda.itens.filter(item => item.isCompra === false && item.quantidade > 0).map(item => item.quantidade).reduce((soma, valor) => soma + valor, 0)
}

export function contarItensDaDevolucao(
	novaVenda: Venda,
	vendaDevolucaoConsignada: Venda | null,
	vendaDevolucaoDemonstracao: Venda | null,
) {
	let vendaASerDevolvida: Venda | null = null;
	if (novaVenda.isConsignado) {
		vendaASerDevolvida = vendaDevolucaoConsignada;
	} else if (novaVenda.isDemonstracao) {
		vendaASerDevolvida = vendaDevolucaoDemonstracao;
	}

	if (!vendaASerDevolvida) {
		return 0;
	}

	let quantidadeDevolvida = 0

	vendaASerDevolvida.itens.forEach(itemOrigem => {
		const itensDevolvidosParcialmente = novaVenda.itens.filter(item =>
			item.quantidade === 0 && novaVenda.itens.filter(itemNV => itemNV.produto.id === item.produto.id && itemOrigem.produto.id === itemNV.produto.id).length > 1)
		const itensDevolvidosCompletamente = novaVenda.itens.filter(item =>
			item.quantidade === 0 && novaVenda.itens.filter(itemNV => itemNV.produto.id === item.produto.id && itemOrigem.produto.id === itemNV.produto.id).length === 1)

		if (itensDevolvidosParcialmente.length > 0) {
			quantidadeDevolvida += itensDevolvidosParcialmente.length
		}

		if (itensDevolvidosCompletamente.length > 0) {
			quantidadeDevolvida += itemOrigem.quantidade
		}
	})

	return quantidadeDevolvida
}

export async function imprimirConfissaoDeDivida(vendaId: string) {
	const response: any = await request
		.get(`/vendas/${vendaId}/confissao-de-divida`, {
			responseType: 'arraybuffer',
		})
		.catch(async error => {
			if (error?.response?.data) {
				let dataJson
				try {
					dataJson = JSON.parse(
						new TextDecoder('utf-8').decode(error.response.data),
					)
					// eslint-disable-next-line no-empty
				} catch { }
				if (dataJson) {
					throw new Error(dataJson)
				}
			}
			throw error
		})

	imprimePdf(response, vendaId)
}

export async function imprimirCarneDeCrediario(vendaId: string) {
	const response: any = await request
		.get(`/vendas/${vendaId}/carne-de-crediario`, {
			responseType: 'arraybuffer',
		})
		.catch(async error => {
			if (error?.response?.data) {
				let dataJson
				try {
					dataJson = JSON.parse(
						new TextDecoder('utf-8').decode(error.response.data),
					)
					// eslint-disable-next-line no-empty
				} catch { }
				if (dataJson) {
					throw new Error(dataJson)
				}
			}
			throw error
		})

	imprimePdf(response, vendaId)
}

export async function imprimirVoucher(voucher: Voucher) {
	const token = UserLoginStore.token
	if (!token) throw new Error('Token não informado')

	const response: any = await request
		.get(`/vouchers/imprimir-voucher`, {
			params: {
				voucherId: voucher.id,
			},
			responseType: 'arraybuffer',
		})
		.catch(async error => {
			if (error?.response?.data) {
				let dataJson
				try {
					dataJson = JSON.parse(
						new TextDecoder('utf-8').decode(error.response.data),
					)
					// eslint-disable-next-line no-empty
				} catch { }
				if (dataJson) {
					throw new Error(dataJson)
				}
			}
			throw error
		})

	imprimePdf(response, voucher.id)
}

export async function imprimirCartaCorrecao(cartaCorrecao: CartaDeCorrecao) {
	const token = UserLoginStore.token
	if (!token) throw new Error('Token não informado')

	const response: any = await request
		.get(`/carta-de-correcao/imprimir-carta`, {
			params: {
				cartaId: cartaCorrecao.id,
				chNFe: cartaCorrecao.chaveNFe,
			},
			responseType: 'arraybuffer',
		})
		.catch(async error => {
			if (error?.response?.data) {
				let dataJson
				try {
					dataJson = JSON.parse(
						new TextDecoder('utf-8').decode(error.response.data),
					)
					// eslint-disable-next-line no-empty
				} catch { }
				if (dataJson) {
					throw new Error(dataJson)
				}
			}
			throw error
		})

	imprimePdf(response, cartaCorrecao.chaveNFe)

}

export function obterDisplayClienteDaDemonstracaoResumida(venda: VendaDemonstracaoResumida) {
	if (!venda.cnpjOuCpf) return ''
	const cnpjOuCpfSemMascara = removerFormatacaoDeCnpjOuCpf(venda.cnpjOuCpf)
	let display =
		cnpjOuCpfSemMascara.length <= 11
			? aplicarMascaraParaCpfOculto(cnpjOuCpfSemMascara)
			: formatarCnpjOuCpf(venda.cnpjOuCpf)
	if (venda.cliente) {
		display = display + ', ' + venda.cliente
	}
	return display
}