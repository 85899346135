import { Loja, Page } from '@/models'
import { GrupoEconomico } from '@/models/GrupoEconomico'
import { AxiosRequestConfig } from 'axios'
import { Inject } from 'inversify-props'
import type { GrupoEconomicoServiceAdapter } from './adapter'

export class FindGrupoEconomicoUseCase {
	@Inject('GrupoEconomicoServiceAdapter')
	private grupoEconomicoServiceAdapter!: GrupoEconomicoServiceAdapter

	find = async (): Promise<GrupoEconomico[]> =>
		await this.grupoEconomicoServiceAdapter.find()

	findByParams = async (params?: any, config?: AxiosRequestConfig): Promise<Page<GrupoEconomico>> =>
		await this.grupoEconomicoServiceAdapter.findByParams(params, config)

	findLojasByGrupoEconomico = async (grupoEconomicoId: string): Promise<Loja[]> =>
		await this.grupoEconomicoServiceAdapter.findLojasByGrupoEconomico(grupoEconomicoId)

	findGruposDoCliente = async (cpfCnpjCliente: string, params?: any, config?: AxiosRequestConfig): Promise<Page<GrupoEconomico>> =>
		await this.grupoEconomicoServiceAdapter.findGruposDoCliente(cpfCnpjCliente, params, config)

	listarGruposDoFornecedor = async (cpfCnpjFornecedor: string, params?: any, config?: AxiosRequestConfig): Promise<Page<GrupoEconomico>> => 
		await this.grupoEconomicoServiceAdapter.listarGruposDoFornecedor(cpfCnpjFornecedor, params, config)
}
