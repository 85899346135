import { injectable } from 'inversify-props'
import { CentroDeCusto } from '@/models/financeiro/Titulo'
import { findCentroDeCusto, createCentroDeCusto, updateCentroDeCusto, findCentroDeCustoByLojaId } from '@/api/titulos'
import { AxiosRequestConfig } from 'axios'
import { CentroDeCustoServiceAdapter } from '@/usecases/titulos/adapter/CentroDeCustoServiceAdapter'

@injectable()
export class CentroDeCustoService implements CentroDeCustoServiceAdapter {
	findCentroDeCusto = async (params?: any, config?: AxiosRequestConfig) => await findCentroDeCusto(params, config)
	createCentroDeCusto = async (centroDeCusto: CentroDeCusto) => await createCentroDeCusto(centroDeCusto)
	updateCentroDeCusto = async (centroDeCusto: CentroDeCusto) => await updateCentroDeCusto(centroDeCusto)
	findCentroDeCustoByLojaId = async (params?: any, config?: AxiosRequestConfig) => await findCentroDeCustoByLojaId(params, config)
}