import request from "@/common/request";
import { ItemDeRomaneio, ListagemItensRomaneio } from "@/models/Romaneio";
import { AxiosRequestConfig } from "axios";

const basePath = '/item-romaneio'

export const findItensRomaneio = async (
	params?: any,
	config?: AxiosRequestConfig,
): Promise<ListagemItensRomaneio> => 
	request.get(`${basePath}`, { params, ...config })

export const findAllItensRomaneio = async (
	romaneioId: string,
): Promise<ItemDeRomaneio[]> => 
	request.get(`${basePath}/all-items/${romaneioId}`)