import 'reflect-metadata'
import { injectable } from 'inversify-props'
import { EstoqueServiceAdapter, FiltroDeEstoques } from '@/usecases/deposito/adapter/EstoqueServiceAdapter'
import {
	createEstoque,
	adicionarQtdeEstoque,
	retirarQtdeEstoque,
	empenharEstoque,
	reaverEstoque,
	deletarEstoque,
	montarEstoque,
	desmontarEstoque,
	buscarEstoques,
	encontrarProdutoComEstoque,
	getEstoque,
	importaItens,
	getEstoqueDoItemDaVendaNaLoja,
	exportEstoque,
	alterarEstoqueMinimo,
	encontrarProdutoComEstoqueRomaneio,
	validarProduto,
	montarItens,
	findAllByDeposito,
	validaEstoqueNegativando,
} from '@/api/deposito'
import { Estoque, EstoqueProjection, FiltroDaTelaDeEstoques, FiltroDeMovimentacaoDeEstoque, FormDeItemDeEstoque, ImportacaoCsvEstoque, Page } from '@/models'
import { FormDeConversaoDeEstoque } from '@/models/estoque/ConversaoDeEstoque'
import { AxiosRequestConfig } from 'axios'
import { findEstoqueEmGradePorProduto, findEstoques, findMovimentacaoAnalitica, findOtimizado, findMovimentacaoSintetica, findDetalhamento, findMovimentacaoAnaliticaCompleta } from '@/api/estoque'
import { RomaneioForm } from '@/models/Romaneio'
import { atualizar, findRomaneio, salvarRomaneio, update } from '@/api/romaneio'
import { findItensRomaneio, findAllItensRomaneio } from '@/api/itemRomaneio'


@injectable()
export class EstoqueService implements EstoqueServiceAdapter {

	update = async (romaneio: RomaneioForm) =>
		await update(romaneio)

	salvarRomaneio = async (romaneioForm: RomaneioForm) => await salvarRomaneio(romaneioForm)

	findRomaneio = async (depositoId: string, params?: any, config?: AxiosRequestConfig) => await findRomaneio(depositoId, params, config)

	findItensRomaneio = async (params?: any, config?: AxiosRequestConfig) => await findItensRomaneio(params, config)

	create = async (idDeposito: string, estoque: FormDeItemDeEstoque) =>
		await createEstoque(idDeposito, estoque)

	add = async (idEstoque: string, qtde: number, observacao: string) =>
		await adicionarQtdeEstoque(idEstoque, qtde, observacao)

	retirar = async (idEstoque: string, qtde: number, observacao: string) =>
		await retirarQtdeEstoque(idEstoque, qtde, observacao)

	empenhar = async (idEstoque: string, qtde: number, observacao: string) =>
		await empenharEstoque(idEstoque, qtde, observacao)

	reaver = async (idEstoque: string, qtde: number, observacao: string) =>
		await reaverEstoque(idEstoque, qtde, observacao)

	remove = async (idDeposito: string, idEstoque: string) =>
		await deletarEstoque(idDeposito, idEstoque)

	montar = async (
		idDeposito: string,
		conversaoForm: FormDeConversaoDeEstoque,
	) => await montarEstoque(idDeposito, conversaoForm)

	desmontar = async (
		idDeposito: string,
		conversaoForm: FormDeConversaoDeEstoque,
	) => await desmontarEstoque(idDeposito, conversaoForm)

	async findEstoques(params?: FiltroDeEstoques, config?: AxiosRequestConfig): Promise<Page<Estoque>> {
		return await findEstoques(params, config)
	}

	buscarEstoques = async (
		params?: FiltroDaTelaDeEstoques,
		config?: AxiosRequestConfig,
	) => await buscarEstoques(params, config)

	get = async (idDeposito: string, idEstoque: string) =>
		await getEstoque(idDeposito, idEstoque)

	encontrarProdutoComEstoque = async (identificador: string, loja: string) =>
		await encontrarProdutoComEstoque(identificador, loja)

	validarProduto = async (identificador: string, loja: string, quantidade: number, validar?: boolean) =>
		await validarProduto(identificador, loja, quantidade, validar)

	encontrarProdutoComEstoqueRomaneio = async (identificador: string, loja: string, quantidade: number, tipoDeMovimentacao: string) =>
		await encontrarProdutoComEstoqueRomaneio(identificador, loja, quantidade, tipoDeMovimentacao)


	importaItens = async (idLoja: string, params?: ImportacaoCsvEstoque) =>
		await importaItens(idLoja, params)

	getEstoqueDoItemDaVendaNaLoja = async (idProduto: string, quantidadeDeItens: string, idLoja: string, autenticadoParaLiberarProdutoSemEstoque: boolean) =>
		await getEstoqueDoItemDaVendaNaLoja(idProduto, quantidadeDeItens, idLoja, autenticadoParaLiberarProdutoSemEstoque)

	findOtimizado = async (params?: any, config?: AxiosRequestConfig) => await findOtimizado(params, config)

	exportEstoque = async (idTabela: string, layoutExportado: string) =>
		await exportEstoque(idTabela, layoutExportado)

	alterarEstoqueMinimo = async (idEstoque: string, qtdeEstoqueMinimo: EstoqueProjection) => await alterarEstoqueMinimo(idEstoque, qtdeEstoqueMinimo)

	findEstoqueEmGradePorProduto = async (params?: FiltroDeEstoques, config?: AxiosRequestConfig) => await findEstoqueEmGradePorProduto(params, config)

	findMovimentacaoAnalitica = async (filtro: FiltroDeMovimentacaoDeEstoque, config?: AxiosRequestConfig) => await findMovimentacaoAnalitica(filtro, config)

	findMovimentacaoSintetica = async (filtro: FiltroDeMovimentacaoDeEstoque, config?: AxiosRequestConfig) => await findMovimentacaoSintetica(filtro, config)

	montarItens = async (urlDoArquivoCsv: string) => await montarItens(urlDoArquivoCsv)

	findDetalhamento = async (params: any, config?: AxiosRequestConfig) => await findDetalhamento(params, config)

	findMovimentacaoAnaliticaCompleta = async (filtro: FiltroDeMovimentacaoDeEstoque, config?: AxiosRequestConfig) => await findMovimentacaoAnaliticaCompleta(filtro, config)

	atualizar = async (romaneio: RomaneioForm) =>
		await atualizar(romaneio)

	findAllByDeposito = async (depositoId: string, dataDoInventario: string) => await findAllByDeposito(depositoId, dataDoInventario)

	validaEstoqueNegativando = async (identificadores: string[], loja: string, quantidades: number[]) =>
		await validaEstoqueNegativando(identificadores, loja, quantidades)

	findAllItensRomaneio = async (romaneioId: string) => await findAllItensRomaneio(romaneioId)
}
