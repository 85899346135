import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { Inject } from 'inversify-props'
import type { VendaServiceAdapter } from './adapter'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import { criarVendaDoPdv } from '@/shareds/venda-shareds'
import { Cliente, DadosParaReaberturaMultiplaDeVendas, ItemDaVenda, NotaDaVenda } from "@/models"
import { CancelarVendaUseCase } from './CancelarVendaUseCase'

export class ReabrirVendaUseCase {
	@Inject('VendaServiceAdapter')
	private vendaService!: VendaServiceAdapter
	private cancelarVendaUseCase!: CancelarVendaUseCase

	constructor() {
		this.cancelarVendaUseCase = new CancelarVendaUseCase()
	}

	async reabrir(identificador: string, devolverTudo: boolean) {
		try {
			const page = await this.vendaService.find({
				identificador,
			})
			const vendaOriginal = page.content[0]

			if (!vendaOriginal) throw new Error('Venda não encontrada')
			if (!vendaOriginal.pontoDeVenda) throw new Error('Venda não tem ponto de venda')
			if (vendaOriginal.temDevolucao) throw new Error('Essa venda já foi devolvida')
			if (vendaOriginal.tipoDeTransacao === 'Devolução') throw new Error('Infelizmente, essa venda é uma devolução e não pode ser reaberta.')
			if (this.existeNotaEmitida(vendaOriginal.notas) && !vendaOriginal.isConsignado && !vendaOriginal.isDemonstracao) throw new Error('Essa venda não pode ser recuperada. Possui Nota Fiscal emitida')
			if (vendaOriginal.situacao === 'Cancelada' && (vendaOriginal.isConsignado || vendaOriginal.isDemonstracao)) throw new Error('Essa venda já está cancelada')
			if ((vendaOriginal.isConsignado || vendaOriginal.isDemonstracao) && !this.existeNotaAprovada(vendaOriginal.notas)) throw new Error('Essa venda não pode ser recuperada. Não possui Nota Fiscal emitida')

			const novoIdentificador = await this.vendaService.gerarIdentificadorDeVenda(vendaOriginal.pontoDeVenda.id)

			if (vendaOriginal.situacao !== 'Cancelada' && !(vendaOriginal.isConsignado || vendaOriginal.isDemonstracao)) {
				try {
					await this.cancelarVendaUseCase
						.cancelar(vendaOriginal.id)
				} catch (error: any) {
					VendaModule.iniciarNovaVenda({ identificador: novoIdentificador, novaVendaReaberta: true })
					throw error
				}
			}

			const novaVenda = criarVendaDoPdv(vendaOriginal.pontoDeVenda)
			if (vendaOriginal.notas.length === 0 && (vendaOriginal.isDemonstracao || vendaOriginal.isConsignado)) novaVenda.id = vendaOriginal.id

			if (vendaOriginal.vendedor && vendaOriginal.vendedor.length > 0) {
				novaVenda.vendedor = [vendaOriginal.vendedor[0]]
			}

			novaVenda.cpfDoCliente = vendaOriginal.cpfDoCliente
			novaVenda.cliente = vendaOriginal.cliente
			novaVenda.tipoDeCliente = vendaOriginal.tipoDeCliente
			novaVenda.itens = vendaOriginal.itens.map<ItemDaVenda>(item => ({
				id: '',
				desconto: item.desconto,
				quantidade: item.quantidade,
				quantidadeLida: 0,
				preco: item.preco,
				precoDeCusto: item.precoDeCusto,
				produto: item.produto,
				idConjunto: item.idConjunto,
				descontoVarejo: item.descontoVarejo,
				percentualCashback: item.percentualCashback,
				diasParaEfetivarCashback: item.diasParaEfetivarCashback,
				isBrinde: false,
				idItemNotaOrigem: null,
				chaveNotaOrigem: null,
				diasParaExpirarCashback: item.diasParaExpirarCashback,
				possuiDescontoPorTabela: item.desconto.valor > 0 ? true : false,
				valorDescontoRateadoDoItem: 0,
				itemDeDevolucao: item.itemDeDevolucao,
				isTotalmenteTrocado: item.isTotalmenteTrocado,
				motivoCancelamento: item.motivoCancelamento,
				isCompra: false,
				idItemOrigem: '',
				isDaVendaReaberta: true,
				vendedor: item.vendedor,
				autenticadoParaLiberarProdutoSemEstoque: item.autenticadoParaLiberarProdutoSemEstoque,
				vendaOrigemDoItem: vendaOriginal.id,
				valorFreteRateado: 0,
				isItemReferenteATroca: item.isItemReferenteATroca,
				itemVendaNaoEletronica: vendaOriginal.possuiNotaNaoEletronica || false,
				isDeOutraLoja: false,
				percentualDescontoTabelaPreco: item.desconto.valor,
			}))

			novaVenda.desconto = vendaOriginal.desconto
			novaVenda.vendaOrigem = vendaOriginal.isConsignado || vendaOriginal.isDemonstracao ? vendaOriginal.id : vendaOriginal.vendaOrigem
			novaVenda.tipoDeTransacao = vendaOriginal.tipoDeTransacao
			novaVenda.temDevolucao = vendaOriginal.temDevolucao
			novaVenda.duplicadoDe = {
				id: vendaOriginal.id,
				identificador: vendaOriginal.identificador,
			}
			novaVenda.isConsignado = vendaOriginal.isConsignado
			novaVenda.isDemonstracao = vendaOriginal.isDemonstracao
			novaVenda.notas = vendaOriginal.notas
			novaVenda.possuiNotaNaoEletronica = vendaOriginal.possuiNotaNaoEletronica

			if (vendaOriginal.notas.filter(nota => nota.cstat === '100').length === 0) {
				novaVenda.modalidadeDeVenda = vendaOriginal.modalidadeDeVenda
			}

			VendaModule.iniciarNovaVenda({ identificador: novoIdentificador, novaVenda, novaVendaReaberta: true, devolverTudo: devolverTudo })

		} catch (e) {
			AlertModule.setError(e)
			throw e
		}

	}

	async reabrirVendasMultiplas(vendas: DadosParaReaberturaMultiplaDeVendas[], cliente: Cliente, retornoMultiploDeDemonstracao: boolean) {
		try {
			const pontoDeVenda = VendaModule.vendaAtual?.pontoDeVenda
			const todosOsItensDasVendas = vendas.flatMap(venda =>
				venda.itens.map(item => ({
					...item,
					vendaOrigemDoItem: venda.id,
					itemVendaNaoEletronica: venda.possuiNotaNaoEletronica,
				})),
			) as ItemDaVenda[]

			const todosOsVendedores = todosOsItensDasVendas.map(item => item.vendedor).filter(item => item !== null)
			const idsVendedoresSet = Array.from(new Set(todosOsVendedores.map(vendedor => vendedor && vendedor.id)))
			const listaComAContagemDosVendedores = idsVendedoresSet
				.map(vendedorId => {
					if (vendedorId) {
						return {
							id: vendedorId,
							quantidade: todosOsVendedores.filter(vendedor => vendedor && vendedor.id === vendedorId).length,
						}
					}
				})
			
			const idVendedorPrincipal = listaComAContagemDosVendedores.length
				? listaComAContagemDosVendedores.reduce(
					(prev, curr) => ((curr?.quantidade ?? 0) > (prev?.quantidade ?? 0) ? curr : prev),
				)?.id
				: null;

			const vendedorPrincipal = todosOsVendedores.find(vendedor => vendedor?.id === idVendedorPrincipal) || null;

			if (!pontoDeVenda) return AlertModule.setError("Ocorreu um erro interno, contate o suporte")
			const novaVenda = criarVendaDoPdv(pontoDeVenda)
			const novoIdentificador = await this.vendaService.gerarIdentificadorDeVenda(pontoDeVenda.id)

			novaVenda.cpfDoCliente = cliente.cnpjOuCpf
			novaVenda.cliente = cliente
			novaVenda.tipoDeCliente = cliente.tipoDeCliente
			novaVenda.isDemonstracao = retornoMultiploDeDemonstracao ? true : false
			novaVenda.isConsignado = !retornoMultiploDeDemonstracao ? true : false
			novaVenda.isVendaReaberta = true
			novaVenda.possuiNotaNaoEletronica = vendas.some(venda => venda.possuiNotaNaoEletronica)
			novaVenda.vendedor = vendedorPrincipal ? [vendedorPrincipal] : null

			novaVenda.itens = todosOsItensDasVendas.map<ItemDaVenda>(item => ({
				id: '',
				desconto: item.desconto,
				quantidade: item.quantidadeLida
					? (item.quantidade - item.quantidadeLida)
					: item.quantidade,
				quantidadeLida: 0,
				preco: item.preco,
				precoDeCusto: item.precoDeCusto,
				produto: item.produto,
				idConjunto: item.idConjunto || undefined,
				descontoVarejo: item.descontoVarejo,
				percentualCashback: item.percentualCashback,
				diasParaEfetivarCashback: item.diasParaEfetivarCashback,
				isBrinde: false,
				idItemNotaOrigem: null,
				chaveNotaOrigem: null,
				diasParaExpirarCashback: item.diasParaExpirarCashback,
				possuiDescontoPorTabela: item.desconto.valor > 0 ? true : false,
				valorDescontoRateadoDoItem: 0,
				itemDeDevolucao: item.itemDeDevolucao,
				isTotalmenteTrocado: item.isTotalmenteTrocado,
				motivoCancelamento: item.motivoCancelamento,
				isCompra: (item.quantidade - item.quantidadeLida) > 0
					? item.isCompra
					: false,
				idItemOrigem: '',
				isDaVendaReaberta: true,
				vendedor: item.vendedor,
				autenticadoParaLiberarProdutoSemEstoque: item.autenticadoParaLiberarProdutoSemEstoque,
				vendaOrigemDoItem: item.vendaOrigemDoItem,
				valorFreteRateado: 0,
				isItemReferenteATroca: false,
				itemVendaNaoEletronica: item.itemVendaNaoEletronica,
				isDeOutraLoja: false,
				percentualDescontoTabelaPreco: item.desconto.valor,
			}))

			// Este código adiciona os itens devolvidos à nova venda, garantindo que eles sejam exibidos para o cliente 
			if (todosOsItensDasVendas.some(item => item.quantidadeLida > 0)) {
				const itemParaDevolucao = todosOsItensDasVendas.filter(item => item.quantidadeLida > 0)
				const itensDevolucao: ItemDaVenda[] = []

				itemParaDevolucao.forEach(item => {
					const contador = (item.quantidade - item.quantidadeLida) === 0 ? item.quantidadeLida - 1 : item.quantidadeLida
					for (let i = 0; i < contador; i++) {
						const itemDevolvidos = {
							id: '',
							desconto: item.desconto,
							quantidade: 0,
							quantidadeLida: 0,
							preco: item.preco,
							precoDeCusto: item.precoDeCusto,
							produto: item.produto,
							idConjunto: item.idConjunto || undefined,
							descontoVarejo: item.descontoVarejo,
							percentualCashback: item.percentualCashback,
							diasParaEfetivarCashback: item.diasParaEfetivarCashback,
							isBrinde: false,
							idItemNotaOrigem: null,
							chaveNotaOrigem: null,
							diasParaExpirarCashback: item.diasParaExpirarCashback,
							possuiDescontoPorTabela: item.desconto.valor > 0 ? true : false,
							valorDescontoRateadoDoItem: 0,
							itemDeDevolucao: item.itemDeDevolucao,
							isTotalmenteTrocado: item.isTotalmenteTrocado,
							motivoCancelamento: item.motivoCancelamento,
							isCompra: false,
							idItemOrigem: '',
							isDaVendaReaberta: true,
							vendedor: item.vendedor,
							autenticadoParaLiberarProdutoSemEstoque: item.autenticadoParaLiberarProdutoSemEstoque,
							vendaOrigemDoItem: item.vendaOrigemDoItem,
							valorFreteRateado: 0,
							isItemReferenteATroca: false,
							itemVendaNaoEletronica: item.itemVendaNaoEletronica,
							isDeOutraLoja: item.isDeOutraLoja,
							percentualDescontoTabelaPreco: item.desconto.valor,
						}
						itensDevolucao.push(itemDevolvidos)
					}
				})

				if (itensDevolucao.length > 0) {
					novaVenda.itens = [...novaVenda.itens, ...itensDevolucao]
				}
			}

			VendaModule.iniciarNovaVenda({ identificador: novoIdentificador, novaVenda, novaVendaReaberta: true })

			if (todosOsItensDasVendas.some(item => item.quantidadeLida > 0)) {
				const vendaDevolucao = criarVendaDoPdv(pontoDeVenda)

				vendaDevolucao.cpfDoCliente = cliente.cnpjOuCpf
				vendaDevolucao.cliente = cliente
				vendaDevolucao.tipoDeCliente = cliente.tipoDeCliente
				vendaDevolucao.isDemonstracao = retornoMultiploDeDemonstracao ? true : false
				vendaDevolucao.isConsignado = !retornoMultiploDeDemonstracao ? true : false
				vendaDevolucao.isVendaReaberta = true
				vendaDevolucao.itens = todosOsItensDasVendas.map<ItemDaVenda>(item => ({
					id: '',
					desconto: item.desconto,
					quantidade: item.quantidade,
					quantidadeLida: 0,
					preco: item.preco,
					precoDeCusto: item.precoDeCusto,
					produto: item.produto,
					idConjunto: item.idConjunto || undefined,
					descontoVarejo: item.descontoVarejo,
					percentualCashback: item.percentualCashback,
					diasParaEfetivarCashback: item.diasParaEfetivarCashback,
					isBrinde: false,
					idItemNotaOrigem: null,
					chaveNotaOrigem: null,
					diasParaExpirarCashback: item.diasParaExpirarCashback,
					possuiDescontoPorTabela: item.desconto.valor > 0 ? true : false,
					valorDescontoRateadoDoItem: 0,
					itemDeDevolucao: item.itemDeDevolucao,
					isTotalmenteTrocado: item.isTotalmenteTrocado,
					motivoCancelamento: item.motivoCancelamento,
					isCompra: item.isCompra || false,
					idItemOrigem: '',
					isDaVendaReaberta: true,
					vendedor: item.vendedor,
					autenticadoParaLiberarProdutoSemEstoque: item.autenticadoParaLiberarProdutoSemEstoque,
					vendaOrigemDoItem: item.vendaOrigemDoItem,
					valorFreteRateado: 0,
					isItemReferenteATroca: false,
					itemVendaNaoEletronica: item.itemVendaNaoEletronica,
					isDeOutraLoja: false,
					percentualDescontoTabelaPreco: item.desconto.valor,
				}))

				VendaModule.setVendaDevolucaoDemonstracao(vendaDevolucao)
			}

		} catch (e) {
			AlertModule.setError(e)
			throw e
		}

	}

	existeNotaEmitida(notas: NotaDaVenda[]) {
		const notasEmitidas = notas.filter(notaFiscal => notaFiscal.cstat === '100')
		return notasEmitidas.length > 0
	}

	existeNotaAprovada(notas: NotaDaVenda[]) {
		const notasEmitidas = notas.filter(notaFiscal => notaFiscal.cstat === '100' || notaFiscal.cstat === '301' || notaFiscal.cstat === '302')
		return notas.length == 0 ? true : notasEmitidas.length > 0
	}
}