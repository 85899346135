import { TipoDeRecurso } from '@/models/Recurso'
import { tituloDaToolbarPeloNomeDaRota } from '@/shareds/router-shareds'
import { RouteConfig } from 'vue-router'

const rotas: RouteConfig[] = [
	{
		path: 'grade-de-produtos',
		name: 'Grade de Produtos',
		components: {
			default: () =>
				import('@/views/application/produtos/TelaDeGradeDeProduto.vue'),
			toolbar: () =>
				import('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: {
			toolbar: tituloDaToolbarPeloNomeDaRota,
		},
		meta: {
			recurso: 'grade-de-produtos' as TipoDeRecurso,
		},
	},
]

export default rotas