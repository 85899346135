import { injectable } from 'inversify-props'
import { GradeDeProdutos } from '@/models'
import { AxiosRequestConfig } from 'axios'
import { createGrade, findGrade, removeGrade, updateGrade } from '@/api/gradeDeProdutos'
import { GradeDeProdutosServiceAdapter } from '@/usecases/produto/gradeDeProdutos/adapter'

@injectable()
export class GradeDeProdutosService implements GradeDeProdutosServiceAdapter {
	find = async (params?: any, config?: AxiosRequestConfig) => await findGrade(params, config)
	create = async (gradeDeProdutos: GradeDeProdutos) => await createGrade(gradeDeProdutos)
	update = async (gradeDeProdutos: GradeDeProdutos) => await updateGrade(gradeDeProdutos)
	delete = async (gradeId: string) => await removeGrade(gradeId)
}
