import request from "@/common/request";
import { Loja, Page } from "@/models";
import { GrupoEconomico } from "@/models/GrupoEconomico";
import { AxiosRequestConfig } from "axios";

const basePath = '/grupos-economicos'

export const create = (grupoEconomico: GrupoEconomico): Promise<GrupoEconomico> =>
	request.post(`${basePath}`, grupoEconomico)

export const update = (grupoEconomico: GrupoEconomico): Promise<GrupoEconomico> =>
	request.put(`${basePath}/${grupoEconomico.id}`, grupoEconomico)

export const find = (): Promise<GrupoEconomico[]> =>
	request.get(`${basePath}`)

export const findLojasByGrupoEconomico = (grupoEconomicoId: string): Promise<Loja[]> =>
	request.get(`${basePath}/${grupoEconomicoId}/lojas`)

export const findByParams = (params?: any, config?: AxiosRequestConfig): Promise<Page<GrupoEconomico>> =>
	request.get(`${basePath}/filtro`, {
		params,
		...config,
	})

export const deleteGrupoEconomico = (grupoEconomicoId: string): Promise<void> =>
	request.delete(`${basePath}/${grupoEconomicoId}`)

export const findGruposDoCliente = (cpfCnpjCliente: string, params?: any, config?: AxiosRequestConfig): Promise<Page<GrupoEconomico>> =>
	request.get(`${basePath}/cliente/${cpfCnpjCliente}`, {
		params,
		...config,
	})

export const listarGruposDoFornecedor = (cpfCnpjFornecedor: string, params?: any, config?: AxiosRequestConfig): Promise<Page<GrupoEconomico>> =>
	request.get(`${basePath}/fornecedor/${cpfCnpjFornecedor}`, {
		params,
		...config,
	})