import { Inject } from 'inversify-props'
import { GradeDeProdutos } from '@/models'
import type { GradeDeProdutosServiceAdapter } from './adapter/GradeDeProdutosServiceAdapter'

export class UpdateGradeDeProdutosUseCase {
	@Inject('GradeDeProdutosServiceAdapter')
	private gradeDeProdutosAdapter!: GradeDeProdutosServiceAdapter

	update = async (gradeDeProdutos: GradeDeProdutos): Promise<GradeDeProdutos> =>
		this.gradeDeProdutosAdapter.update(gradeDeProdutos)
}
