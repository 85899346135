import request from '@/common/request'
import { Titulo, Page, EntradaDeTitulo, TituloComParcelamento, TotalizadoresDeTitulo, TitulosPorFornecedor } from '@/models'
import { AxiosRequestConfig } from 'axios'
import { SituacaoDoTitulo } from '@/models'
import { CentroDeCusto } from '@/models/financeiro/Titulo'

const basePath = '/titulos'

export const create = (params: TituloComParcelamento): Promise<Titulo> =>
	request.post(`${basePath}`, params)

export const update = (data: EntradaDeTitulo): Promise<Titulo> => 
	request.put(`${basePath}/${data.id}`, data)

export const findTitulos = async (params?: any, config?: AxiosRequestConfig): Promise<Page<Titulo>> =>
	request.get(`${basePath}`, { params, ...config })

export const exportCsv = async (params?: any, config?: AxiosRequestConfig): Promise<any> =>
	request.get(`${basePath}/csv`, { params, ...config })

export const findTotalizador = async (params?: any, config?: AxiosRequestConfig): Promise<TotalizadoresDeTitulo> =>
	request.get(`${basePath}/totalizador`, { params, ...config })

export const findParaBaixa = async (params?: any, config?: AxiosRequestConfig): Promise<Page<Titulo>> =>
	request.get(`${basePath}/busca-para-baixa`, { params, ...config })

export const getTitulo = async (id?: string | null, config?: AxiosRequestConfig): Promise<Titulo> =>
	request.get(`${basePath}/${id}`, config)

export const deleteById = (id: string): Promise<void> =>
	request.delete(`${basePath}/${id}`)

export const updateTitulos = (titulosId: string[], situacaoDoTitulo: SituacaoDoTitulo): Promise<Titulo[]> =>	
	request.put(`${basePath}/situacao/${situacaoDoTitulo}`, titulosId)

export const findTitulosPorFornecedor = async(idFornecedor: string): Promise<TitulosPorFornecedor[]> =>
	request.get(`${basePath}/buscaDeFornecedores/${idFornecedor}`) 

export const calcularJurosDoTitulo = (tituloId: string): Promise<void> => 
	request.put(`${basePath}/calcular/${tituloId}`)

export const findCentroDeCusto = async (params?: any, config?: AxiosRequestConfig): Promise<Page<CentroDeCusto>> =>
	request.get(`/centroDeCusto`, {
		params,
		...config,
	})

export const createCentroDeCusto = async (centroDeCusto: CentroDeCusto): Promise<CentroDeCusto> =>
	request.post(`/centroDeCusto`, centroDeCusto)

export const updateCentroDeCusto = async (centroDeCusto: CentroDeCusto): Promise<CentroDeCusto> =>
	request.put(`/centroDeCusto/${centroDeCusto.id}`, centroDeCusto)

export const findCentroDeCustoByLojaId = async (params?: any, config?: AxiosRequestConfig): Promise<CentroDeCusto[]> =>
	request.get('/centroDeCusto/loja', {
		params,
		...config,
	})

export const verificaSeTituloEstaPago = async (tituloId: string ): Promise<boolean> =>
	request.get(`${basePath}/verificaSeTituloEstaPago/${tituloId}`)
