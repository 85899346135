import {
	get,
	update,
	create,
	criaItem,
	find,
	updateItem,
	removeItem,
	importaItem,
	buscaItensPorTabelaDePreco,
	removeItens,
	removeAllItens,
	exportAllItens,
	remove,
	listarItensGraneisDaTabelaDePreco,
	updateTabelaPreco,
	desvincularLojaDaTabela,
	buscarQuantidadeCompradaDoItemHaDoisDias,
	buscaItensParaManutencaoDaTabelaDePreco,
	realizarManutencaoDaTabelaDePrecos,
} from '@/api/tabeladepreco'
import { FiltroDeBuscaDeProdutos, FormCapaTabelaDePreco, ItemDaTabelaDePreco, LojaTabelaPreco } from '@/models'
import { TabelaDePrecoServiceAdapter } from '@/usecases'
import { AxiosRequestConfig } from 'axios'
import { injectable } from 'inversify-props'
import 'reflect-metadata'

@injectable()
export class TabelaDePrecoService implements TabelaDePrecoServiceAdapter {
	adicionaItem = async (idTabela: string, item: ItemDaTabelaDePreco) =>
		await criaItem(idTabela, item)

	updateTabelaPreco =  async (lojaTabelaId: LojaTabelaPreco[], tabelaId: string ) => 
		await updateTabelaPreco(lojaTabelaId, tabelaId)

	alteraItem = async (idTabela: string, item: ItemDaTabelaDePreco) => await updateItem(idTabela, item)

	find = async (
		params?: any,
		config?: AxiosRequestConfig,
	) => await find(params, config)

	get = async (id: string) => await get(id)

	create = async (data: FormCapaTabelaDePreco) => await create(data)

	update = async (data: FormCapaTabelaDePreco) => await update(data)

	removeItem = async (idTabela: string, idItem: string) => await removeItem(idTabela, idItem)

	desvincularLojaDaTabela = async (idTabela: string, lojaId: string) => await desvincularLojaDaTabela(idTabela, lojaId)

	importaItens = async (idTabela: string, arquivoContemCabecalho: boolean, urlDoArquivo: string) => await importaItem(idTabela, arquivoContemCabecalho, urlDoArquivo)

	buscaItensPorTabelaDePreco = async (id: string, params?: any, config?: AxiosRequestConfig) => await buscaItensPorTabelaDePreco(id, params, config)

	buscarQuantidadeCompradaDoItemHaDoisDias =  async (itemTabelaPrecoId: string, cpfOuCnpjDoCliente: string) => await buscarQuantidadeCompradaDoItemHaDoisDias(itemTabelaPrecoId, cpfOuCnpjDoCliente)

	removeItens = async (idTabela: string, itens: string[]) => await removeItens(idTabela, itens)

	removeAllItens = async (idTabela: string) => await removeAllItens(idTabela)

	exportAllItens = async (idTabela: string) => await exportAllItens(idTabela)

	removeTabela = async (idTabela: string) => await remove(idTabela)

	listarItensGraneisDaTabelaDePreco = async (
		idTabela: string,
		params?: any,
		config?: AxiosRequestConfig,
	) => await listarItensGraneisDaTabelaDePreco(idTabela, params, config)

	buscaItensParaManutencaoDaTabelaDePreco = async (
		id: string, 
		params?: FiltroDeBuscaDeProdutos, 
		config?: AxiosRequestConfig,
	) => await buscaItensParaManutencaoDaTabelaDePreco(id, params, config)

	realizarManutencaoDaTabelaDePrecos = (
		id: string, 
		params?: FiltroDeBuscaDeProdutos, 
		config?: AxiosRequestConfig,
	) => realizarManutencaoDaTabelaDePrecos(id, params, config)
}
