import { FiltroDeBuscaDeProdutos, ItemDaTabelaDePreco, Page, TabelaDePreco } from '@/models'
import { AxiosRequestConfig } from 'axios'
import {Inject} from "inversify-props"
import type { TabelaDePrecoServiceAdapter } from '@/usecases'

export class FindTabelaDePrecoUseCase {
	@Inject('TabelaDePrecoServiceAdapter')
	private tabelaService!: TabelaDePrecoServiceAdapter

	find = async (params?: any, config?: AxiosRequestConfig): Promise<Page<TabelaDePreco>> =>
		await this.tabelaService.find(params, config)

	async get(id: string): Promise<TabelaDePreco> {
		return this.tabelaService.get(id)
	}

	buscaItensPorTabelaDePreco = async (id: string, params?: any, config?: AxiosRequestConfig): Promise<Page<ItemDaTabelaDePreco>> =>
		await this.tabelaService.buscaItensPorTabelaDePreco(id, params, config)

	buscaItensParaManutencaoDaTabelaDePreco = async (
		id: string,
		params?: FiltroDeBuscaDeProdutos,
		config?: AxiosRequestConfig,
	): Promise<Page<ItemDaTabelaDePreco>> => 
		await this.tabelaService.buscaItensParaManutencaoDaTabelaDePreco(id, params, config)

	realizarManutencaoDaTabelaDePrecos = async (
		id: string,
		params?: FiltroDeBuscaDeProdutos,
		config?: AxiosRequestConfig,
	): Promise<void> => 
		await this.tabelaService.realizarManutencaoDaTabelaDePrecos(id, params, config)
}
