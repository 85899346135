export enum EstadoProcessoVenda {
	// CONTROLE DE ESTADOS DO PROCESSO NNE
	SEM_PROCESSO = -1,
	INICIANDO_DEVOLUCAO_NNE = 0,
	CANCELANDO_NNES = 1,
	CANCELAMENTO_NNES_CONCLUIDO = 2,
	INICIANDO_DEVOLUCAO_DEMONSTRACAO_NNE = 3,
	DEVOLUCAO_DEMONSTRACAO_NNE_CONCLUIDA = 4,
	GERANDO_NNE = 5,
	NNE_GERADA = 6,
	DEVOLUCAO_NNE_CONCLUIDA = 7,

	// CONTROLE DE ESTADOS DO PROCESSO NFE
	INICIANDO_DEVOLUCAO_NFE = 8,
	INICIANDO_DEVOLUCAO_DEMONSTRACAO_NFE = 9,
	DEVOLUCAO_DEMONSTRACAO_NFE_CONCLUIDA = 10,
	GERANDO_NFE = 11,
	NFE_GERADA = 12,
	IMPRIMINDO_NF_DEVOLUCAO = 13,
	IMPRESSAO_NF_DEVOLUCAO_CONCLUIDA = 14,
	DEVOLUCAO_NFE_CONCLUIDA = 15,

	// CONTROLE DE ESTADOS DO PROCESSO DE VENDA E DEMONSTRAÇÃO
	PROCESSANDO_VENDAS = 16,
	VENDAS_CONCLUIDAS = 17,



	// //CONTROLE DE ESTADOS DE PROCESSO DE GERAR NFE DE DEMONSTRAÇÃO
	PRECESSANDO_IMPRESSAO_DEMONSTRACAO = 18,
	FINALIZOU_IMPRESSAO_DEMONSTRACAO = 19,

	// //CONTROLE DE ESTADOS DE PROCESSO DE GERAR NFE DE VENDA
	PRECESSANDO_IMPRESSAO_VENDA = 20,
	FINALIZOU_IMPRESSAO_VENDA = 21,

	PROCESSANDO_CANCELAMENTO_VENDAS = 22,
	PROCESSO_CANCELAMENTO_VENDAS_CONCLUIDO = 23,

	PROCESSANDO_DEVOLUCAO_VENDA_CON_DEM = 24,
	PROCESSO_DEVOLUCAO_VENDA_CON_DEM_CONCLUIDO = 25,

	PROCESSANDO_EMISSAO_DANFE_DEVOLUCAO = 26,
	PROCESSO_EMISSAO_DANFE_DEVOLUCAO_CONCLUIDO = 27,

	PROCESSANDO_DEVOLUCAO_DA_TROCA = 28,
	PROCESSO_DEVOLUCAO_DA_TROCA_CONCLUIDO = 29,

	PROCESSANDO_VENDA_FINAL = 30,
	PROCESSO_VENDA_FINAL_CONLCUIDO = 31,
}

export function getCodigoProcesso(estado: string): number | undefined {
	return EstadoProcessoVenda[estado as keyof typeof EstadoProcessoVenda];
}