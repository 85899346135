import request from '@/common/request'
import {
	Venda,
	Page,
	ParametrosDeBuscaDeVenda,
	VendaComReferencias,
	ResumoDaVenda,
	VendaParcial,
	TotalizadoresDeVenda,
	UsuarioBase,
	VendaComPedido,
	ItemDaVendaComReferencias,
	ItemDaVenda,
	DadosParaReaberturaMultiplaDeVendas,
	FiltroDeVendasSemNota,
	DescontoProgressivo,
	VendaDemonstracaoResumida,
} from '@/models'
import { FiltroDeRelatorioDeVendas } from '@/models/relatorios/RelatorioDeVendas'
import { VendedorEdit } from '@/usecases'

const basePath = '/vendas'

export const create = (data: Venda, isSincrono: boolean, vaiGerarNotaEletronica: boolean): Promise<Venda> =>
	request.post(`${basePath}?isSincrono=${isSincrono}&vaiGerarNotaEletronica=${vaiGerarNotaEletronica}`, data)

export const createVendas = (data: Venda[]): Promise<Venda[]> =>
	request.post(`${basePath}/criar-vendas`, data)

export const find = (params?: ParametrosDeBuscaDeVenda): Promise<Page<Venda>> =>
	request.get(`${basePath}`, {
		params,
	})

export const list = (params?: ParametrosDeBuscaDeVenda): Promise<Page<ResumoDaVenda>> =>
	request.get(`${basePath}/v2`, {
		params,
	})

export const findNumeroDePedidos = (params?: ParametrosDeBuscaDeVenda): Promise<string> =>
	request.get(`${basePath}/pedidos/contagem-pendentes`, {
		params,
	})

export const listSemTurno = (params?: ParametrosDeBuscaDeVenda): Promise<Page<ResumoDaVenda>> =>
	request.get(`${basePath}/sem-turno`, {
		params,
	})

export const remove = (id: string): Promise<void> =>
	request.delete(`${basePath}/${id}`)

export const get = (id: string): Promise<Venda> =>
	request.get(`${basePath}/${id}`)

export const getVendaComPedido = (id: string): Promise<VendaComPedido> =>
	request.get(`${basePath}/${id}`)

getVendaComPedido

export const getVendaComReferencias = (id: string): Promise<VendaComReferencias> =>
	request.get(`${basePath}/${id}/detalhar`)

export const update = (id: string, venda: VendaParcial): Promise<Venda> =>
	request.patch(`${basePath}/${id}`, venda)

export const cancelarVenda = (id: string, params?: any): Promise<Venda> =>
	request.get(`${basePath}/${id}/cancelar`, {
		params,
	})

export const cancelarItem = (vendaId: string, params?: any): Promise<ItemDaVendaComReferencias> =>
	request.get(`${basePath}/${vendaId}/cancelar-item`, {
		params,
	})

export const patchCpfNaNota = async (venda: Partial<Venda>): Promise<void> =>
	request.patch(`${basePath}/${venda.id}/cpf-na-nota`, venda)

export const cancelarNotasFiscais = async (id: string, motivoDoCancelamentoFiscal: string): Promise<Venda> =>
	request.put(`${basePath}/${id}/cancelar-notas/${motivoDoCancelamentoFiscal}`)

export const listRelatorio = (params?: FiltroDeRelatorioDeVendas): Promise<Page<ResumoDaVenda>> =>
	request.get(`${basePath}/relatorio`, {
		params,
	})

export const getTotalDeVendas = (params?: FiltroDeRelatorioDeVendas): Promise<TotalizadoresDeVenda> =>
	request.get(`${basePath}/relatorio/totalizadores`, {
		params,
	})

export const patchVendedor = (idVenda: string, vendedor: UsuarioBase): Promise<Venda> =>
	request.patch(`${basePath}/${idVenda}/vendedor`, vendedor)

export const removerVendedor = (idVenda: string, vendedor: UsuarioBase): Promise<void> =>
	request.post(`${basePath}/${idVenda}/vendedor/remover`, vendedor)

export const patchRepresentante = (idVenda: string, representante: UsuarioBase): Promise<Venda> =>
	request.patch(`${basePath}/${idVenda}/representante`, representante)

export const patchVendaOrigem = (idVenda: string, vendaOrigemId: string): Promise<Venda> =>
	request.patch(`${basePath}/${idVenda}/vendaOrigem?vendaOrigemId=${vendaOrigemId}`)

export const removerVendaOrigem = (idVenda: string): Promise<Venda> =>
	request.patch(`${basePath}/${idVenda}/removerVendaOrigem`)

export const gerarIdentificadorDeVenda = (idPontoDeVenda: string): Promise<string> =>
	request.post(`${basePath}/gerar-identificador?idPontoDeVenda=${idPontoDeVenda}`)

export const enviarEmail = (idVenda: string, email: string): Promise<void> =>
	request.post(`${basePath}/${idVenda}/nota-fiscal/enviar-email?emailDestino=${email}`)

export const validarIdentificadorDeVenda = (idPontoDeVenda: string, identificador: string): Promise<string> =>
	request.get(`${basePath}/validar-identificador/${identificador}?idPontoDeVenda=${idPontoDeVenda}`)

export const devolucaoVendaConsignada = (venda: Venda): Promise<void> =>
	request.post(`${basePath}/devolucao-consignado`, venda)

export const devolucaoVendaDemonstracao = (venda: Venda): Promise<void> =>
	request.post(`${basePath}/devolucao-demonstracao`, venda)

export const gerarIdentificadorDeVendaSemTurno = (lojaId: string): Promise<string> =>
	request.post(`${basePath}/gerar-identificador/sem-turno?lojaId=${lojaId}`)

export const listNota = (params?: ParametrosDeBuscaDeVenda): Promise<Page<ResumoDaVenda>> =>
	request.get(`${basePath}/nota`, {
		params,
	})

export const atualizarModalidade = (modalidadeId: string, vendaId: string): Promise<void> =>
	request.patch(`${basePath}/atualizar-modalidade-na-venda/${vendaId}/${modalidadeId}`)

export const verificarSeVendaPossuiNota = (vendaId: string): Promise<boolean> =>
	request.get(`${basePath}/verificar-se-venda-possui-nota/${vendaId}`)

export const atualizarVendedores = (vendedorEdit: VendedorEdit): Promise<void> =>
	request.patch(`${basePath}/${vendedorEdit.id}/vendedores`, vendedorEdit)

export const podeVender = (cnpjOuCpf: string, turnoDeVendaId: string): Promise<string[]> =>
	request.get(`${basePath}/pode-vender?cnpjOuCpf=${cnpjOuCpf}&turnoDeVendaId=${turnoDeVendaId}`)

export const atualizarNumeroDoTicket = (numeroTicket: string | null, vendaId: string): Promise<void> =>
	request.patch(`${basePath}/atualizar-ticket/${vendaId}/${numeroTicket}`)

export const removerNumeroTicket = (vendaId: string): Promise<void> =>
	request.delete(`${basePath}/atualizar-ticket/${vendaId}`)

export const buscarOrigem = (produtoId: string, quantidadeLida: number, lojaId: string, cpfOuCnpjCliente: string, itens: ItemDaVenda[]): Promise<ItemDaVenda> =>
	request.post(`${basePath}/buscar-origem?produtoId=${produtoId}&quantidadeLida=${quantidadeLida}&lojaId=${lojaId}&cpfOuCnpjCliente=${cpfOuCnpjCliente}`, itens)

export const buscarVendasParaRetornoMultiplo = (
	cpfOuCnpjCliente: string,
	lojaId: string,
	retornoMultiploDeDemonstracao: boolean,
): Promise<DadosParaReaberturaMultiplaDeVendas[]> =>
	request.get(
		`${basePath}/multiplas?cpfOuCnpjCliente=${cpfOuCnpjCliente}&lojaId=${lojaId}&
		retornoMultiploDeDemonstracao=${retornoMultiploDeDemonstracao}`,
	)

export const findVendaByNota = (chaveNF: string): Promise<string> =>
	request.get(`${basePath}/venda-nota/${chaveNF}`)

export const filtrarVendasSemNota = (params?: FiltroDeVendasSemNota): Promise<string[]> =>
	request.get(`${basePath}/filtrar-vendas-sem-nota`, {
		params,
	})

export const cancelarVendas = (vendasId: string[]): Promise<void> =>
	request.post(`${basePath}/cancelar-vendas`, vendasId)

export const findDescontosProgressivosDaLoja = (params: any): Promise<DescontoProgressivo[]> =>
	request.get(`${basePath}/desconto-progressivo`, {
		params,
	})

export const findEstacoesConfiguradasPorLoja = (lojaId: string): Promise<string[]> =>
	request.get(`${basePath}/desconto-progressivo/estacoes?lojaId=${lojaId}`)

export const findHierarquiaDemonstracoes = (params?: ParametrosDeBuscaDeVenda): Promise<Page<VendaDemonstracaoResumida>> =>
	request.get(`${basePath}/lista-demonstracoes-hierarquia`, {
		params,
	})

export const possuiDevolucaoDaVendaOrigem = (vendaOrigemIds: string[]): Promise<boolean> =>
	request.get(`${basePath}/possui-devolucao`, {params: { vendaOrigemIds }})

export const disparoDeMensagemVenda = (vendaId: any, novoTelefone?: any): Promise<void> =>
	request.post(novoTelefone ? `${basePath}/disparo-mensagem-venda/${vendaId}?novoTelefone=${novoTelefone}` : `${basePath}/disparo-mensagem-venda/${vendaId}`)

