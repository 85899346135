import { Inject } from 'inversify-props'
import { GradeDeProdutos, Page } from '@/models'
import { AxiosRequestConfig } from 'axios'
import type { GradeDeProdutosServiceAdapter } from './adapter/GradeDeProdutosServiceAdapter'

export class FindGradeDeProdutosUseCase {
	@Inject('GradeDeProdutosServiceAdapter')
	private gradeDeProdutosService!: GradeDeProdutosServiceAdapter

	findAll = async (params?: any, config?: AxiosRequestConfig): Promise<Page<GradeDeProdutos>> =>
		this.gradeDeProdutosService.find(params, config)
}
