import { formatCNPJ, formatCPF } from '@brazilian-utils/brazilian-utils'
import Decimal from 'decimal.js'

export function formatarMoeda(valor: number) {
	return valor.toLocaleString('pt-BR', {
		currency: 'BRL',
		maximumFractionDigits: 2,
		minimumFractionDigits: 2,
	})
}

export function formatarMoedaStr(valor: string) {
	if (!valor) return
	return new Decimal(valor).toNumber().toLocaleString('pt-BR', {
		currency: 'BRL',
		maximumFractionDigits: 2,
		minimumFractionDigits: 2,
	})
}

export function formatarDecimal(valor: number, options?: Intl.NumberFormatOptions) {
	return valor.toLocaleString('pt-BR', options)
}

export function displayPercentual(valor: number, options?: Intl.NumberFormatOptions) {
	return valor.toLocaleString('pt-BR', options) + '%'
}

export function formatarNcm(valor: string) {
	const normalizado = valor.padStart(8, '0')
	return normalizado.replace(/(\d{2})(\d{2})(\d{2})(\d{1})(\d{1})/, "$1.$2.$3.$4.$5");
}

export function formatarCnpjOuCpf(valor: string): string {
	return removerFormatacaoDeCnpjOuCpf(valor).length <= 11
		? formatCPF(valor)
		: formatCNPJ(valor)
}

export function deformatarValorDecimal(valor: string): number {
	let valorNormalizado = valor
	valorNormalizado = valorNormalizado.replace(/\./g, '')
	valorNormalizado = valorNormalizado.replace(',', '.')
	valorNormalizado = valorNormalizado.replace(/[^-.,\d]/g, '')
	return Number(valorNormalizado)
}

export function formatarDataHora(data: Date): string {
	return `${new Date(data).toLocaleDateString()} às ${new Date(data).toLocaleTimeString()}`
}

export function formatarData(data: Date): string {
	return `${new Date(data).toLocaleDateString()}`
}

export function aplicarMascaraParaCpfOuCnpjOculto(valor: string) {
	return removerFormatacaoDeCnpjOuCpf(valor).length <= 11
		? aplicarMascaraParaCpfOculto(valor)
		: aplicarMascaraParaCnpjOculto(valor)
}

export function aplicarMascaraParaCpfOculto(valor: string) {
	return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.***.***-$4')
}

export function aplicarMascaraParaCnpjOculto(valor: string) {
	return valor.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.***.***/****-$5')
}

export function aplicarMascaraParaEmail(valor: string) {
	return valor.replace(/(\w{2})\w*@/g, '$1******@')
}

export function aplicarMascaraParaTelefone(valor: string) {
	return valor.replace(/(\w{2})\d*-/g, '*****')
}

export function fixarCasasDecimais(valor: number, casasDecimais: number) {
	return Number(valor.toFixed(casasDecimais))
}

export function removerFormatacaoDeCnpjOuCpf(cnpjOuCpfFormatado: string | null) {
	if (cnpjOuCpfFormatado === null) return ''
	return cnpjOuCpfFormatado.replace(/\D/g, '')
}

export function removerCaracteresEspeciaisDeTexto(valor: string): string {
	return valor.replace(/([^0-9a-zA-Z\s])/g, '')
}
