import { Inject } from 'inversify-props'
import { GradeDeProdutos } from '@/models'
import type { GradeDeProdutosServiceAdapter } from './adapter/GradeDeProdutosServiceAdapter'

export class CreateGradeDeProdutosUseCase {
	@Inject('GradeDeProdutosServiceAdapter')
	private gradeDeProdutosAdapter!: GradeDeProdutosServiceAdapter

	create = async (gradeDeProdutos: GradeDeProdutos): Promise<GradeDeProdutos> =>
		this.gradeDeProdutosAdapter.create(gradeDeProdutos)
}
