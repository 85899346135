import { Page } from '@/models/Page';
import { inject  } from 'inversify-props'
import type { FornecedorServiceAdapter }  from './adapter'
import { AxiosRequestConfig } from 'axios'
import { Fornecedor } from '@/models';

export class FindFornecedorUseCase {
	@inject('FornecedorServiceAdapter')
	private fornecedorServiceAdapter!: FornecedorServiceAdapter

	find = async (params?: any, config?: AxiosRequestConfig): Promise<Page<Fornecedor>> =>
		await this.fornecedorServiceAdapter.find(params, config)

	get = async (id: string): Promise<Fornecedor> =>
		await this.fornecedorServiceAdapter.get(id)

	existeVinculoFornecedorComGrupo = async (cpfOuCnpjDoFornecedor: string, grupoEconomicoId: string): Promise<boolean> =>
		await this.fornecedorServiceAdapter.existeVinculoFornecedorComGrupo(cpfOuCnpjDoFornecedor, grupoEconomicoId)
}