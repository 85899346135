import { injectable } from 'inversify-props'
import { EntradaDeTitulo, SituacaoDoTitulo, TituloComParcelamento } from '@/models/financeiro/Titulo'
import {
	create,
	findTitulos,
	getTitulo,
	update,
	deleteById,
	updateTitulos,
	findTotalizador,
	findParaBaixa,
	exportCsv,
	findTitulosPorFornecedor,
	calcularJurosDoTitulo,
	verificaSeTituloEstaPago,
} from '@/api/titulos'
import { TituloServiceAdapter } from '@/usecases/titulos'
import { AxiosRequestConfig } from 'axios'

@injectable()
export class TituloService implements TituloServiceAdapter {
	create = async (params: TituloComParcelamento) => await create(params)
	update = async (titulo: EntradaDeTitulo) => await update(titulo)
	find = async (params?: any, config?: AxiosRequestConfig) => await findTitulos(params, config)
	exportCsv = async (params?: any, config?: AxiosRequestConfig) => await exportCsv(params, config)
	findTotalizador = async (params?: any, config?: AxiosRequestConfig) => await findTotalizador(params, config)
	findParaBaixa = async (params?: any, config?: AxiosRequestConfig) => await findParaBaixa (params, config)
	get = async (id: string | null) => await getTitulo(id)
	delete = async (id: string) => await deleteById(id)
	updateTitulos = async (titulosId: string[], situacaoDoTitulo: SituacaoDoTitulo) => await updateTitulos(titulosId, situacaoDoTitulo)
	findTitulosPorFornecedor = async (idFornecedor: string) => await findTitulosPorFornecedor(idFornecedor)
	calcularJurosDoTitulo = async (tituloId: string) => await calcularJurosDoTitulo(tituloId)
	verificaSeTituloEstaPago = async (tituloId: string) => await verificaSeTituloEstaPago(tituloId)
}
