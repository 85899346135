import { container } from 'inversify-props'
import { VendaServiceAdapter } from './adapter'
import { DetalhesDoPagamento, FiltroDeVendasSemNota, ItemDaVenda, Pagamento, TurnoDeVenda, Venda } from '@/models'
import {
	criarDevolucao,
	obterTrocoDaVenda,
	obterDescontoEmPercentual,
	unificarItensDaVendaPorProduto,
	imprimirNotaFiscal,
	obterDescontoEmPercentualDaTroca,
	emitirEComunicarDanfe,
	criarDevolucaoParaDemonstracaoOuConsignado,
	gerarNotaNaoEletronica,
} from '@/shareds/venda-shareds'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import AlertModule, { ErroComPrefixo } from '@/store/vuex/aplicacao/AlertModule'
import PluginModule from '@/store/vuex/aplicacao/PluginStore'
import { imprimirCupomSemValorFiscal } from '@/shareds/pedidos/impressao-cupom-sem-valor-fiscal'
import { FindEstoqueUseCase } from '../deposito'
import { PreVendaUseCase } from './PreVendaUseCase'
import { PreVenda } from '@/models/pre-venda/PreVenda'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import { FindModalidadeDeVendaUseCase } from './FindModalidadeDeVendaUseCase'
import { FindVendaUseCase } from './FindVendaUseCase'
import { getCodigoProcesso } from '@/store/vuex/venda/EstadoProcessoVenda'

const findModalidadeDeVendaUseCase = new FindModalidadeDeVendaUseCase()
const findVendaUseCase = new FindVendaUseCase()

type UseCaseParams = {
	venda: Venda
	detalhesDosPagamentos: DetalhesDoPagamento[]
	turnoDeVenda: TurnoDeVenda
	service?: VendaServiceAdapter
	gerarCupom: boolean
	confirmacaoDeValeTroca?: { mostrar: () => Promise<boolean> }
	imprimirCupomSemValorFiscal?: (venda: Venda, pagamentos: DetalhesDoPagamento[]) => Promise<any>
	imprimirNotaFiscal?: (idVenda: string, imprimirNfe?: boolean, isContingencia?: boolean) => Promise<any>
	emitirEComunicarDanfe?: (idVenda: string) => Promise<any>
	gerarNotaNaoEletronica?: (idVenda: string) => Promise<any>
	vendaModule?: typeof VendaModule
	findEstoqueUseCase?: FindEstoqueUseCase
	itensDevolucao?: ItemDaVenda[]
	emitirDanfe: boolean
	preVendaUseCase?: PreVendaUseCase
}

export async function FinalizarVendaUseCase({
	service = container.get<VendaServiceAdapter>('VendaServiceAdapter'),
	vendaModule = VendaModule,
	venda,
	detalhesDosPagamentos,
	gerarCupom,
	turnoDeVenda,
	findEstoqueUseCase = new FindEstoqueUseCase(),
	itensDevolucao,
	emitirDanfe,
	preVendaUseCase = new PreVendaUseCase(),
}: UseCaseParams): Promise<Venda> {
	if (!venda) throw new Error('Venda não informada')
	if (!turnoDeVenda) throw new Error('Turno de venda não informado')
	if (!venda.itens || venda.itens.length === 0) throw new Error('Venda não possui itens')

	venda.modalidadeDeVenda = venda.modalidadeDeVenda && typeof Object ? venda.modalidadeDeVenda : null
	let identificador = ''
	let vendaAEnviar: Venda | null
	const ehUmaTroca = venda.itens.filter(item => item.quantidade > 0).length > 0 && (venda.tipoDeTransacao === 'Devolução' || venda.tipoDeTransacao === 'Outros')
	const ehUmaVendaFical = !venda.possuiNotaNaoEletronica
	const ehUmaDemonstracaoReaberta = (venda.isDemonstracao || venda.isConsignado) && venda.isVendaReaberta
	const ehUmaVendaDeValePresente = venda?.itens.some(item => item.produto.isValePresente) ?? false

	if (venda.cliente && turnoDeVenda.id && turnoDeVenda.pontoDeVenda.loja.configuracaoDaLoja?.bloquearNovaVenda) {
		const podeVender = await service.podeVender(venda.cliente.cnpjOuCpf, turnoDeVenda.id)

		if (podeVender.length > 0) {
			throw Error(`Existe uma venda anterior com nota inválida (${podeVender[0]}), faça a correção dela para seguir com a venda atual.`)
		}
	}

	await validarEstoque(venda, turnoDeVenda, ehUmaVendaFical, ehUmaTroca, findEstoqueUseCase)

	try {
		identificador = await service.gerarIdentificadorDeVenda(turnoDeVenda.pontoDeVenda.id)
	} catch (error: any) {
		throw Error('Falha ao finalizar a venda, tente novamente ou contate o suporte.')
	}


	const gerarEstoqueNaVenda = turnoDeVenda.pontoDeVenda.loja.configuracaoDaLoja?.podeGerarEstoqueNaVenda || false
	if ((!gerarEstoqueNaVenda || turnoDeVenda.pontoDeVenda.criaPedidoNaVenda) && venda.tipoDeTransacao === 'Venda' && !ehUmaDemonstracaoReaberta) {
		const itensUnificados = unificarItensDaVendaPorProduto(venda.itens)
		const podeLiberarEstoque = UserLoginStore.permiteRegraDeNegocio('pode-liberar-produto-sem-estoque') || UserLoginStore.autenticandoParaLiberarVendaSemEstoque
		let projecoesPorItemDaVenda
		if (!podeLiberarEstoque && !VendaModule.vendaAtual?.itens.some(item => item.produto.isValePresente)) {

			projecoesPorItemDaVenda = await Promise.all(
				itensUnificados.filter(item => item.quantidade != 0).map(async (itemDaVenda: any) => {
					let criticas = ''

					if (!itemDaVenda.autenticadoParaLiberarProdutoSemEstoque) {
						const quantidadeADevolver = (
							itensDevolucao?.filter(i => i.id = itemDaVenda.id)
								.reduce((total, { quantidade }) => total + quantidade, 0) || 0) - itemDaVenda.quantidade

						await findEstoqueUseCase.getEstoqueDoItemDaVendaNaLoja(
							itemDaVenda.id, quantidadeADevolver.toString(), turnoDeVenda?.pontoDeVenda.loja.id || '', itemDaVenda.autenticadoParaLiberarProdutoSemEstoque,
						).catch((error) => {
							criticas = error instanceof Object ? error.response.data[0] : error
						})
					}

					return criticas
				}),
			)
			projecoesPorItemDaVenda = projecoesPorItemDaVenda.filter(value => value !== '')
		}
		const criticasNormalizadas = projecoesPorItemDaVenda
			? projecoesPorItemDaVenda.join('<br>')
			: undefined
		if (criticasNormalizadas) {
			throw criticasNormalizadas
		}
	}

	if (ehUmaDemonstracaoReaberta) {
		return processarVendaDemonstracaoOuConsignado(venda, turnoDeVenda, gerarCupom, emitirDanfe, detalhesDosPagamentos,
			service, identificador, itensDevolucao, ehUmaVendaFical)
	} else {
		const codigoDoProcesso = VendaModule.etapaVenda ? getCodigoProcesso(VendaModule.etapaVenda.estado) || 0 : 0

		if (ehUmaTroca && venda.itens.filter(item => item.quantidade > 0).length > 0) {
			vendaAEnviar = {
				...venda,
				troco: obterTrocoDaVenda(venda),
				tipoDeCliente: venda.tipoDeCliente || null,
				pontoDeVenda: turnoDeVenda.pontoDeVenda,
				dataHora: venda.dataHora || new Date(),
				identificador: venda.identificador,
				itens: venda.itens.filter(item => item.quantidade > 0),
				isConsignado: venda.isVendaReaberta ? false : venda.isConsignado,
				isDemonstracao: venda.isVendaReaberta ? false : venda.isDemonstracao,
				cpfDoCliente: venda.cliente ? venda.cliente.cnpjOuCpf : '',
				isOrcamento: !gerarCupom,
				vendaOrigem: venda.vendaOrigem,
				pagamentos: venda.pagamentos.filter(pagamento => pagamento.valor > 0) || [],
				tipoDeTransacao: 'Venda',
				tipoNota: 'Saída',
				isTroca: true,
				desconto: obterDescontoEmPercentualDaTroca(venda),
				pedido: turnoDeVenda.pontoDeVenda.criaPedidoNaVenda ? venda.pedido : null,
				autenticandoParaLiberarVendaSemEstoque: venda.autenticandoParaLiberarVendaSemEstoque,
			}

		} else {

			vendaAEnviar = {
				...venda,
				troco: obterTrocoDaVenda(venda),
				tipoDeCliente: venda.tipoDeCliente || null,
				pontoDeVenda: turnoDeVenda.pontoDeVenda,
				dataHora: venda.dataHora || new Date(),
				identificador: venda.identificador,
				itens: venda.itens.filter(item => item.quantidade > 0 || item.quantidade < 0),
				isConsignado: venda.isVendaReaberta ? false : venda.isConsignado,
				isDemonstracao: venda.isVendaReaberta ? false : venda.isDemonstracao,
				cpfDoCliente: venda.cliente ? venda.cliente.cnpjOuCpf : '',
				isOrcamento: !gerarCupom,
				vendaOrigem: venda.vendaOrigem,
				isTroca: false,
				desconto: obterDescontoEmPercentual(venda),
				pedido: null,
				demonstracaoConvertida: venda.isDemonstracao && venda.isVendaReaberta,
				consignacaoConvertida: venda.isConsignado && venda.isVendaReaberta,
				autenticandoParaLiberarVendaSemEstoque: UserLoginStore.autenticandoParaLiberarVendaSemEstoque,
			}
		}

		const apenasDevolucaoDeDemonstracao = vendaAEnviar.itens.filter(item => item.quantidade !== 0).length === 0
			&& venda.isVendaReaberta
			&& (venda.isConsignado || venda.isDemonstracao)

		if (apenasDevolucaoDeDemonstracao && itensDevolucao) {

			let identificadorDevolucao = ''
			try {
				identificadorDevolucao = await service.gerarIdentificadorDeVenda(turnoDeVenda.pontoDeVenda.id)
			} catch (error: any) {
				throw Error('Falha ao finalizar a venda, tente novamente ou contate o suporte.')
			}

			const vendaOriginal: Venda = {
				...vendaAEnviar,
				id: '',
				troco: obterTrocoDaVenda(venda),
				tipoDeCliente: venda.tipoDeCliente || null,
				tipoDeTransacao: 'Devolução',
				pontoDeVenda: turnoDeVenda.pontoDeVenda,
				dataHora: new Date(),
				identificador: venda.identificador,
				vendaOrigem: venda.id,
				itens: itensDevolucao,
			}

			let vendaDevolucao: Venda = {
				...criarDevolucaoParaDemonstracaoOuConsignado({
					itens: itensDevolucao,
					vendaOrigem: venda,
					vendaOriginal: vendaOriginal,
					...turnoDeVenda.pontoDeVenda.parametrosFiscais,
					tipoDeTransacao: 'Devolução',
				}),
				pontoDeVenda: turnoDeVenda.pontoDeVenda,
				dataHora: new Date(),
				identificador: identificadorDevolucao,
				isConsignado: venda.isConsignado,
				isDemonstracao: venda.isDemonstracao,
				cliente: venda.cliente,
				vendaOrigem: venda.vendaOrigem,
			}

			if (codigoDoProcesso < 25) {
				if (vendaDevolucao.isConsignado) {
					VendaModule.setEtapaVenda({
						...VendaModule.etapaVenda,
						estado: 'PROCESSANDO_DEVOLUCAO_VENDA_CON_DEM',
					})

					await service.devolucaoVendaConsignada(vendaDevolucao)

					VendaModule.setEtapaVenda({
						vendas: [vendaDevolucao],
						estado: 'PROCESSO_DEVOLUCAO_VENDA_CON_DEM_CONCLUIDO',
					})
				} else {
					VendaModule.setEtapaVenda({
						...VendaModule.etapaVenda,
						estado: 'PROCESSANDO_DEVOLUCAO_VENDA_CON_DEM',
					})
					await service.devolucaoVendaDemonstracao(vendaDevolucao)
					VendaModule.setEtapaVenda({
						vendas: [vendaDevolucao],
						estado: 'PROCESSO_DEVOLUCAO_VENDA_CON_DEM_CONCLUIDO',
					})
				}
			}

			if (codigoDoProcesso < 27) {
				try {
					vendaDevolucao = VendaModule.etapaVenda.vendas[0]
					VendaModule.setEtapaVenda({
						...VendaModule.etapaVenda,
						estado: 'PROCESSANDO_EMISSAO_DANFE_DEVOLUCAO',
					})
					await emitirDanfePorVendaId(vendaDevolucao.id)
					VendaModule.setEtapaVenda({
						vendas: [],
						estado: 'PROCESSO_EMISSAO_DANFE_DEVOLUCAO_CONCLUIDO',
					})
				} catch (error: any) {
					const erroComPrefixo = {
						error: error,
						prefixo: 'Erro ao emitir danfe do retorno, verifique na tela de vendas: ',
					} as ErroComPrefixo
					AlertModule.setErrorComPrefixo(erroComPrefixo)
				}
			}

			vendaModule.iniciarNovaVenda({ identificador, novaVendaReaberta: false })

			return vendaDevolucao
		}

		if ((venda.isConsignado || venda.isDemonstracao) && venda.isVendaReaberta && itensDevolucao && itensDevolucao.length > 0) {
			let identificadorDevolucao = ''
			try {
				identificadorDevolucao = await service.gerarIdentificadorDeVenda(turnoDeVenda.pontoDeVenda.id)
			} catch (error: any) {
				throw Error('Falha ao finalizar a venda, tente novamente ou contate o suporte.')
			}

			const vendaOriginal: Venda = {
				...vendaAEnviar,
				id: '',
				troco: obterTrocoDaVenda(venda),
				tipoDeCliente: venda.tipoDeCliente || null,
				tipoDeTransacao: 'Devolução',
				pontoDeVenda: turnoDeVenda.pontoDeVenda,
				dataHora: new Date(),
				identificador: venda.identificador,
				vendaOrigem: vendaAEnviar.id,
				itens: itensDevolucao,
			}

			let vendaDevolucao: Venda = {
				...criarDevolucaoParaDemonstracaoOuConsignado({
					itens: itensDevolucao,
					vendaOrigem: vendaAEnviar,
					vendaOriginal: vendaOriginal,
					...turnoDeVenda.pontoDeVenda.parametrosFiscais,
					tipoDeTransacao: 'Devolução',
				}),
				pontoDeVenda: turnoDeVenda.pontoDeVenda,
				dataHora: new Date(),
				identificador: identificadorDevolucao,
				isConsignado: venda.isConsignado,
				isDemonstracao: venda.isDemonstracao,
				cliente: venda.cliente,
				vendaOrigem: vendaAEnviar.vendaOrigem,
				desconto: null,
			}

			if (codigoDoProcesso < 25) {
				if (vendaDevolucao.isConsignado) {
					VendaModule.setEtapaVenda({
						...VendaModule.etapaVenda,
						estado: 'PROCESSANDO_DEVOLUCAO_VENDA_CON_DEM',
					})
					await service.devolucaoVendaConsignada(vendaDevolucao)
					VendaModule.setEtapaVenda({
						vendas: [vendaDevolucao],
						estado: 'PROCESSO_DEVOLUCAO_VENDA_CON_DEM_CONCLUIDO',
					})
				} else {
					VendaModule.setEtapaVenda({
						...VendaModule.etapaVenda,
						estado: 'PROCESSANDO_DEVOLUCAO_VENDA_CON_DEM',
					})
					await service.devolucaoVendaDemonstracao(vendaDevolucao)
					VendaModule.setEtapaVenda({
						vendas: [vendaDevolucao],
						estado: 'PROCESSO_DEVOLUCAO_VENDA_CON_DEM_CONCLUIDO',
					})
				}
			}

			if (codigoDoProcesso < 27) {
				try {
					VendaModule.setEtapaVenda({
						...VendaModule.etapaVenda,
						estado: 'PROCESSANDO_EMISSAO_DANFE_DEVOLUCAO',
					})
					vendaDevolucao = VendaModule.etapaVenda.vendas[0]
					await emitirDanfePorVendaId(vendaDevolucao.id)
					VendaModule.setEtapaVenda({
						vendas: [],
						estado: 'PROCESSO_EMISSAO_DANFE_DEVOLUCAO_CONCLUIDO',
					})
				} catch (error: any) {
					const erroComPrefixo = {
						error: error,
						prefixo: 'Erro ao emitir danfe do retorno, verifique na tela de vendas: ',
					} as ErroComPrefixo
					AlertModule.setErrorComPrefixo(erroComPrefixo)
				}
			}
		}

		if (ehUmaTroca) {

			let identificadorDevolucao = ''
			try {
				identificadorDevolucao = await service.gerarIdentificadorDeVenda(turnoDeVenda.pontoDeVenda.id)
			} catch (error: any) {
				throw Error('Falha ao finalizar a venda, tente novamente ou contate o suporte.')
			}

			const vendaOriginal: Venda = {
				...venda,
				id: '',
				troco: 0,
				tipoDeCliente: venda.tipoDeCliente || null,
				tipoDeTransacao: venda.tipoDeTransacao,
				pontoDeVenda: turnoDeVenda.pontoDeVenda,
				dataHora: new Date(),
				vendaOrigem: venda.vendaOrigem,
			}

			const vendaDevolucao: Venda = {
				...criarDevolucao({
					itens: venda.itens.filter(item => item.quantidade < 0),
					vendaOrigem: venda,
					vendaOriginal: vendaOriginal,
					...turnoDeVenda.pontoDeVenda.parametrosFiscais,
					tipoDeTransacao: 'Devolução',
					totalValorFrete: 0,
				}),
				pontoDeVenda: turnoDeVenda.pontoDeVenda,
				dataHora: new Date(),
				identificador: identificadorDevolucao,
				cliente: venda.cliente,
				pagamentos: venda.pagamentos.filter(pagamento => pagamento.valor < 0) || [],
				isTroca: true,
				vendaOrigem: vendaOriginal.vendaOrigem,
				tipoDeTransacao: venda.tipoDeTransacao,
			}

			const gerarNotaEletronica = gerarCupom || emitirDanfe

			if (codigoDoProcesso < 29) {
				VendaModule.setEtapaVenda({
					...VendaModule.etapaVenda,
					estado: 'PROCESSANDO_DEVOLUCAO_DA_TROCA',
				})
				await service.create(vendaDevolucao, false, gerarNotaEletronica)
				VendaModule.setEtapaVenda({
					vendas: [],
					estado: 'PROCESSO_DEVOLUCAO_DA_TROCA_CONCLUIDO',
				})
			}
		}

		const vendaACriar: Venda = {
			...vendaAEnviar,
			cpfDoCliente: venda.cpfDoCliente,
			existeValePresente: ehUmaVendaDeValePresente,
		}

		if (codigoDoProcesso < 31) {
			VendaModule.setEtapaVenda({
				...VendaModule.etapaVenda,
				estado: 'PROCESSANDO_VENDA_FINAL',
			})
			const vendaAtualizada = await service.create(vendaACriar, emitirDanfe, gerarCupom || emitirDanfe || !!vendaACriar.pontoDeVenda?.criaPedidoNaVenda)
			VendaModule.setEtapaVenda({
				vendas: [vendaAtualizada],
				estado: 'PROCESSO_VENDA_FINAL_CONLCUIDO',
			})

			// Nova venda
			if (!venda.identificador) vendaModule.adicionarVendaDoTurno(vendaAtualizada)
			await imprimirCupomOuDanfe(vendaAtualizada, gerarCupom, emitirDanfe, detalhesDosPagamentos)

			if (VendaModule.vendaAtual?.idPreVenda) {
				try {
					const preVenda: PreVenda = {
						id: VendaModule.vendaAtual?.idPreVenda,
						identificador: '',
						situacao: 'Concluído',
						dataHoraCriacao: null,
						itens: [],
						loja: null,
						cpfOuCnpjDoCliente: VendaModule.vendaAtual.cpfDoCliente,
						vendedor: null,
						tipoDePreVenda: 'venda',
						vendaIdOrigem: '',
					}
					await preVendaUseCase.updateSituacao(preVenda)
					VendaModule.recarregaVenda
				} catch (error) {
					throw Error('Falha ao finalizar a venda, tente novamente ou contate o suporte.')
				}
			}

			localStorage.setItem('ULTIMA_VENDA', JSON.stringify(vendaAtualizada))

			vendaModule.iniciarNovaVenda({ identificador, novaVendaReaberta: false })
			UserLoginStore.setAutenticandoParaLiberarVendaSemEstoque(false)

			return vendaAtualizada
		} else {
			AlertModule.setSuccess('Venda finalizada')
			const vendaAtualizada = VendaModule.etapaVenda.vendas[0]
			vendaModule.iniciarNovaVenda({ identificador, novaVendaReaberta: false })
			UserLoginStore.setAutenticandoParaLiberarVendaSemEstoque(false)
			return codigoDoProcesso == 31 ? vendaAtualizada : vendaACriar
		}
	}
}

async function processarVendaDemonstracaoOuConsignado(venda: Venda, turnoDeVenda: TurnoDeVenda, gerarCupom: boolean,
	emitirDanfe: boolean, detalhesDosPagamentos: DetalhesDoPagamento[], service: VendaServiceAdapter,
	identificador: string, itensDevolucao: ItemDaVenda[] | undefined, ehUmaVendaFiscal: boolean): Promise<Venda> {

	const tipoPagamentoDemonstracao = turnoDeVenda?.tiposDePagamento
		.filter(tipo => tipo.formaDePagamento === 'Demonstração' || tipo.formaDePagamento === 'Consignado')

	if (tipoPagamentoDemonstracao.length === 0) {
		// eslint-disable-next-line no-constant-condition
		if (tipo => tipo.formaDePagamento === 'Demonstração') {
			throw ('Para realizar esse operação cadastre uma forma de pagamento de Demonstração')
		} else {
			throw ('Para realizar esse operação cadastre uma forma de pagamento de Consignado')
		}
	}

	const itensDaDemonstracao = venda.itens.filter(item => item.isCompra === false && item.quantidade > 0)

	const vendasAEnviar: Venda[] = []
	if (itensDaDemonstracao.length > 0) {
		const totalPrecoDemonstracao = itensDaDemonstracao.length > 0
			? itensDaDemonstracao.reduce((total, item) => total + item.preco * item.quantidade, 0)
			: 0

		const pagamentoDemonstracao: Pagamento[] = [{
			id: '',
			valor: totalPrecoDemonstracao,
			tipoDePagamento: tipoPagamentoDemonstracao[0],
			dataHoraPagamento: new Date(),
			qtdeDeParcelas: 0,
			status: 'Pago',
			valorDaTaxa: null,
			detalhesDoPagamentoId: null,
			link: null,
			valePresenteId: null,
		}]

		venda.autenticandoParaLiberarVendaSemEstoque = UserLoginStore.autenticandoParaLiberarVendaSemEstoque

		const vendaDemonstracao: Venda = {
			...venda,
			id: '',
			identificador: '',
			troco: obterTrocoDaVenda(venda),
			tipoDeCliente: venda.tipoDeCliente || null,
			pontoDeVenda: turnoDeVenda.pontoDeVenda,
			dataHora: venda.dataHora || new Date(),
			itens: itensDaDemonstracao,
			isConsignado: venda.isConsignado,
			isDemonstracao: venda.isDemonstracao,
			cpfDoCliente: venda.cliente ? venda.cliente.cnpjOuCpf : '',
			isOrcamento: !gerarCupom,
			vendaOrigem: venda.vendaOrigem,
			pagamentos: pagamentoDemonstracao,
			tipoDeTransacao: 'Venda',
			tipoNota: 'Saída',
			isTroca: false,
			desconto: null,
			pedido: null,
			demonstracaoConvertida: false,
			consignacaoConvertida: false,
			loja: venda.pontoDeVenda ? venda.pontoDeVenda.loja.id : "",
			cashbackAplicado: false,
			valorDeCashbackAplicado: 0,
		}

		vendasAEnviar.push(vendaDemonstracao)
	}

	const itensDaVenda = venda.itens.filter(item => item.isCompra === true && item.quantidade > 0)

	if (itensDaVenda.length > 0) {
		const params = {
			isDevolucao: false,
			isDemonstracao: true,
			isVenda: false,
		}
		let modalidade
		if (venda.pontoDeVenda) {
			modalidade = await findModalidadeDeVendaUseCase.findModalidadePadrao(venda.pontoDeVenda.loja.id, params)
		}
		const vendaNormal: Venda = {
			...venda,
			id: '',
			troco: obterTrocoDaVenda(venda),
			tipoDeCliente: venda.tipoDeCliente || null,
			pontoDeVenda: turnoDeVenda.pontoDeVenda,
			dataHora: venda.dataHora || new Date(),
			itens: itensDaVenda,
			isConsignado: false,
			isDemonstracao: false,
			cpfDoCliente: venda.cliente ? venda.cliente.cnpjOuCpf : '',
			isOrcamento: !gerarCupom,
			vendaOrigem: venda.vendaOrigem,
			pagamentos: venda.pagamentos.filter(pagamento => pagamento.valor > 0) || [],
			tipoDeTransacao: 'Venda',
			tipoNota: 'Saída',
			modalidadeDeVenda: modalidade,
			demonstracaoConvertida: venda.isDemonstracao && venda.isVendaReaberta,
			consignacaoConvertida: venda.isConsignado && venda.isVendaReaberta,
		}

		vendasAEnviar.push(vendaNormal)
	}

	const codigoDoProcesso = getCodigoProcesso(VendaModule.etapaVenda.estado) || 0

	//Processo de devolução de Demonstração/Consignado
	if (itensDevolucao && itensDevolucao.length > 0) {
		const itensDevolucaoEletronica = itensDevolucao.filter(item => !item.itemVendaNaoEletronica)
		const itensDevolucaoNaoEletronica = itensDevolucao.filter(item => item.itemVendaNaoEletronica)
		const vendasOrigemSet = new Set<string>();

		if (venda.vendaOrigem) {
			vendasOrigemSet.add(venda.vendaOrigem);
		} else {
			itensDevolucao.forEach(item => {
				if (item.vendaOrigemDoItem) {
					vendasOrigemSet.add(item.vendaOrigemDoItem);
				}
			});
		}
		const vendasOrigemUnicas = Array.from(vendasOrigemSet);
		const possuiDevolucaoDaVendaOrigem = vendasOrigemUnicas.length > 0 ? await findVendaUseCase.possuiDevolucaoDaVendaOrigem(vendasOrigemUnicas) : false

		if (itensDevolucaoNaoEletronica.length > 0 && codigoDoProcesso < 7) {
			const vendaOriginal: Venda = {
				...venda,
				id: '',
				troco: obterTrocoDaVenda(venda),
				tipoDeCliente: venda.tipoDeCliente || null,
				tipoDeTransacao: 'Devolução',
				pontoDeVenda: turnoDeVenda.pontoDeVenda,
				dataHora: new Date(),
				identificador: venda.identificador,
				itens: itensDevolucaoNaoEletronica,
			}

			VendaModule.setEtapaVenda({
				...VendaModule.etapaVenda,
				estado: 'INICIANDO_DEVOLUCAO_NNE',
			})

			if (vendasAEnviar.length === 0 && itensDevolucaoEletronica.length === 0) {
				const devolucaoNaoEletronica = await processarVendaDevolucaoNaoEletronica(venda, turnoDeVenda, service,
					itensDevolucaoNaoEletronica, vendaOriginal, codigoDoProcesso)
				VendaModule.iniciarNovaVenda({ identificador, novaVendaReaberta: false })
				UserLoginStore.setAutenticandoParaLiberarVendaSemEstoque(false)

				return devolucaoNaoEletronica
			} else if(!possuiDevolucaoDaVendaOrigem){
				await processarVendaDevolucaoNaoEletronica(venda, turnoDeVenda, service,
					itensDevolucaoNaoEletronica, vendaOriginal, codigoDoProcesso)

				VendaModule.setEtapaVenda({
					...VendaModule.etapaVenda,
					estado: 'DEVOLUCAO_NNE_CONCLUIDA',
				})
			}
		}

		if (itensDevolucaoEletronica.length > 0 && codigoDoProcesso < 15) {
			const vendaOriginal: Venda = {
				...venda,
				id: '',
				troco: obterTrocoDaVenda(venda),
				tipoDeCliente: venda.tipoDeCliente || null,
				tipoDeTransacao: 'Devolução',
				pontoDeVenda: turnoDeVenda.pontoDeVenda,
				dataHora: new Date(),
				identificador: venda.identificador,
				itens: itensDevolucaoEletronica,
			}

			VendaModule.setEtapaVenda({
				...VendaModule.etapaVenda,
				estado: 'INICIANDO_DEVOLUCAO_NFE',
			})

			if (vendasAEnviar.length === 0) {
				const devolucaoEletronica = await processarVendaDevolucaoEletronica(venda, turnoDeVenda, gerarCupom,
					emitirDanfe, detalhesDosPagamentos, service, itensDevolucaoEletronica, vendaOriginal, vendasAEnviar, codigoDoProcesso)

				VendaModule.iniciarNovaVenda({ identificador, novaVendaReaberta: false })
				UserLoginStore.setAutenticandoParaLiberarVendaSemEstoque(false)

				return devolucaoEletronica
			} else if(!possuiDevolucaoDaVendaOrigem){
				await processarVendaDevolucaoEletronica(venda, turnoDeVenda, gerarCupom,
					emitirDanfe, detalhesDosPagamentos, service, itensDevolucaoEletronica, vendaOriginal, vendasAEnviar, codigoDoProcesso)

				VendaModule.setEtapaVenda({
					...VendaModule.etapaVenda,
					estado: 'DEVOLUCAO_NFE_CONCLUIDA',
				})
			}
		}
	}

	if (vendasAEnviar.length > 0) {
		let vendaCriadas = vendasAEnviar

		if (codigoDoProcesso < 17) {
			VendaModule.setEtapaVenda({
				vendas: vendaCriadas,
				estado: 'PROCESSANDO_VENDAS',
			})
			vendaCriadas = await service.createVendas(vendasAEnviar)

			VendaModule.setEtapaVenda({
				vendas: vendaCriadas,
				estado: 'VENDAS_CONCLUIDAS',
			})
		}

		if (codigoDoProcesso === 17) {
			vendaCriadas = VendaModule.etapaVenda.vendas
		}

		const venda = vendaCriadas.filter(venda => !venda.isDemonstracao && !venda.isConsignado)[0]
		const demonstracao = vendaCriadas.filter(venda => venda.isDemonstracao)[0]
		if (demonstracao) {
			if (venda) {
				VendaModule.setEtapaVenda({
					...VendaModule.etapaVenda,
					estado: 'PRECESSANDO_IMPRESSAO_DEMONSTRACAO',
				})
				// Função auxiliar responsável por processar a impressão da DANFE, centralizando a lógica para evitar duplicação de código.
				const processarImpressaoOuDanfe = async () => {
					if (ehUmaVendaFiscal) {
						try {
							await emitirDanfePorVendaId(demonstracao.id)
						} catch (error: any) {
							const erroComPrefixo: ErroComPrefixo = {
								error,
								prefixo: 'Erro ao emitir danfe do retorno, verifique na tela de vendas: ',
							}
							AlertModule.setErrorComPrefixo(erroComPrefixo)
						}
					} else {
						await gerarNotaNaoEletronica(demonstracao.id)
					}
				}

				await processarImpressaoOuDanfe()
				VendaModule.setEtapaVenda({
					...VendaModule.etapaVenda,
					estado: 'FINALIZOU_PRECESSO_IMPRESSAO_DEMONSTRACAO',
				})

				localStorage.setItem('ULTIMA_VENDA', JSON.stringify(venda))
				VendaModule.setEtapaVenda({
					...VendaModule.etapaVenda,
					estado: 'PRECESSANDO_IMPRESSAO_VENDA',
				})

				await imprimirCupomOuDanfe(venda, gerarCupom, emitirDanfe, detalhesDosPagamentos)
				VendaModule.setEtapaVenda({
					...VendaModule.etapaVenda,
					estado: 'FINALIZOU_PRECESSO_IMPRESSAO_VENDA',
				})

				VendaModule.iniciarNovaVenda({ identificador, novaVendaReaberta: false })
				UserLoginStore.setAutenticandoParaLiberarVendaSemEstoque(false)

				return venda
			} else {
				VendaModule.setEtapaVenda({
					...VendaModule.etapaVenda,
					estado: 'PRECESSANDO_IMPRESSAO_DEMONSTRACAO',
				})
				await imprimirCupomOuDanfe(demonstracao, gerarCupom, emitirDanfe, detalhesDosPagamentos)
				localStorage.setItem('ULTIMA_VENDA', JSON.stringify(demonstracao))
				VendaModule.iniciarNovaVenda({ identificador, novaVendaReaberta: false })
				UserLoginStore.setAutenticandoParaLiberarVendaSemEstoque(false)
				VendaModule.setEtapaVenda({
					...VendaModule.etapaVenda,
					estado: 'FINALIZOU_PRECESSO_IMPRESSAO_DEMONSTRACAO',
				})

				return demonstracao
			}

		} else if (venda && vendaCriadas.length > 1) {
			VendaModule.setEtapaVenda({
				...VendaModule.etapaVenda,
				estado: 'PRECESSANDO_IMPRESSAO_VENDA',
			})
			await imprimirCupomOuDanfe(venda, gerarCupom, emitirDanfe, detalhesDosPagamentos)
			VendaModule.setEtapaVenda({
				...VendaModule.etapaVenda,
				estado: 'FINALIZOU_PRECESSO_IMPRESSAO_VENDA',
			})
			localStorage.setItem('ULTIMA_VENDA', JSON.stringify(venda))
			VendaModule.iniciarNovaVenda({ identificador, novaVendaReaberta: false })
			UserLoginStore.setAutenticandoParaLiberarVendaSemEstoque(false)

			return venda
		} else {
			VendaModule.setEtapaVenda({
				...VendaModule.etapaVenda,
				estado: 'PRECESSANDO_IMPRESSAO_VENDA',
			})
			const ImprimirVenda = vendaCriadas[0]
			await imprimirCupomOuDanfe(ImprimirVenda, gerarCupom, emitirDanfe, detalhesDosPagamentos)
			VendaModule.setEtapaVenda({
				...VendaModule.etapaVenda,
				estado: 'FINALIZOU_PRECESSO_IMPRESSAO_VENDA',
			})
			localStorage.setItem('ULTIMA_VENDA', JSON.stringify(ImprimirVenda))
			VendaModule.iniciarNovaVenda({ identificador, novaVendaReaberta: false })
			UserLoginStore.setAutenticandoParaLiberarVendaSemEstoque(false)

			return ImprimirVenda
		}
	} else {
		if (itensDevolucao && itensDevolucao.length > 0 && !ehUmaVendaFiscal && venda.vendaIdParaCancelar && codigoDoProcesso < 23) {
			VendaModule.setEtapaVenda({
				...VendaModule.etapaVenda,
				estado: 'PROCESSANDO_CANCELAMENTO_VENDAS',
			})
			await service.cancelar(venda.vendaIdParaCancelar)
			VendaModule.setEtapaVenda({
				...VendaModule.etapaVenda,
				estado: 'PROCESSO_CANCELAMENTO_VENDAS_CONCLUIDO',
			})
		}
		VendaModule.iniciarNovaVenda({ identificador, novaVendaReaberta: false })
		UserLoginStore.setAutenticandoParaLiberarVendaSemEstoque(false)
		return venda
	}
}

async function processarVendaDevolucaoEletronica(venda: Venda, turnoDeVenda: TurnoDeVenda, gerarCupom: boolean,
	emitirDanfe: boolean, detalhesDosPagamentos: DetalhesDoPagamento[], service: VendaServiceAdapter,
	itensDevolucaoEletronica: ItemDaVenda[], vendaOriginal: Venda, vendasAEnviar: Venda[], codigoDoProcesso: number): Promise<Venda> {

	let identificadorDevolucao = ''
	try {
		identificadorDevolucao = await service.gerarIdentificadorDeVenda(turnoDeVenda.pontoDeVenda.id)
	} catch (error: any) {
		throw Error('Falha ao finalizar a venda, tente novamente ou contate o suporte.')
	}

	const vendasOrigemId = Array.from(
		new Set(itensDevolucaoEletronica.map(item => item.vendaOrigemDoItem)),
	)

	const vendaDevolucaoEletronica: Venda = {
		...criarDevolucaoParaDemonstracaoOuConsignado({
			itens: itensDevolucaoEletronica,
			vendaOrigem: venda,
			vendaOriginal: vendaOriginal,
			...turnoDeVenda.pontoDeVenda.parametrosFiscais,
			tipoDeTransacao: 'Devolução',
		}),
		pontoDeVenda: turnoDeVenda.pontoDeVenda,
		dataHora: new Date(),
		identificador: identificadorDevolucao,
		isConsignado: venda.isConsignado,
		isDemonstracao: venda.isDemonstracao,
		cliente: venda.cliente,
		desconto: null,
		vendaOrigem: vendasOrigemId.length === 1 ? vendasOrigemId[0] : null,
	}

	VendaModule.setEtapaVenda({
		...VendaModule.etapaVenda,
		estado: 'INICIANDO_DEVOLUCAO_DEMONSTRACAO_NFE',
	})

	if (vendaDevolucaoEletronica.isConsignado && codigoDoProcesso < 10) {
		await service.devolucaoVendaConsignada(vendaDevolucaoEletronica)
	} else if (codigoDoProcesso < 10) {
		await service.devolucaoVendaDemonstracao(vendaDevolucaoEletronica)
	}

	VendaModule.setEtapaVenda({
		...VendaModule.etapaVenda,
		estado: 'DEVOLUCAO_DEMONSTRACAO_NFE_CONCLUIDA',
	})
	if (codigoDoProcesso < 12) {
		try {
			VendaModule.setEtapaVenda({
				...VendaModule.etapaVenda,
				estado: 'GERANDO_NFE',
			})

			await emitirDanfePorVendaId(vendaDevolucaoEletronica.id)

			VendaModule.setEtapaVenda({
				...VendaModule.etapaVenda,
				estado: 'NFE_GERADA',
			})
			if (vendasAEnviar.length === 0) {
				VendaModule.setEtapaVenda({
					...VendaModule.etapaVenda,
					estado: 'IMPRIMINDO_NF_DEVOLUCAO',
				})
				await imprimirCupomOuDanfe(vendaDevolucaoEletronica, gerarCupom, emitirDanfe, detalhesDosPagamentos)
				VendaModule.setEtapaVenda({
					...VendaModule.etapaVenda,
					estado: 'IMPRESSAO_NF_DEVOLUCAO_CONCLUIDA',
				})

				return vendaDevolucaoEletronica
			}
		} catch (error: any) {
			const erroComPrefixo = {
				error: error,
				prefixo: 'Erro ao emitir danfe do retorno, verifique na tela de vendas: ',
			} as ErroComPrefixo
			AlertModule.setErrorComPrefixo(erroComPrefixo)
		}
	}

	return vendaDevolucaoEletronica
}

async function processarVendaDevolucaoNaoEletronica(venda: Venda, turnoDeVenda: TurnoDeVenda, service: VendaServiceAdapter,
	itensDevolucaoNaoEletronica: ItemDaVenda[], vendaOriginal: Venda, codigoDoProcesso: number): Promise<Venda> {
	let identificadorDevolucao = ''
	try {
		identificadorDevolucao = await service.gerarIdentificadorDeVenda(turnoDeVenda.pontoDeVenda.id)
	} catch (error: any) {
		throw Error('Falha ao finalizar a venda, tente novamente ou contate o suporte.')
	}

	const vendasOrigemId = Array.from(
		new Set(itensDevolucaoNaoEletronica.map(item => item.vendaOrigemDoItem)),
	)

	const listaDasVendasSemNota = await service.filtrarVendasSemNota({ vendasId: vendasOrigemId } as FiltroDeVendasSemNota)

	const novaListaDeItens = itensDevolucaoNaoEletronica.filter(item =>
		!listaDasVendasSemNota.some(vendaSemNotaId => vendaSemNotaId === item.vendaOrigemDoItem))

	if (listaDasVendasSemNota.length > 0 && codigoDoProcesso < 2) {
		VendaModule.setEtapaVenda({
			...VendaModule.etapaVenda,
			estado: 'CANCELANDO_NNES',
		})
		await service.cancelarVendas(listaDasVendasSemNota)
		VendaModule.setEtapaVenda({
			...VendaModule.etapaVenda,
			estado: 'CANCELAMENTO_NNES_CONCLUIDO',
		})
		if (novaListaDeItens.length === 0) return venda
	}

	const vendaDevolucaoEletronica: Venda = {
		...criarDevolucaoParaDemonstracaoOuConsignado({
			itens: novaListaDeItens,
			vendaOrigem: venda,
			vendaOriginal: vendaOriginal,
			...turnoDeVenda.pontoDeVenda.parametrosFiscais,
			tipoDeTransacao: 'Devolução',
		}),
		pontoDeVenda: turnoDeVenda.pontoDeVenda,
		dataHora: new Date(),
		identificador: identificadorDevolucao,
		isConsignado: venda.isConsignado,
		isDemonstracao: venda.isDemonstracao,
		cliente: venda.cliente,
		desconto: null,
		vendaOrigem: vendasOrigemId.length === 1 ? vendasOrigemId[0] : null,
	}

	VendaModule.setEtapaVenda({
		...VendaModule.etapaVenda,
		estado: 'INICIANDO_DEVOLUCAO_DEMONSTRACAO_NNE',
	})

	if (vendaDevolucaoEletronica.isConsignado && codigoDoProcesso < 4) {
		await service.devolucaoVendaConsignada(vendaDevolucaoEletronica)
		VendaModule.setEtapaVenda({
			...VendaModule.etapaVenda,
			estado: 'DEVOLUCAO_DEMONSTRACAO_NNE_CONCLUIDA',
		})
	} else if (codigoDoProcesso < 4) {
		await service.devolucaoVendaDemonstracao(vendaDevolucaoEletronica)
		VendaModule.setEtapaVenda({
			...VendaModule.etapaVenda,
			estado: 'DEVOLUCAO_DEMONSTRACAO_NNE_CONCLUIDA',
		})
	}
	if (codigoDoProcesso < 6) {
		try {
			VendaModule.setEtapaVenda({
				...VendaModule.etapaVenda,
				estado: 'GERANDO_NNE',
			})
			await gerarNotaNaoEletronica(vendaDevolucaoEletronica.id)
			VendaModule.setEtapaVenda({
				...VendaModule.etapaVenda,
				estado: 'NNE_GERADA',
			})
			return vendaDevolucaoEletronica
		} catch (error: any) {
			const erroComPrefixo = {
				error: error,
				prefixo: 'Erro ao emitir danfe do retorno, verifique na tela de vendas: ',
			} as ErroComPrefixo
			AlertModule.setErrorComPrefixo(erroComPrefixo)
		}
	}
	return vendaDevolucaoEletronica
}

async function imprimirCupomOuDanfe(vendaAtualizada: Venda, gerarCupom: boolean, emitirDanfe: boolean, detalhesDosPagamentos: DetalhesDoPagamento[]) {
	const reservarNumeroDaNota = vendaAtualizada.pagamentos.filter(pagamento => pagamento.tipoDePagamento.reservarNumeroDaNota).length > 0

	if (gerarCupom && emitirDanfe) {
		try {
			await imprimirNotaFiscal(vendaAtualizada.id, true, false, reservarNumeroDaNota)
		} catch (error: any) {
			AlertModule.setError(error)
		}
	} else {
		if (gerarCupom && (vendaAtualizada.pontoDeVenda?.podeGerarNFCE || vendaAtualizada.pontoDeVenda?.podeGerarNFE)) {
			try {
				await imprimirNotaFiscal(vendaAtualizada.id, false, false, reservarNumeroDaNota)
			} catch (error: any) {
				AlertModule.setError(error)
			}
		}

		PluginModule.emit("finalizarVenda", { venda: vendaAtualizada })

		if (vendaAtualizada.tipoDeTransacao === 'Devolução' || vendaAtualizada.tipoDeTransacao === 'Outros') {
			AlertModule.setSuccess("Devolução realizada com sucesso")
		} else if ((vendaAtualizada.pontoDeVenda?.imprimeReciboNoOrcamento && !gerarCupom) || reservarNumeroDaNota) {
			const vendaComCliente = {
				...vendaAtualizada,
				cliente: vendaAtualizada.cliente,
			}

			if (!vendaAtualizada.pontoDeVenda?.criaPedidoNaVenda && !reservarNumeroDaNota) {
				try {
					await gerarNotaNaoEletronica(vendaAtualizada.id)
				} catch (error: any) {
					AlertModule.setError(error);
				}
			}

			await imprimirCupomSemValorFiscal(vendaComCliente, detalhesDosPagamentos);
		} else if (!vendaAtualizada.pontoDeVenda?.imprimeReciboNoOrcamento && !gerarCupom) {
			if (!vendaAtualizada.pontoDeVenda?.criaPedidoNaVenda && !reservarNumeroDaNota) {
				try {
					await gerarNotaNaoEletronica(vendaAtualizada.id)
				} catch (error: any) {
					AlertModule.setError(error);
				}
			}
		}
	}
}

async function emitirDanfePorVendaId(vendaId: string) {
	await emitirEComunicarDanfe(vendaId)
}

async function validarEstoque(venda: Venda, turnoDeVenda: TurnoDeVenda, ehUmaDemonstracaoSemNotaEReaberta: boolean,
	ehUmaTroca: boolean, findEstoqueUseCase: FindEstoqueUseCase) {
	const ehUmaDemonstracao = venda.isDemonstracao || venda.isConsignado

	const gerarEstoqueNaVenda = turnoDeVenda.pontoDeVenda.loja.configuracaoDaLoja?.podeGerarEstoqueNaVenda ||
		UserLoginStore.permiteRegraDeNegocio('pode-liberar-produto-sem-estoque') || UserLoginStore.autenticandoParaLiberarVendaSemEstoque

	if ((!gerarEstoqueNaVenda || turnoDeVenda.pontoDeVenda.criaPedidoNaVenda) && venda.tipoDeTransacao === 'Venda'
		&& !ehUmaDemonstracaoSemNotaEReaberta && !ehUmaDemonstracao) {
		const itensUnificados = unificarItensDaVendaPorProduto(venda.itens)
		const podeLiberarEstoque = UserLoginStore.permiteRegraDeNegocio('pode-liberar-produto-sem-estoque') || UserLoginStore.autenticandoParaLiberarVendaSemEstoque
		let projecoesPorItemDaVenda
		if (!podeLiberarEstoque && !VendaModule.vendaAtual?.itens.some(item => item.produto.isValePresente)) {
			projecoesPorItemDaVenda = await Promise.all(
				itensUnificados.map(async (itemDaVenda: any) => {
					let criticas = ''
					await findEstoqueUseCase.getEstoqueDoItemDaVendaNaLoja(
						itemDaVenda.id, itemDaVenda.quantidade.toString(), turnoDeVenda?.pontoDeVenda.loja.id || '', itemDaVenda.autenticadoParaLiberarProdutoSemEstoque,
					).catch((error) => {
						criticas = error instanceof Object ? error.response.data[0] : error
					})
					return criticas
				}),
			)
			projecoesPorItemDaVenda = projecoesPorItemDaVenda.filter(value => value !== '')
		}
		const criticasNormalizadas = projecoesPorItemDaVenda
			? projecoesPorItemDaVenda.join('<br>')
			: undefined
		if (criticasNormalizadas) {
			throw criticasNormalizadas
		}
	}

	//valida estoque somente dos itens positivos(itens da venda)
	if (ehUmaTroca && !gerarEstoqueNaVenda) {
		const itensUnificados = unificarItensDaVendaPorProduto(venda.itens.filter(item => item.quantidade > 0))
		const podeLiberarEstoque = UserLoginStore.permiteRegraDeNegocio('pode-liberar-produto-sem-estoque') || UserLoginStore.autenticandoParaLiberarVendaSemEstoque
		let projecoesPorItemDaVenda
		if (!podeLiberarEstoque && !VendaModule.vendaAtual?.itens.some(item => item.produto.isValePresente)) {
			projecoesPorItemDaVenda = await Promise.all(
				itensUnificados.map(async (itemDaVenda: any) => {
					let criticas = ''
					await findEstoqueUseCase.getEstoqueDoItemDaVendaNaLoja(
						itemDaVenda.id, itemDaVenda.quantidade.toString(), turnoDeVenda?.pontoDeVenda.loja.id || '', itemDaVenda.autenticadoParaLiberarProdutoSemEstoque,
					).catch((error) => {
						criticas = error instanceof Object ? error.response.data[0] : error
					})
					return criticas
				}),
			)
			projecoesPorItemDaVenda = projecoesPorItemDaVenda.filter(value => value !== '')
		}
		const criticasNormalizadas = projecoesPorItemDaVenda
			? projecoesPorItemDaVenda.join('<br>')
			: undefined
		if (criticasNormalizadas) {
			throw criticasNormalizadas
		}
	}
}