import "reflect-metadata";
import { injectable } from 'inversify-props'
import { GrupoEconomicoServiceAdapter } from "@/usecases/grupo-economico/adapter/GrupoEconomicoServiceAdapter";
import { GrupoEconomico } from "@/models/GrupoEconomico";
import { create, update, find, findLojasByGrupoEconomico, findByParams, deleteGrupoEconomico, findGruposDoCliente, listarGruposDoFornecedor } from "@/api/grupoeconomico";
import { AxiosRequestConfig } from "axios";

@injectable()
export class GrupoEconomicoService implements GrupoEconomicoServiceAdapter {
	create = async (grupoEconomico: GrupoEconomico) => await create(grupoEconomico)
	update = async (grupoEconomico: GrupoEconomico) => await update(grupoEconomico)
	find = async () => await find()
	findLojasByGrupoEconomico = async (grupoEconomicoId: string) =>  await findLojasByGrupoEconomico(grupoEconomicoId)
	findByParams = async (params?: any, config?: AxiosRequestConfig) => await findByParams(params, config)
	delete = async (grupoEconomicoId: string) => await deleteGrupoEconomico(grupoEconomicoId)
	findGruposDoCliente = async (cpfCnpjCliente: string, params?: any, config?: AxiosRequestConfig) => await findGruposDoCliente(cpfCnpjCliente, params, config)
	listarGruposDoFornecedor = async (cpfCnpjFornecedor: string, params?: any, config?: AxiosRequestConfig) => await listarGruposDoFornecedor(cpfCnpjFornecedor, params, config)
}