import { container } from 'inversify-props'
import * as usecases from '@/usecases'
import * as services from '@/services'
import { backendDomain } from '@/shareds/utils'
import { EtiquetaDePrecoService } from './services/etiqueta/EtiquetaDePrecoService'
import { NotificacaoService } from '@/services'

export default function buildDependencyContainer(): void {
	console.log(backendDomain())
	container
		.bind<usecases.LoginServiceAdapter>('LoginServiceAdapter')
		.toConstantValue(new services.LoginService())

	container
		.bind<usecases.ContaServiceAdapter>('ContaServiceAdapter')
		.toConstantValue(new services.AccountService())

	container
		.bind<usecases.UsuarioServiceAdapter>('UsuarioServiceAdapter')
		.toConstantValue(new services.UsuarioService())

	container
		.bind<usecases.LojaServiceAdapter>('LojaServiceAdapter')
		.toConstantValue(new services.LojaService())

	container
		.bind<usecases.PerfilDeUsuarioServiceAdapter>(
			'PerfilDeUsuarioServiceAdapter',
		)
		.toConstantValue(new services.PerfilDeUsuarioService())

	container
		.bind<usecases.ProdutoServiceAdapter>('ProdutoServiceAdapter')
		.toConstantValue(new services.ProdutoService())

	container
		.bind<usecases.COFINSServiceAdapter>('COFINSServiceAdapter')
		.toConstantValue(new services.CofinsService())

	container
		.bind<usecases.CSOSNServiceAdapter>('CSOSNServiceAdapter')
		.toConstantValue(new services.CSOSNService())

	container
		.bind<usecases.PISServiceAdapter>('PISServiceAdapter')
		.toConstantValue(new services.PISService())

	container
		.bind<usecases.RegraDeImpostoServiceAdapter>('RegraDeImpostoServiceAdapter')
		.toConstantValue(new services.RegraDeImpostoService())

	container
		.bind<usecases.ServiceICMSAdapter>('ServiceICMSAdapter')
		.toConstantValue(new services.ServiceICMS())

	container
		.bind<usecases.ServiceIPIAdapter>('ServiceIPIAdapter')
		.toConstantValue(new services.ServiceIPI())

	container
		.bind<usecases.OrigemServiceAdapter>('OrigemServiceAdapter')
		.toConstantValue(new services.OrigemService())

	container
		.bind<usecases.CFOPServiceAdapter>('CFOPServiceAdapter')
		.toConstantValue(new services.CFOPService())

	container
		.bind<usecases.CESTServiceAdapter>('CESTServiceAdapter')
		.toConstantValue(new services.CESTService())

	container
		.bind<usecases.AtributoServiceAdapter>('AtributoServiceAdapter')
		.toConstantValue(new services.AtributoService())

	container
		.bind<usecases.NCMServiceAdapter>('NCMServiceAdapter')
		.toConstantValue(new services.NCMService())

	container
		.bind<usecases.MarcaServiceAdapter>('MarcaServiceAdapter')
		.toConstantValue(new services.MarcaService())

	container
		.bind<usecases.GeneroServiceAdapter>('GeneroServiceAdapter')
		.toConstantValue(new services.GeneroService())

	container
		.bind<usecases.CategoriaServiceAdapter>('CategoriaServiceAdapter')
		.toConstantValue(new services.CategoriaService())

	container
		.bind<usecases.TagServiceAdapter>('TagServiceAdapter')
		.toConstantValue(new services.TagService())

	container
		.bind<usecases.DepositoServiceAdapter>('DepositoServiceAdapter')
		.toConstantValue(new services.DepositoService())

	container
		.bind<usecases.EstoqueServiceAdapter>('EstoqueServiceAdapter')
		.toConstantValue(new services.EstoqueService())

	container
		.bind<usecases.TurnoDeVendaServiceAdapter>('TurnoDeVendaServiceAdapter')
		.toConstantValue(new services.TurnoDeVendaService())

	container
		.bind<usecases.VendaServiceAdapter>('VendaServiceAdapter')
		.toConstantValue(new services.VendaService())

	container
		.bind<usecases.PagamentoServiceAdapter>('PagamentoServiceAdapter')
		.toConstantValue(new services.PagamentoService())

	container
		.bind<usecases.PedidoServiceAdapter>('PedidoServiceAdapter')
		.toConstantValue(new services.PedidoService())

	container
		.bind<usecases.ClienteServiceAdapter>('ClienteServiceAdapter')
		.toConstantValue(new services.ClienteService())
	container
		.bind<usecases.BuscaCepServiceAdapter>('BuscaCepServiceAdapter')
		.toConstantValue(new services.BuscaCepService())
	container
		.bind<usecases.TabelaDePrecoServiceAdapter>('TabelaDePrecoServiceAdapter')
		.toConstantValue(new services.TabelaDePrecoService())
	container
		.bind<usecases.TipoDeClienteServiceAdapter>('TipoDeClienteServiceAdapter')
		.toConstantValue(new services.TipoDeClienteService())
	container
		.bind<usecases.FechamentoDeCaixaServiceAdapter>('FechamentoDeCaixaServiceAdapter')
		.toConstantValue(new services.FechamentoDeCaixaService())

	container
		.bind<usecases.DadosDeProdutoServiceAdapter>('DadosDeProdutoServiceAdapter')
		.toConstantValue(new services.DadosDeProdutoService())
	container
		.bind<usecases.DadosDeVendaServiceAdapter>('DadosDeVendasServiceAdapter')
		.toConstantValue(new services.DadosDeVendasService())

	container
		.bind<usecases.ConfiguracaoDeCupomServiceAdapter>('ConfiguracaoDeCupomServiceAdapter')
		.toConstantValue(new services.ConfiguracaoDeCupomService())

	container
		.bind<usecases.ConsultaNFServiceAdapter>('ConsultaNFService')
		.toConstantValue(new services.ConsultaNFService)

	container
		.bind<usecases.FiscalServiceAdapter>('FiscalServiceAdapter')
		.toConstantValue(new services.FiscalService())
	container
		.bind<usecases.VoucherServiceAdapter>('VoucherServiceAdapter')
		.toConstantValue(new services.VoucherService())
	container
		.bind<usecases.MotivoDaMovimentacaoServiceAdapter>('MotivoDaMovimentacaoServiceAdapter')
		.toConstantValue(new services.MotivoDaMovimentacaoService())

	container
		.bind<usecases.NotaFiscalServiceAdapter>('NotaFiscalServiceAdapter')
		.toConstantValue(new services.NotaFiscalService())

	container
		.bind<usecases.CapaDaNotaServiceAdapter>('CapaDaNotaServiceAdapter')
		.toConstantValue(new services.CapaDaNotaService())

	container
		.bind<usecases.ManifestoServiceAdapter>('ManifestoServiceAdapter')
		.toConstantValue(new services.ManifestoService())

	container
		.bind<usecases.FornecedorServiceAdapter>('FornecedorServiceAdapter')
		.toConstantValue(new services.FornecedorService())

	container
		.bind<usecases.EtiquetaDePrecoServiceAdapter>('EtiquetaDePrecoServiceAdapter')
		.toConstantValue(new EtiquetaDePrecoService())

	container
		.bind<usecases.TituloServiceAdapter>('TituloServiceAdapter')
		.toConstantValue(new services.TituloService())

	container
		.bind<usecases.NotificacaoServiceAdapter>('NotificacaoServiceAdapter')
		.toConstantValue(new NotificacaoService())

	container
		.bind<usecases.ImportacaoDeNotaServiceAdapter>('ImportacaoDeNotaServiceAdapter')
		.toConstantValue(new services.ImportacaoDeNotaService())

	container
		.bind<usecases.PdvServiceAdapter>('PdvServiceAdapter')
		.toConstantValue(new services.PdvService())

	container
		.bind<usecases.RelatorioServiceAdapter>('RelatorioServiceAdapter')
		.toConstantValue(new services.RelatorioService())

	container
		.bind<usecases.ImportacaoServiceAdapter>('ImportacaoServiceAdapter')
		.toConstantValue(new services.ImportacaoService())

	container
		.bind<usecases.RegraDeAplicacaoServiceAdapter>('RegraDeAplicacaoServiceAdapter')
		.toConstantValue(new services.RegraDeAplicacaoService())

	container
		.bind<usecases.RegraPorEstadoServiceAdapter>('RegraPorEstadoServiceAdapter')
		.toConstantValue(new services.RegraPorEstadoService())

	container
		.bind<usecases.ComandaServiceAdapter>('ComandaServiceAdapter')
		.toConstantValue(new services.ComandaService())

	container
		.bind<usecases.MetaServiceAdapter>('MetaServiceAdapter')
		.toConstantValue(new services.MetaService())

	container
		.bind<usecases.FaqServiceAdapter>('FaqServiceAdapter')
		.toConstantValue(new services.FaqService())

	container
		.bind<usecases.CashbackServiceAdapter>('CashbackServiceAdapter')
		.toConstantValue(new services.CashbackService())

	container
		.bind<usecases.DetalhesDoPagamentoServiceAdapter>('DetalhesDoPagamentoServiceAdapter')
		.toConstantValue(new services.DetalhesDoPagamentoService())

	container
		.bind<usecases.GrupoEconomicoServiceAdapter>('GrupoEconomicoServiceAdapter')
		.toConstantValue(new services.GrupoEconomicoService())

	container
		.bind<usecases.DashboardsServiceAdapter>('DashboardsServiceAdapter')
		.toConstantValue(new services.DashboardService())

	container
		.bind<usecases.NotasEmTransitoServiceAdapter>('NotasEmTransitoServiceAdapter')
		.toConstantValue(new services.NotasEmTransitoService())

	container
		.bind<usecases.RegistroDeCustosServiceAdapter>('RegistroDeCustosServiceAdapter')
		.toConstantValue(new services.RegistroDeCustosService())

	container
		.bind<usecases.MotivosDeCancelamentoServiceAdapter>('MotivosDeCancelamentoServiceAdapter')
		.toConstantValue(new services.MotivosDeCancelamentoService)

	container
		.bind<usecases.BandeirasDeCartaoServiceAdapter>('BandeirasDeCartaoServiceAdapter')
		.toConstantValue(new services.BandeirasDeCartaoService())

	container
		.bind<usecases.PreVendaServiceAdapter>('PreVendaServiceAdapter')
		.toConstantValue(new services.PreVendaService())

	container
		.bind<usecases.ItemDaPreVendaServiceAdapter>('ItemDaPreVendaServiceAdapter')
		.toConstantValue(new services.ItemDaPreVendaService())

	container
		.bind<usecases.BaixaDePagamentoServiceAdapter>('BaixaDePagamentoServiceAdapter')
		.toConstantValue(new services.BaixaDePagamentoService())

	container
		.bind<usecases.AdquirenteServiceAdapter>('AdquirenteServiceAdapter')
		.toConstantValue(new services.AdquirenteService())

	container
		.bind<usecases.ModalidadeDeVendaServiceAdapter>('ModalidadeDeVendaServiceAdapter')
		.toConstantValue(new services.ModalidadeDeVendaService())

	container
		.bind<usecases.AnexoServiceAdapter>('AnexoServiceAdapter')
		.toConstantValue(new services.AnexoService())

	container
		.bind<usecases.EstacaoServiceAdapter>('EstacaoServiceAdapter')
		.toConstantValue(new services.EstacaoService())

	container
		.bind<usecases.MotivoDeCancelamentoFiscalServiceAdapter>('MotivoDeCancelamentoFiscalServiceAdapter')
		.toConstantValue(new services.MotivoDeCancelamentoFiscalService)

	container
		.bind<usecases.ProfissaoServiceAdapter>('ProfissaoServiceAdapter')
		.toConstantValue(new services.ProfissaoService())

	container
		.bind<usecases.CartaDeCorrecaoServiceAdapter>('CartaDeCorrecaoServiceAdapter')
		.toConstantValue(new services.CartaDeCorrecaoService())

	container
		.bind<usecases.TifluxServiceAdapter>('TifluxServiceAdapter')
		.toConstantValue(new services.TifluxService())

	container
		.bind<usecases.ValePresenteServiceAdapter>('ValePresenteServiceAdapter')
		.toConstantValue(new services.ValePresenteService())

	container
		.bind<usecases.EmailServiceAdapter>('EmailServiceAdapter')
		.toConstantValue(new services.EmailService())

	container
		.bind<usecases.AnalyticsServiceAdapter>('AnalyticsServiceAdapter')
		.toConstantValue(new services.AnalyticsService())

	container
		.bind<usecases.GradeDeProdutosServiceAdapter>('GradeDeProdutosServiceAdapter')
		.toConstantValue(new services.GradeDeProdutosService())

	container
		.bind<usecases.CentroDeCustoServiceAdapter>('CentroDeCustoServiceAdapter')
		.toConstantValue(new services.CentroDeCustoService())
}
