import { ItemDaNotaEmTransito, ItemDaNotaEmTransitoSalvo, NotaEmTransito, Page } from "@/models"
import { Inject } from "inversify-props"
import type { NotasEmTransitoServiceAdapter } from "./adapter"
import { AxiosRequestConfig } from "axios"
import { imprimirNotaEmTransito } from "@/shareds/venda-shareds"
export class NotasEmTransitoUseCase {
	@Inject('NotasEmTransitoServiceAdapter')
	private notasEmTransitoServiceAdapter!: NotasEmTransitoServiceAdapter

	find = async (params?: any, config?: AxiosRequestConfig): Promise<Page<NotaEmTransito>> =>
		await this.notasEmTransitoServiceAdapter.find(params, config)

	receberNotaEmTransito = async (nota: NotaEmTransito): Promise<NotaEmTransito> =>
		await this.notasEmTransitoServiceAdapter.receberNotaEmTransito(nota)

	imprimirNotaEmTransito = async (notaEmTransito: NotaEmTransito): Promise<any> =>
		imprimirNotaEmTransito(notaEmTransito)

	remover = async (params: any): Promise<void> =>
		await this.notasEmTransitoServiceAdapter.remover(params)

	findByChaveDanfe = async (chaveDanfe: string): Promise<NotaEmTransito> =>
		await this.notasEmTransitoServiceAdapter.findByChaveDanfe(chaveDanfe)

	getProdutosDaNotaEmTransito = async (chaveDanfe: string): Promise<ItemDaNotaEmTransito> =>
		await this.notasEmTransitoServiceAdapter.getProdutosDaNotaEmTransito(chaveDanfe)

	salvarProdutoBipadoDaNota = async (produto: ItemDaNotaEmTransitoSalvo): Promise<ItemDaNotaEmTransitoSalvo> =>
		await this.notasEmTransitoServiceAdapter.salvarProdutoBipadoDaNota(produto)	

	resetarBipagem = async (notaId: string | null): Promise<any> =>
		await this.notasEmTransitoServiceAdapter.resetarBipagem(notaId)

	salvarMotivoProdutoFaltante = async (produto: ItemDaNotaEmTransitoSalvo): Promise<any> =>
		await this.notasEmTransitoServiceAdapter.salvarMotivoProdutoFaltante(produto)

	deleteMotivoFaltante = async (produto: ItemDaNotaEmTransitoSalvo): Promise<any> =>
		await this.notasEmTransitoServiceAdapter.deleteMotivoFaltante(produto)
}