import request from '@/common/request'
import {
	GradeDeProdutos,
	Page,
} from '@/models'
import { AxiosRequestConfig } from 'axios'

const basePath = '/grade-de-produtos'

export const findGrade = async (params?: any, config?: AxiosRequestConfig): Promise<Page<GradeDeProdutos>> =>
	request.get(`${basePath}`, {
		params,
		...config,
	})

export const updateGrade = async (gradeDeProdutos: GradeDeProdutos): Promise<GradeDeProdutos> =>
	request.put(`${basePath}`, gradeDeProdutos)

export const createGrade = async (gradeDeProdutos: GradeDeProdutos): Promise<GradeDeProdutos> =>
	request.post(`${basePath}`, gradeDeProdutos)

export const removeGrade = async (id: string): Promise<void> =>
	request.delete(`${basePath}/${id}`)