import type { CapaDaNotaServiceAdapter } from './adapter/CapaDaNotaServiceAdapter';
import { AxiosRequestConfig } from 'axios';
import { Inject } from 'inversify-props';
import { Page, CapaDaNota, FiltroDeCapaDaNota, CapaDaNotaParaCartaDeCorrecao, NotaFiscalCompleta } from '@/models';
export class FindCapaDaNotaUseCase {
	@Inject('CapaDaNotaServiceAdapter')
	private capaDaNotaServiceAdapter!: CapaDaNotaServiceAdapter

	find = async (params?: FiltroDeCapaDaNota, config?: AxiosRequestConfig): Promise<Page<CapaDaNota>> =>
		await this.capaDaNotaServiceAdapter.find(params, config)

	downloadXml = async (params: FiltroDeCapaDaNota, config?: AxiosRequestConfig): Promise<any> =>
		await this.capaDaNotaServiceAdapter.downloadXml(params, config)

	findByChNFe = async (chNFe: string): Promise<CapaDaNotaParaCartaDeCorrecao> =>
		await this.capaDaNotaServiceAdapter.findByChNFe(chNFe)

	findNotasParaDevolucao = async (params?: any): Promise<Page<CapaDaNota>> =>
		await this.capaDaNotaServiceAdapter.findNotasParaDevolucao(params)

	getDetalhes = async (notaId: string): Promise<NotaFiscalCompleta> =>
		await this.capaDaNotaServiceAdapter.getDetalhes(notaId)

	findIdDestByChave = async (chaveDaNota: string[], config?: AxiosRequestConfig): Promise<boolean | null> => 
		await this.capaDaNotaServiceAdapter.findIdDestByChave(chaveDaNota, config)
}
