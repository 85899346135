import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { Inject } from 'inversify-props'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import { criarEmissaoDeNota, criarVendaDoPdv } from '@/shareds/venda-shareds'
import { ItemDaVenda } from "@/models"
import { FindModalidadeDeVendaUseCase, type VendaServiceAdapter } from '../venda'
import type { PreVendaServiceAdapter } from './adapter'
import { PreVenda } from '@/models/pre-venda/PreVenda'
import AplicarTabelaDePrecoUseCase from '../tabela-de-precos/AplicarTabelaDePrecoUseCase'
import type { ClienteServiceAdapter } from '../cliente'

export class ImportarPreVendaUseCase {
	@Inject('PreVendaServiceAdapter')
	private preVendaService!: PreVendaServiceAdapter

	@Inject('VendaServiceAdapter')
	private vendaService!: VendaServiceAdapter

	@Inject('ClienteServiceAdapter')
	private clienteService!: ClienteServiceAdapter

	findModalidadeDeVendaUseCase = new FindModalidadeDeVendaUseCase()


	async importarPreVenda(identificador: string, telaDeEmissaoDeNota: boolean) {
		try {

			let preVenda: PreVenda
			const loja = telaDeEmissaoDeNota
				? VendaModule.lojaDaEntrada
				: VendaModule.lojaDaVenda

			if (!loja) throw new Error('Loja não selecionada')
			if ((VendaModule.turnoDeVenda == null || !VendaModule.turnoDeVenda.pontoDeVenda) && !telaDeEmissaoDeNota) throw new Error('Pdv de venda não selecionado')

			if (telaDeEmissaoDeNota) {
				preVenda = await this.preVendaService.findPreVendaByFiltro({
					identificador: identificador,
					lojaId: loja.id,
					cnpjDestinatario: VendaModule.emissaoEntradaAtual?.cpfDoCliente,
				})
			} else {
				preVenda = await this.preVendaService.findPreVendaByIdentificador(identificador)
			}
			const novoIdentificador = await this.vendaService.gerarIdentificadorDeVendaSemTurno(loja.id)

			const novaVenda = !telaDeEmissaoDeNota && VendaModule.turnoDeVenda
				? criarVendaDoPdv(VendaModule.turnoDeVenda.pontoDeVenda)
				: criarEmissaoDeNota()

			if (preVenda.cpfOuCnpjDoCliente && preVenda.cpfOuCnpjDoCliente.length > 0) {
				novaVenda.cliente = await this.clienteService.get(preVenda.cpfOuCnpjDoCliente) || null
				novaVenda.cpfDoCliente = VendaModule.vendaAtual?.cpfDoCliente || preVenda.cpfOuCnpjDoCliente || ''
			}

			novaVenda.loja = loja.id
			novaVenda.identificador = novoIdentificador
			novaVenda.cuponsDeDesconto = []
			novaVenda.tipoDeTransacao = VendaModule.emissaoEntradaAtual?.tipoDeTransacao || 'Venda'
			novaVenda.isBrinde = VendaModule.vendaAtual?.isBrinde || false
			novaVenda.isConsignado = VendaModule.vendaAtual?.isConsignado || false
			novaVenda.isDemonstracao = VendaModule.vendaAtual?.isDemonstracao || false
			novaVenda.vendedor = preVenda.vendedor ? [preVenda.vendedor] : []

			const itensFormatados = preVenda.itens.map<ItemDaVenda>(item => ({
				id: '',
				desconto: {
					isPercentual: false,
					valor: 0,
				},
				quantidade: item.quantidade,
				quantidadeLida: 0,
				preco: item.preco,
				precoDeCusto: 0,
				produto: item.produto,
				idConjunto: null,
				descontoVarejo: 0,
				percentualCashback: 0,
				diasParaEfetivarCashback: 0,
				isBrinde: false,
				idItemNotaOrigem: null,
				chaveNotaOrigem: item.chaveNotaOrigem,
				diasParaExpirarCashback: 0,
				possuiDescontoPorTabela: false,
				valorDescontoRateadoDoItem: 0,
				itemDeDevolucao: false,
				isTotalmenteTrocado: false,
				motivoCancelamento: null,
				isCompra: false,
				idItemOrigem: '',
				isDaVendaReaberta: false,
				vendedor: preVenda.vendedor,
				autenticadoParaLiberarProdutoSemEstoque: false,
				vendaOrigemDoItem: null,
				valorFreteRateado: 0,
				isItemReferenteATroca: false,
				isDeOutraLoja: false,
				percentualDescontoTabelaPreco: 0,
			}))

			let isProcessoTelaDeEmissaoDeNota = false
			switch (preVenda.tipoDePreVenda) {
				case 'venda':
					novaVenda.tipoDeTransacao = 'Venda'
					novaVenda.tipoNota = 'Saída'
					break
				case 'demonstracao':
					novaVenda.tipoDeTransacao = 'Venda'
					novaVenda.tipoNota = 'Saída'
					novaVenda.isDemonstracao = true
					break
				case 'consignacao':
					novaVenda.tipoDeTransacao = 'Venda'
					novaVenda.tipoNota = 'Saída'
					novaVenda.isConsignado = true
					break
				case 'outras saidas':
					novaVenda.tipoDeTransacao = 'Outros'
					novaVenda.tipoNota = 'Saída'
					isProcessoTelaDeEmissaoDeNota = true
					break
				case 'outras entradas':
					novaVenda.tipoDeTransacao = 'Outros'
					novaVenda.tipoNota = 'Entrada'
					break
				case 'brinde':
					novaVenda.tipoDeTransacao = 'Venda'
					novaVenda.tipoNota = 'Saída'
					novaVenda.isBrinde = true
					break
				case 'ativo':
					novaVenda.tipoDeTransacao = 'Ativo'
					novaVenda.tipoNota = 'Saída'
					isProcessoTelaDeEmissaoDeNota = true
					break
				case 'conserto':
					novaVenda.tipoDeTransacao = 'Conserto'
					novaVenda.tipoNota = 'Saída'
					isProcessoTelaDeEmissaoDeNota = true
					break
				case 'devolucao entrada':
					novaVenda.tipoDeTransacao = 'Devolução'
					novaVenda.tipoNota = 'Entrada'
					novaVenda.itens = novaVenda.itens.map(item => {
						return {
							...item,
							quantidade: item.quantidade > 0 ? -Math.abs(item.quantidade) : item.quantidade,
							preco: item.preco > 0 ? Math.abs(item.preco) : item.preco,

						}
					})
					break
				case 'devolucao saida':
					novaVenda.tipoDeTransacao = 'Devolução'
					novaVenda.tipoNota = 'Saída'
					novaVenda.itens = novaVenda.itens.map(item => {
						return {
							...item,
							quantidade: item.quantidade > 0 ? -Math.abs(item.quantidade) : item.quantidade,
							preco: item.preco > 0 ? Math.abs(item.preco) : item.preco,
						}
					})
					isProcessoTelaDeEmissaoDeNota = true
					break
				default:
					novaVenda.tipoNota = 'Saída'
					novaVenda.isDemonstracao = false
					novaVenda.isConsignado = false
					novaVenda.isBrinde = false
			}

			if (!telaDeEmissaoDeNota && isProcessoTelaDeEmissaoDeNota) {
				throw new Error('Tipo de pré venda não pode ser importada no caixa, apenas na tela de emissão de notas')
			}

			if (novaVenda.tipoDeTransacao !== 'Transferência' && (novaVenda.tipoDeTransacao !== 'Devolução' && novaVenda.tipoNota === 'Saída')) {
				const itensComOPrecoAplicado = await AplicarTabelaDePrecoUseCase({
					loja: loja,
					itens: itensFormatados,
					tipoDeCliente: null,
					validarLimiteDeProduto: false,
					vendaAtual: novaVenda,
				})
				novaVenda.itens = itensComOPrecoAplicado
			} else {
				novaVenda.itens = itensFormatados
			}

			novaVenda.idPreVenda = preVenda.id

			let params;
			if (novaVenda.isConsignado || novaVenda.isDemonstracao) {
				params = {
					isDevolucao: false,
					isDemonstracao: true,
					isVenda: false,
				}
			} else {
				params = {
					isDevolucao: false,
					isDemonstracao: false,
					isVenda: true,
				}
			}

			const modalidade = await this.findModalidadeDeVendaUseCase.findModalidadePadrao(loja.id, params)

			if (modalidade && modalidade.modalidade !== '') {
				novaVenda.modalidadeDeVenda = modalidade
			} else {
				novaVenda.modalidadeDeVenda = null
			}

			VendaModule.importarVenda({ vendaImportar: novaVenda, telaDeEmissaoDeNota })
			VendaModule.setVendaOriginadaDaPreVenda(true)

		} catch (e) {
			AlertModule.setError(e)
			throw e
		}
	}
}