import { Inject } from 'inversify-props'
import type { GradeDeProdutosServiceAdapter } from './adapter/GradeDeProdutosServiceAdapter'

export class DeleteGradeDeProdutosUseCase {
	@Inject('GradeDeProdutosServiceAdapter')
	private gradeDeProdutosAdapter!: GradeDeProdutosServiceAdapter

	delete = async (id: string): Promise<void> =>
		this.gradeDeProdutosAdapter.delete(id)
}
