import { FiltroDeCapaDaNota } from "@/models"
import { Inject } from "inversify-props"
import type { CapaDaNotaServiceAdapter } from ".."
import { CreateDownloadUseCase } from ".."

export class DownloadNotasUseCase {

	@Inject('CapaDaNotaServiceAdapter')
	private capaDaNotaServiceAdapter!: CapaDaNotaServiceAdapter

	createDownloadUseCase = new CreateDownloadUseCase()

	execute = async (identificador: string, params: FiltroDeCapaDaNota) => {
		const xmls = (await this.capaDaNotaServiceAdapter.getXmls(params)).filter(xml => xml != null)

		return this.createDownloadUseCase.execute(identificador, xmls)
	}

	getXmlsCompletos = async (params: FiltroDeCapaDaNota) => {

		const xmls = await this.capaDaNotaServiceAdapter.getXmlsCompletos(params)

		return xmls
	}
}