import AlertModule from '@/store/vuex/aplicacao/AlertModule';
import store from '@/store/vuex'
import { TurnoDeVenda } from './../../../models/venda/TurnoDeVenda';
import { Module, VuexModule, Mutation, Action, getModule } from "vuex-module-decorators";
import type { Desconto, EtapaDaVenda, FormMovimentacaoDeCaixa, FormPagamento, ItemDaVenda, Loja, MovimentacaoDeCaixa, TipoDeTransacaoDeVenda, Venda } from '@/models';
import { obterValorDisponivelDeSangria, normalizarTurnoDeVenda, criarVendaDoPdv, criarEmissaoDeNota } from '@/shareds/venda-shareds';
import router from '@/router';
import { InformacoesDoCliente } from '@/usecases/venda/AlterarPrecosDaVendaPorCliente'
import DialogoDeAutenticacao from './DialogoDeAutenticacao.vue'
import { TipoDeRegraDePermissao } from '@/models/RegraDeNegocio';
import type { ParametrosParaAutenticacao } from '@/models/RegraDeNegocio';
import Confirmacao from '@/components/ui/Confirmacao.vue';
import UserLoginStore from '../authentication/UserLoginStore';

const TURNO_DE_VENDA_STORAGE = 'TURNO_DE_VENDA_STORAGE'
const VENDA_ATUAL_STORAGE = 'VENDA_ATUAL_STORAGE'
const VENDAS_DO_TURNO_STORAGE = 'VENDAS_DO_TURNO_STORAGE'
const LOJA_DA_VENDA_STORAGE = 'LOJA_DA_VENDA_STORAGE'
const DESCONTO_EM_SERIE = 'DESCONTO_EM_SERIE'
const VENDA_DEVOLUCAO_CONSIGNADA_STORAGE = 'VENDA_DEVOLUCAO_CONSIGNADA_STORAGE'
const VENDA_DEVOLUCAO_DEMONSTRACAO_STORAGE = 'VENDA_DEVOLUCAO_DEMONSTRACAO_STORAGE'
const EMISSAO_ENTRADA_ATUAL_STORAGE = 'EMISSAO_ENTRADA_ATUAL_STORAGE'
const LOJA_DA_ENTRADA_STORAGE = 'LOJA_DA_ENTRADA_STORAGE'
const PAGAMENTO_COMUNICADO = 'PAGAMENTO_COMUNICADO'
const ETAPA_VENDA = 'ETAPA_VENDA'

// LIMPANDO TEMPORARIAMENTE POR ESTOURO DE STORAGE NAS MÁQUINAS DE CONTAGEM MG		
localStorage.removeItem(VENDAS_DO_TURNO_STORAGE)

localStorage[TURNO_DE_VENDA_STORAGE] = JSON.stringify(
	localStorage[TURNO_DE_VENDA_STORAGE] && localStorage[TURNO_DE_VENDA_STORAGE] !== 'null'
		? normalizarTurnoDeVenda(JSON.parse(localStorage[TURNO_DE_VENDA_STORAGE]))
		: null,
)

@Module({
	name: "VendaStore",
	namespaced: true,
	dynamic: true,
	store,
})
export default class VendaStore extends VuexModule {
	turnoDeVenda: TurnoDeVenda | null = localStorage[TURNO_DE_VENDA_STORAGE]
		? normalizarTurnoDeVenda(JSON.parse(localStorage[TURNO_DE_VENDA_STORAGE]))
		: null

	vendaAtual: Venda | null = localStorage[VENDA_ATUAL_STORAGE]
		? JSON.parse(localStorage[VENDA_ATUAL_STORAGE])
		: null

	vendasDoTurno: Venda[] = localStorage[VENDAS_DO_TURNO_STORAGE]
		? JSON.parse(localStorage[VENDAS_DO_TURNO_STORAGE])
		: []

	lojaDaVenda: Loja | null = localStorage[LOJA_DA_VENDA_STORAGE]
		? JSON.parse(localStorage[LOJA_DA_VENDA_STORAGE])
		: null

	descontoEmSerie: Desconto | null = localStorage[DESCONTO_EM_SERIE]
		? JSON.parse(localStorage[DESCONTO_EM_SERIE])
		: null

	vendaDevolucaoConsignada: Venda | null = localStorage[VENDA_DEVOLUCAO_CONSIGNADA_STORAGE]
		? JSON.parse(localStorage[VENDA_DEVOLUCAO_CONSIGNADA_STORAGE])
		: null

	vendaDevolucaoDemonstracao: Venda | null = localStorage[VENDA_DEVOLUCAO_DEMONSTRACAO_STORAGE]
		? JSON.parse(localStorage[VENDA_DEVOLUCAO_DEMONSTRACAO_STORAGE])
		: null

	emissaoEntradaAtual: Venda | null = localStorage[VENDA_ATUAL_STORAGE]
		? JSON.parse(localStorage[VENDA_ATUAL_STORAGE])
		: null

	lojaDaEntrada: Loja | null = localStorage[LOJA_DA_ENTRADA_STORAGE]
		? JSON.parse(localStorage[LOJA_DA_ENTRADA_STORAGE])
		: null

	pagamentoComunicado: FormPagamento | null = localStorage[PAGAMENTO_COMUNICADO]
		? JSON.parse(localStorage[PAGAMENTO_COMUNICADO])
		: null

	etapaVenda: EtapaDaVenda = localStorage[ETAPA_VENDA]
		? JSON.parse(localStorage[ETAPA_VENDA])
		:
		{
			estado: 'SEM_PROCESSO',
			vendas: [],
		}

	recarregaVenda = false
	recarregaNota = false
	carregandoItensDaVenda = false
	vendaDesatualizada = false
	vendaOriginadaDaPreVenda = false

	dialogoDeAutenticacao: (DialogoDeAutenticacao & { mostrar: (lojaId?: string, regra?: TipoDeRegraDePermissao, desconto?: Desconto, item?: ItemDaVenda, permanente?: boolean, autenticacaoPadrao?: boolean) => Promise<boolean> }) | null = null
	confirmarEstoqueNegativo: (Confirmacao & { mostrarComParametros: (titulo: string, subtitulo: string) => Promise<boolean> }) | null = null

	@Mutation
	setEtapaVenda(etapa: VendaStore['etapaVenda']) {
		this.etapaVenda = etapa
		setStorageDeLoja(this, ETAPA_VENDA, etapa)
	}

	@Mutation
	setPagamentoComunicado(pagamento: VendaStore['pagamentoComunicado']) {
		setStorageDeLoja(this, PAGAMENTO_COMUNICADO, pagamento)
		this.pagamentoComunicado = pagamento
	}

	@Mutation
	setDialogDeAutenticacao(dialogoDeAutenticacao: VendaStore['dialogoDeAutenticacao']) {
		this.dialogoDeAutenticacao = dialogoDeAutenticacao
	}

	@Mutation
	setConfirmarEstoqueNegativo(confirmarEstoqueNegativo: VendaStore['confirmarEstoqueNegativo']) {
		this.confirmarEstoqueNegativo = confirmarEstoqueNegativo
	}

	@Mutation
	setVendaOriginadaDaPreVenda(vendaOriginadaDaPreVenda: boolean) {
		this.vendaOriginadaDaPreVenda = vendaOriginadaDaPreVenda
	}

	@Mutation
	setRecarregaVenda(recarrega: boolean) {
		this.recarregaVenda = recarrega
	}

	@Mutation
	setRecarregaNota(recarrega: boolean) {
		this.recarregaNota = recarrega
	}

	@Mutation
	setCarregandoItensDaVenda(carregandoItensDaVenda: boolean) {
		this.carregandoItensDaVenda = carregandoItensDaVenda
	}

	@Mutation
	setLojaDaVenda(loja: Loja | null) {
		setStorageDeLoja(this, LOJA_DA_VENDA_STORAGE, loja)
		this.lojaDaVenda = loja
	}

	@Mutation
	setLojaDaEntrada(loja: Loja | null) {
		setStorageDeLoja(this, LOJA_DA_ENTRADA_STORAGE, loja)
		this.lojaDaEntrada = loja
	}

	informacoesDoCliente: InformacoesDoCliente | null = null

	informacoesDoClienteDaNota: InformacoesDoCliente | null = null

	emFechamento = false

	@Mutation
	setInformacoesDoCliente(informacoes: InformacoesDoCliente | null) {
		this.informacoesDoCliente = informacoes
	}

	@Mutation
	setInformacoesDoClienteDaNota(informacoes: InformacoesDoCliente | null) {
		this.informacoesDoClienteDaNota = informacoes
	}

	@Mutation
	setTurnoDeVenda(turno: VendaStore['turnoDeVenda']) {
		const turnoDaVenda = turno ? normalizarTurnoDeVenda(turno) : null
		setStorageDeLoja(this, TURNO_DE_VENDA_STORAGE, turnoDaVenda)
		this.turnoDeVenda = turnoDaVenda
	}

	@Mutation
	setVendaDevolucaoConsignada(venda: VendaStore['vendaDevolucaoConsignada']) {
		setStorageDeLoja(this, VENDA_DEVOLUCAO_CONSIGNADA_STORAGE, venda)
		this.vendaDevolucaoConsignada = venda
	}

	@Mutation
	setVendaDevolucaoDemonstracao(venda: VendaStore['vendaDevolucaoDemonstracao']) {
		setStorageDeLoja(this, VENDA_DEVOLUCAO_DEMONSTRACAO_STORAGE, venda)
		this.vendaDevolucaoDemonstracao = venda
	}

	@Mutation
	setVendaAtual(venda: VendaStore['vendaAtual']) {
		setStorageDeLoja(this, VENDA_ATUAL_STORAGE, venda)
		this.vendaAtual = venda
	}

	@Mutation
	setEmissaoEntradaAtual(venda: VendaStore['emissaoEntradaAtual']) {
		setStorageDeLoja(this, EMISSAO_ENTRADA_ATUAL_STORAGE, venda)
		this.emissaoEntradaAtual = venda
	}

	@Mutation
	setVendasDoTurno(vendas: VendaStore['vendasDoTurno']) {

		// DESABILITADO TEMPORARIAMENTE POR ESTOURO DE STORAGE NAS MÁQUINAS DE CONTAGEM MG
		// setStorageDeLoja(this, VENDAS_DO_TURNO_STORAGE, vendas)
		this.vendasDoTurno = vendas
	}

	@Mutation
	adicionarVendaDoTurno(venda: Venda) {
		this.vendasDoTurno = [
			...this.vendasDoTurno,
			venda,
		]
		// setStorageDeLoja(this, VENDAS_DO_TURNO_STORAGE, this.vendasDoTurno)
	}

	@Mutation
	setEmFechamentoDeCaixa(emFechamento: boolean) {
		this.emFechamento = emFechamento
	}

	@Mutation
	addMovimentacao(movimentacao: MovimentacaoDeCaixa) {
		this.turnoDeVenda?.movimentacoes.push(movimentacao)
		setStorageDeLoja(this, TURNO_DE_VENDA_STORAGE, this.turnoDeVenda)
	}

	@Mutation
	setDescontoEmSerie(desconto: Desconto | null) {
		setLocalStorage(DESCONTO_EM_SERIE, desconto)
		this.descontoEmSerie = desconto
	}

	@Mutation
	atualizaMovimentacao(movimentacao: MovimentacaoDeCaixa) {
		const movimentIndex = this.turnoDeVenda?.movimentacoes.findIndex(
			element => element.id === movimentacao.id,
		)
		if (typeof movimentIndex === 'number') {
			this.turnoDeVenda?.movimentacoes.splice(movimentIndex, 1, movimentacao)
			setStorageDeLoja(this, TURNO_DE_VENDA_STORAGE, this.turnoDeVenda)
		}
	}

	@Mutation
	setVendaDesatualizada(vendaDesatualizada: boolean) {
		this.vendaDesatualizada = vendaDesatualizada
	}

	@Action
	iniciarNovaVenda(parametros: {
		identificador: string
		novaVenda?: Venda
		novaVendaReaberta: boolean
		devolverTudo?: boolean
	}) {
		const { identificador, novaVenda } = parametros
		if (!this.turnoDeVenda) {
			throw new Error('Turno de venda não iniciado')
		}
		this.setInformacoesDoCliente(null)

		const venda = novaVenda
			? { ...novaVenda }
			: criarVendaDoPdv(this.turnoDeVenda.pontoDeVenda);

		venda.identificador = identificador
		venda.isVendaReaberta = parametros.novaVendaReaberta
		this.setVendaAtual(venda)
		this.setRecarregaVenda(true)
		this.setDescontoEmSerie(null)
		if (venda.isConsignado && venda.isVendaReaberta)
			this.setVendaDevolucaoConsignada(venda)

		if (venda.isDemonstracao && venda.isVendaReaberta)
			this.setVendaDevolucaoDemonstracao(venda)

		if (!venda.isVendaReaberta) {
			this.setVendaDevolucaoConsignada(null)
			this.setVendaDevolucaoDemonstracao(null)
		}

		if (UserLoginStore.usuario?.vendedor && this.vendaAtual && this.turnoDeVenda.pontoDeVenda.autoPreencherVendedor) {
			this.vendaAtual.vendedor = [UserLoginStore.usuario]
		}

		if (parametros.devolverTudo && this.vendaAtual) {
			const itensDevolvidos = this.vendaAtual.itens.map(item => ({ ...item, quantidade: 0 }));
			this.setVendaAtual({
				...this.vendaAtual,
				itens: itensDevolvidos,
			});
		}
		this.setEtapaVenda({ estado: 'SEM_PROCESSO', vendas: [] })
	}

	@Action
	iniciarNovaNota(parametros: {
		identificador: string
		lojaId: string
		tipoDeTransacao?: TipoDeTransacaoDeVenda
	}) {
		this.setInformacoesDoClienteDaNota(null)

		const venda = criarEmissaoDeNota()
		venda.identificador = parametros.identificador
		venda.loja = parametros.lojaId
		venda.tipoDeTransacao = parametros.tipoDeTransacao || 'Transferência'

		this.setEmissaoEntradaAtual(venda)
		this.setRecarregaNota(true)
		this.setDescontoEmSerie(null)

		if (parametros.lojaId === '') {
			this.setLojaDaEntrada(null)
		}
	}

	@Action
	importarVenda(parametros: {
		vendaImportar: Venda
		telaDeEmissaoDeNota: boolean
	}) {
		this.setInformacoesDoClienteDaNota(null)

		parametros.telaDeEmissaoDeNota
			? this.setEmissaoEntradaAtual(parametros.vendaImportar)
			: this.setVendaAtual(parametros.vendaImportar)
		this.setRecarregaNota(true)
		this.setDescontoEmSerie(null)
	}


	@Action
	mostrarFechamento() {
		router.push({
			name: 'Venda',
			query: { closing: null },
		})
			.catch()
	}

	@Action
	async moverParaMovimentacoesCasoNaoTenhaAnexo() {
		if (!VendaModule.precisaAdicionarAnexoNasMovimentacoes) return false
		AlertModule.setError({
			text: 'Favor incluir anexo nas movimentações',
			timeout: -1,
		})
		router
			.push({
				name: 'Venda',
				query: { ['cash-handling']: null, ['sem-anexos']: null },
			})
			.catch()
		return true
	}

	@Action
	verificarVendaDesatualizada() {

		if (this.vendaDesatualizada) return

		if (
			verificaStorageDiferente(VENDA_ATUAL_STORAGE, this.vendaAtual) ||
			verificaStorageDiferente(LOJA_DA_VENDA_STORAGE, this.lojaDaVenda) ||
			verificaStorageDiferente(TURNO_DE_VENDA_STORAGE, this.turnoDeVenda) // ||
			// verificaStorageDiferente(VENDAS_DO_TURNO_STORAGE, this.vendasDoTurno)
		) {
			this.setVendaDesatualizada(true)
		}

	}

	@Action
	async autenticarAcao(parametros?: ParametrosParaAutenticacao, permanente = false) {
		if (!this.dialogoDeAutenticacao) return
		return this.dialogoDeAutenticacao.mostrar(
			parametros?.lojaId || undefined,
			parametros?.regra || undefined,
			parametros?.desconto || undefined,
			parametros?.item || undefined,
			permanente,
		)
	}

	@Action
	async chamarConfirmacaoEstoqueNegativo() {
		if (!this.confirmarEstoqueNegativo) return
		return this.confirmarEstoqueNegativo.mostrarComParametros(
			'O estoque ficará negativo',
			'Deseja prosseguir?',
		)
	}

	@Action
	async autenticarPorTempoDeInatividade(parametros?: ParametrosParaAutenticacao, permanente = false) {
		if (!this.dialogoDeAutenticacao) return
		return this.dialogoDeAutenticacao.mostrar(
			parametros?.lojaId || undefined,
			parametros?.regra || undefined,
			parametros?.desconto || undefined,
			parametros?.item || undefined,
			permanente,
			false,
		)
	}

	@Action
	async autenticarAcaoTelaDeCaixa(parametros?: ParametrosParaAutenticacao, permanente = true) {
		if (!this.dialogoDeAutenticacao || !permanente) return
		return this.dialogoDeAutenticacao.mostrar(
			parametros?.lojaId || undefined,
			parametros?.regra || undefined,
			parametros?.desconto || undefined,
			parametros?.item || undefined,
			permanente,
		)
	}

	get movimentacaoIsMaiorQueDisponivel() {
		return (movimentacao: MovimentacaoDeCaixa | FormMovimentacaoDeCaixa) =>
			movimentacao.tipoMovimentacao === 'Sangria' &&
			Number(movimentacao.valor) > this.totalDisponivelDeSangria
	}

	get totalDisponivelDeSangria() {
		return this.turnoDeVenda
			? obterValorDisponivelDeSangria(this.turnoDeVenda, this.vendasDoTurno)
			: 0
	}

	get temVendaEmAndamento() {
		if (!this.turnoDeVenda || !this.vendaAtual) return false
		return !!this.vendaAtual.itens.length
	}

	get temVendaComPagamentos() {
		if (!this.vendaAtual) return false
		return this.vendaAtual.pagamentos.length > 0
	}

	get precisaAdicionarAnexoNasMovimentacoes() {
		if (!this.lojaDaVenda) return false
		return (
			this.temMovimentacaoSemAnexo &&
			this.lojaDaVenda.configuracaoDaLoja.adicionarAnexosEmMovimentacoes
		)
	}

	get temMovimentacaoSemAnexo() {
		if (!this.turnoDeVenda) return false
		return this.turnoDeVenda.movimentacoes
			.some(movimentacao => movimentacao.anexos.length === 0 && movimentacao.tipoMovimentacao != 'Abertura')
	}

	get lembraDePedirOCliente() {
		if (!this.vendaAtual || !this.lojaDaVenda) return false
		return this.lojaDaVenda.configuracaoDaLoja.lembreteDeCpfCnpj && !this.vendaAtual.cpfDoCliente || false
	}

	get bloquearCampoDeCpfECnpjDoCliente() {
		if (!this.lojaDaVenda) return false
		return this.lojaDaVenda.configuracaoDaLoja.bloquearEdicaoCpfCnpjAposPrimeiraBipagem || false
	}
}

function verificaStorageDiferente(nomeDoStorage: string, dadosEmMemoria: any) {

	const jsonStorage = localStorage[nomeDoStorage] || "null"
	const jsonEmMemoria = JSON.stringify(dadosEmMemoria)

	return jsonStorage !== jsonEmMemoria
}

function setStorageDeLoja(vendaModule: VendaStore, chave: string, valor: any) {
	if (vendaModule.vendaDesatualizada) {
		console.warn('Venda desatualizada, atualize a aba!')
		return
	}
	setLocalStorage(chave, valor)
}

function setLocalStorage(chave: string, valor: any) {
	if (valor) {
		localStorage[chave] = JSON.stringify(valor)
	} else {
		localStorage.removeItem(chave)
	}
}

export const VendaModule = getModule(VendaStore)
