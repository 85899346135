import { Inject } from "inversify-props"
import type { EstoqueServiceAdapter } from "../deposito"
import { AxiosRequestConfig } from "axios"
import { ItemDeRomaneio, ListagemItensRomaneio } from "@/models/Romaneio"

export class ItemRomaneioEstoqueUseCase {

	@Inject('EstoqueServiceAdapter')
	private estoqueService!: EstoqueServiceAdapter



	async findItensRomaneio(params?: any, config?: AxiosRequestConfig): Promise<ListagemItensRomaneio> {
		return this.estoqueService.findItensRomaneio(params, config)
	} 
	
	async findAllItensRomaneio(romaneioId: string): Promise<ItemDeRomaneio[]> {
		return this.estoqueService.findAllItensRomaneio(romaneioId)
	}

}